//#region Imports
import * as CryptoJS from "crypto-js";

import { environment } from "../../environments/environment";
import { Utilities } from "./utilities";
//#endregion Imports

export class Secure {
  //#region encryptHandler
  public encryptParamHandler(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }
  //#endregion encryptHandler

  //#region decryptHandler
  public decryptParamHandler(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  //#endregion decryptHandler

  //#region encryptHandler
  public encryptHandler(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }
  //#endregion encryptHandler

  //#region decryptHandler
  public decryptHandler(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  //#endregion decryptHandler

  //#region decryptJSONHandler
  public decryptJSONHandler(value: string): JSON {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
  //#endregion decryptJSONHandler

  //#region encryptJSONHandler
  public encryptJSONHandler(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.AES_ENC_SECRET_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.AES_ENC_IV);
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  //#endregion encryptJSONHandler
}
