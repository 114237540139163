import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { filterDropdown } from "../components/filter-dropdown/filter-dropdown.component";
import { Services } from "../helpers/Services";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { DatePickerComponent } from "../components/date-picker/date-picker.component";
import * as $ from 'jquery';
import { DatePipe, Location } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-appoinment",
  templateUrl: "./appoinment.component.html",
  styleUrls: ["./appoinment.component.css"],
})
export class AppoinmentComponent implements OnInit, AfterViewChecked {
  LocationDropdownValues$: Observable<filterDropdown>;
  DentistDropdownValues$: Observable<filterDropdown>;
  appservices: Services;
  isLocations = true;
  date$ = new Subject<Date>();
  timingList = [];
  minDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  selectDate = this.date$.next.bind(this.date$);
  startTimesByDate: { [date: string]: string[]; } = {}; // Object to hold start times by date
  dateOfBirth: any = '';
  selectedSlotDate: any;
  @ViewChild(DatePickerComponent) slotDateCompoent!: DatePickerComponent;
  flag: boolean = false;
  tnc: boolean = false;
  firstLoad: boolean = false;
  practiceId;
  submitted = false;
  selectedDentist;
  slotId: any;
  slotFlag: boolean = false;
  locationId;
  doctorId: any = '';
  selectedLocation;
  privacy_site: string = environment.PRIVACY;
  terms_site: string = environment.TERMS;
  consent_form: string = environment.CONSENT_FORM;
  d4wDetails;
  d4W_practiceId;
  isAvailableSlot = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private renderer: Renderer2,
    private el: ElementRef,
    private datePipe: DatePipe,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.appservices = new Services();
    this.firstLoad = true;
    this.d4wDetails = this.appservices.getSession("is_d4w", true);
    if (this.d4wDetails) {
      this.d4W_practiceId = this.d4wDetails.d4w_practice_id;
      this.practiceId = this.d4W_practiceId;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  separateStartTimesByDate() {
    if (this.timingList?.length != 0 && this.timingList?.length != undefined) {
      this.timingList.forEach(time => {
        const tempDate = time.start.split(' ')[0];
        const date = tempDate;
        const startTime = time.start.split(' ')[1].substring(0, 5);
        let hours = parseInt(startTime.split(":")[0], 10); // Extract hours and convert to integer
        let period = hours >= 12 ? 'PM' : 'AM';
        if (!this.startTimesByDate[date]) {
          this.startTimesByDate[date] = [];
        }
        let obj: any = {
          time: startTime,
          meridiem: period,
          slotId: time.slotId
        };
        this.startTimesByDate[date].push(obj);
      });
    } else {
      this.slotFlag = false;
    }
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  async ngOnInit(): Promise<void> {
    this.getLocations();
    this.getDentistList();
  }

  public getLocations() {
    this.appservices.callJSONAPI('practice/locations' + `?practiceId=${this.d4W_practiceId}` + "", "", "get", this, "127.0.0.1").subscribe(
      (data: any) => {
        let val = JSON.parse(data.result);
        this.locationId = val[0]['practiceId'];
        let obj = {
          id: val[0]['practiceId'],
          name: val[0]['practiceName']
        };
        this.selectedLocation = obj;
        const cvalues = val?.filter((x: any) => {
          if (!x.id || !x.name) {
            x.id = x.practiceId;
            x.name = x.practiceName;
          }
          return x;
        });
        const data1 = {
          isLoading: false,
          values: cvalues,
        };
        this.LocationDropdownValues$ = of(data1);
      },
      (error) => { }
    );
  }

  onChangeDob(event) {
    this.dateOfBirth = this.datePipe.transform(event, 'yyyy-MM-dd', 'en-US');
    this.cdr.detectChanges();
  }

  async getDentistList() {
    this.appservices.callJSONAPI('practice/doctors' + "", "", "get", this, "127.0.0.1").subscribe(
      (data: any) => {
        let val = JSON.parse(data.result);
        this.doctorId = val[0]['doctorId'];
        let obj = {
          id: val[0]['doctorId'],
          name: val[0]['doctorName']
        };
        this.selectedDentist = obj;
        const cvalues = val?.filter((x: any) => {
          if (!x.id || !x.name) {
            x.id = x.doctorId;
            x.name = x.doctorName;
          }
          return x;
        });
        const data1 = {
          isLoading: false,
          values: cvalues,
        };
        this.DentistDropdownValues$ = of(data1);
        if (this.firstLoad) {
          let dateSelection = {
            selectDate: {
              startDate: this.minDate,
              endDate: this.minDate
            }
          };
          this.selectionDateSlot(dateSelection);
          this.firstLoad = false;
          this.flag = true;
        }
      },
      (error) => { }
    );
  }

  public getSlotInfo(selectedSlotId: any) {
    this.appservices.callJSONAPI('practice/slot_info' + `?slotId=${selectedSlotId}` + "", "", "get", this, "127.0.0.1").subscribe(
      (data: any) => {
        let val = JSON.parse(data.result);
      },
      (error) => { }
    );
  }

  onChangeLocation(event: any) {
    this.locationId = event.practiceId;
    this.practiceId = event.practiceId;
  }

  onChangeDentist(event: any) {
    this.doctorId = event.id;
    let obj = {
      id: event.id,
      name: event.name,
    };
    this.selectedDentist = obj;
  }

  selectedTimeSlot(timeSlot: any, date: any, i, j) {
    this.slotId = timeSlot.slotId;
    const slotElements = this.el.nativeElement.querySelectorAll('.slot');
    slotElements.forEach((element: HTMLElement) => {
      this.renderer.removeClass(element, 'active');
    });
    const divElement = this.el.nativeElement.querySelector(
      "#slot-section" + i + j
    );
    this.renderer.addClass(divElement, "active");
    this.getSlotInfo(this.slotId);
  }

  selectionDateSlot(dateSelection) {
    this.timingList = [];
    this.slotFlag = true;
    let selectDate = dateSelection.selectDate;
    let startDate = selectDate.startDate.year + '-' + ('0' + selectDate.startDate.month).slice(-2) + '-' + ('0' + selectDate.startDate.day).slice(-2);
    let endDate = selectDate.endDate.year + '-' + ('0' + selectDate.endDate.month) + '-' + ('0' + selectDate.endDate.day).slice(-2);
    this.startTimesByDate = {};
    if (dateSelection && this.doctorId) {
      this.appservices.callJSONAPI('practice/doctor_slots' + `?doctorId=${this.doctorId}&start=${startDate} 00:10:00&end=${endDate} 23:59:00`, "", "get", this, "127.0.0.1").subscribe(
        (data: any) => {
          if (data.success) {
            let val = JSON.parse(data.result);
            this.timingList = val;
            this.isAvailableSlot = this.timingList?.length == 0 ? false : true;
            this.separateStartTimesByDate();
            this.slotFlag = false;
          }
        },
        (error) => {
          this.slotFlag = false;
        }
      );
    } else {
      this.slotFlag = false;
    }
  }

  bookAppointment() {
    if (this.doctorId && this.locationId && this.slotId && this.tnc && this.dateOfBirth) {
      $('.submit_btn').html('Please Wait...').attr('disabled', true);
      let emailId = this.appservices.getSession("email", false);
      let last_name = this.appservices.getSession("last_name", false);
      let first_name = this.appservices.getSession("first_name", false);
      let phoneNumber = this.appservices.getSession("phoneNumber", false);
      let dial_code = this.appservices.getSession("dial_code", false);
      let obj = {
        dob: this.dateOfBirth,
        email: emailId,
        firstName: first_name,
        lastName: last_name,
        mobile: phoneNumber,
        notes: '',
        recall: true,
        slotId: this.slotId,
        title: 0,
        wicksId: '',
        practice_id: Number(this.practiceId),
        dentist_id: this.doctorId,
        doctorName: this.selectedDentist.name
      };
      let requestBody = this.appservices.generteJSONFromArray(obj);
      this.appservices.callJSONAPI('practice/booking_create', requestBody, "post", this, "127.0.0.1").subscribe(
        (data: any) => {
          if (data.status) {
            this.appservices.setSession("booking_details", data.result, true);
            this.router.navigate(['/booking-success']);
          }
        },
        (error) => { }
      );
    } else {
      this.submitted = true;
    }
  }

  backClicked() {
    this.location.back();
  }
}
