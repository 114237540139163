import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RazorpayService {
  private apiKey = "rzp_test_e8LfO6RfNIF3Qi";
  constructor(private http: HttpClient) {}
  createCustomer(customerData: any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.apiKey}`,
    });
    const apiUrl = "https://api.razorpay.com/v1/customers"; // Replace with the Razorpay API endpoint for customer creation

    return this.http.post(apiUrl, customerData, { headers });
  }
}
