import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-invalid-access",
  templateUrl: "./invalid-access.component.html",
  styleUrls: ["./invalid-access.component.css"],
})
export class InvalidAccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
