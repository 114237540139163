import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import * as $ from "jquery";
import { Services } from "../../app/helpers/Services";

@Component({
  selector: "app-email-confirmation",
  templateUrl: "./email-confirmation.component.html",
  styleUrls: ["./email-confirmation.component.css"],
})
export class EmailConfirmationComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  user_email: any;
  user_details: any;

  constructor(private httpClient: HttpClient, private router: Router) {
    this.appservices = new Services();
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate(["get-started"]);
    }
    this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    // console.log(this.user_details);
    this.user_email = this.user_details.email;
  }
  onContinue() {
    if (true) {
      $(".coninue_btn").html("Submitting...").attr("disabled", true);
      this.apirequest = [];
      this.apirequest["first_name"] = this.user_details.firstName;
      this.apirequest["last_name"] = this.user_details.lastName;
      this.apirequest["email"] = this.user_details.email;
      this.apirequest["phone"] = this.user_details.phoneNumber;
      this.apirequest["widget_token"] = this.appservices.getSession(
        "token",
        false
      );
      let dail_code = this.appservices.getSession("dial_code", false)
        ? this.appservices.getSession("dial_code", false).toString()
        : "1";
      this.apirequest["dial_code"] = dail_code;
      this.input =
        '"user":' + this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices
        .callJSONAPI(
          this.appservices.WIDGET_LOGIN,
          this.input,
          "post",
          this,
          ""
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            $(".coninue_btn").html("Continue").attr("disabled", false);
            if (data.status) {
              let full_name =
                data.result.user.first_name + data.result.user.last_name;
              this.appservices.setSession("is_logged_in", "true", false);
              this.appservices.setSession(
                "user_access_token",
                data.result.user.user_access_token,
                false
              );
              this.appservices.setSession(
                "first_name",
                data.result.user.first_name,
                false
              );
              this.appservices.setSession(
                "last_name",
                data.result.user.last_name,
                false
              );
              this.appservices.setSession("full_name", full_name, false);
              this.appservices.setSession(
                "email",
                data.result.user.email,
                false
              );
              this.appservices.setSession(
                "user_id",
                data.result.user.user_id,
                false
              );
              this.callMedicalQueSaveAPI();
              // this.router.navigate(['medical-questions']);
            } else {
              this.appservices.serviceMessages(
                "vs-messages",
                data.message,
                "danger"
              );
              if (!this.appservices.getSession("token", false)) {
                this.router.navigate(["get-started"]);
              }
            }
          },
          (error) => {
            this.appservices.showDebugMessage(error);
          }
        );
    }
  }
  callMedicalQueSaveAPI() {
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession(
      "token",
      false
    );
    this.apirequest["questions"] = this.appservices.getSession(
      "questions_array",
      true
    );
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    //console.log(input);
    this.appservices
      .callJSONAPI(
        this.appservices.MEDICAL_QUESTIONNAIRE_SAVE,
        input,
        "post",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          //console.log(data);
          if (data.status) {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "success"
            );
            this.router.navigate(["oral-health-score-preference"]);
          } else {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "danger"
            );
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
  }
  // }
  onBackToContinue() {
    this.router.navigate(["contact-information"]);
  }
}
