import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnInit,
  TemplateRef,
  HostListener,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Services } from "../../app/helpers/Services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { LyDialog } from "@alyle/ui/dialog";
import { ImgCropperEvent } from "@alyle/ui/image-cropper";
import { CropperDialogComponent } from "../global_components/cropper-dialog/cropper-dialog.component";
import * as $ from "jquery";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DomSanitizer } from "@angular/platform-browser";
import * as Bowser from "bowser";
import { SlickCarouselComponent } from "ngx-slick-carousel";
declare const loadScript: any;
import { environment } from "../../../src/environments/environment";
import { MessageService } from "../profile-pic-service.service";
import { NgxImageCompressService } from "ngx-image-compress";
import { interval, Subscription } from "rxjs";
import { Options } from "@angular-slider/ngx-slider";
declare var gtag;
declare const fbq = '';

@Component({
  selector: "app-upload-teeth-photos",
  templateUrl: "./upload-teeth-photos.component.html",
  styleUrls: ["./upload-teeth-photos.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadTeethPhotosComponent implements OnInit {
  youtube_1: any;
  youtube_2: any;
  youtube_3: any;
  youtube_4: any;
  youtube_5: any;
  modalRef: BsModalRef;
  cropped?: string;
  termsAndConditions: any = "-1";
  @ViewChild("fileInput") el: ElementRef;
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  uploadImageInputForm: FormGroup;
  submitted = false;
  vision_api_result: any[] = [];
  defaultImage = "assets/images/upload-image-loader.gif";
  imageUrl: any;
  editFile: boolean = true;
  folder: any;
  todaysDataTime;
  cropped_url_blob: Blob;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  ohrdata: any = [];
  ohrStoredImages: any = [];
  extra_images: any = [];
  imageError: any;
  length: any = 0;
  urls = new Array<string>();
  unique_key: any;
  today = new Date();
  responseData: any;
  responseDataSingleImage: any;
  imagesInfo: any;
  count: any;
  object: object = {};
  images = [];
  flag: boolean = false;
  isfrontImage: any;
  input: any;
  displayImage: any;
  displayTitle: any;
  count_value: number = 0;
  // isButtonDisabled: boolean = true; renamed to isUploadButtonDisabled: boolean = true;
  isUploadButtonDisabled: boolean = true;
  displayHeader: any = "Oral Health Rating";
  widget_flow_type: any = "";
  currentDate: any = "";
  get_contact_information_later: any = "";
  guest_session_uuid: any = "";
  guest_user_id: any = "";
  // analyzingText: any = "Smilo.ai is analysing the pictures you have submitted";
  analyzingText: any = "Please wait while we upload your images";
  successText: string = "Successfully Done";
  applyStyle: string = "loaderImage";
  isSubmitted = false;
  InputForm: FormGroup;
  inputFormOtp: FormGroup;
  isOtpSubmitted: boolean = false;
  user: any;
  user_details: any;
  countryCodeNumber: any;
  location: any;
  country_name: any = "";
  isDigitsOnly: any;
  reg: any;
  privacy_site: string = environment.PRIVACY;
  terms_site: string = environment.TERMS;
  consent_form: string = environment.CONSENT_FORM;
  staticImage: string = "assets/images/sign-up.png";
  isButtonDisabled: boolean = true;
  practiceBranches: any = "";
  userSelectedAddress: string = "";
  isBranchesButtonDisabled: boolean = true;
  display_branches_div: boolean = false;
  display_contactForm_div: boolean = true;
  checkboxChecked: boolean = false;
  firstName: string = "";
  lastName: string = "";
  phoneNumber: any;
  dialCode: any;
  preSelectedBranch: any = "";
  uploadImageBtnSelected: any = false;
  header_display_msg: any = "";
  signInValidNumber: any = "";
  phoneSignIn: any = "";
  signInPhoneNumber: any = "";
  signInSelectedCountryCode: any = "";
  signInSelectedCountry: any = "";
  contactus_country_name: any = "";
  redirecting_url: any = "";
  sendSubjectMessage: any = "";
  profile_img: any = "assets/images/default-profile.png";
  contactFormBtn: boolean = false;
  showContactForm: boolean = false;
  showLoadingForm: boolean = false;
  isDataLoaded: boolean = false;
  backgroundProcessing: boolean = true;
  contactFormSubmitted: boolean = false;
  ohr_flow: any;
  forthSlideVisited: boolean = false;
  fifthSlideVisited: boolean = false;
  sixthSlideVisited: boolean = false;
  uploadImgClass: string = "v1";
  after_image_upload_div: string = "after_image_upload_div";
  imagesTitle: any;
  displayPreviousBtn: string = "visibility_none";
  displaySkipBtn: string = "visibility_none";
  currentSelectedRow: any;
  from_medical_history_form: any;
  slideConfig = {
    swipe: false,
    swipeToSlide: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      "<div onclick=\"Window.myComponent.checkingImageExist('right',this)\" class='nav-btn icon-right-arrow next-slide blue_color font_30'></div>",
    prevArrow:
      "<div onclick=\"Window.myComponent.checkingImageExist('left',this)\" class='nav-btn icon-left-arrow prev-slide blue_color font_30'></div>",
    infinite: false,
  };
  isUserFromIndia: boolean = false;
  isOtpVerificationProcessExist: boolean = false;
  digitOne: any = "";
  digitTwo: any = "";
  digitThree: any = "";
  digitFour: any = "";
  isInValidOtp: boolean = false;
  isOtpSuccess: boolean = false;
  timer: number = 30;
  isTimerOver: boolean = false;
  isOtpReceive: boolean = true;
  timerSubscription!: Subscription;
  currentIndex: number = 1;
  isAllApiDone: boolean = false;
  imageSelectionCount: any = "5";
  // whiteningContrastValue: any = 0;
  // contrastOptions: Options = {
  //   floor: 1.0,
  //   ceil: 2.0,
  //   step: 0.1,
  // };
  // whiteningBrightnessValue: any = 0;
  // brightnessOptions: Options = {
  //   floor: 0,
  //   ceil: 100
  // };
  isTeethWhitening = false;
  teethWhiteningSingleImage;
  isTeethWhiteningType2 = false; // Api implementation

  constructor(
    private _dialog: LyDialog,
    private httpClient: HttpClient,
    private router: Router,
    private _cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private imageCompress: NgxImageCompressService,
    private renderer: Renderer2,
  ) {
    this.appservices = new Services();
    let teethWhite: any = this.appservices.getSession(
      "teethWhitening",
      false
    );
    let teethWhiteApi: any = this.appservices.getSession(
      "teethWhiteningType2",
      false
    );
    if (teethWhiteApi == 'true' || teethWhiteApi == true) {
      this.isTeethWhiteningType2 = true;
      localStorage.removeItem("teethWhiteningResultImage");
      localStorage.removeItem("teethWhiteningUploadedImage");
    }
    // if (teethWhite == 'true' || teethWhite == true) {
    //   this.isTeethWhitening = true;
    //   localStorage.removeItem("teethWhiteningResultImage");
    //   localStorage.removeItem("teethWhiteningUploadedImage");
    // }
    Window["myComponent"] = this;
    // this.teethWhiteningContinue(false); //banuba
  }

  slickInit(e) {
    // this.currentIndex = $(".slick-active").attr("data-slick-index");
    // console.log(
    //   "🚀 ~ file: upload-teeth-photos.component.ts:211 ~ slickInit ~ this.currentIndex:",
    //   this.currentIndex
    // );
  }

  checkingImageExist(movementArea: any, e: any) {
    setTimeout(() => {
      this.disableArrow();
    }, 500);
    var continueFurther = true;
    if (movementArea == "left") {
      setTimeout(() => {
        let currentIndex = $(".slick-active").attr("data-slick-index");
        this.currentIndex = Number(currentIndex) + 1;
        if (currentIndex == "3") {
          if ($("#uploadImage3").length == 0) {
            this.displaySkipBtn = "visibility_block";
          }
          if ($("#uploadImage3").length != 0) {
            this.displaySkipBtn = "visibility_none";
          }
        }
        if (currentIndex == "4") {
          if ($("#uploadImage4").length == 0) {
            this.displaySkipBtn = "visibility_block";
          }
          if ($("#uploadImage4").length != 0) {
            this.displaySkipBtn = "visibility_none";
          }
        }
      }, 100);
      $(".save_continue_btn_span").html("Save & Continue");
    }
    if (movementArea == "right") {
      let OHRflow = this.ohr_flow;
      let OHRlength = this.getOHRDataLength();
      let currentIndex = $(".slick-active").attr("data-slick-index");
      if (
        currentIndex >= 0 &&
        ($("#uploadImage0").length == 0 ||
          $("#uploadImage1").length == 0 ||
          $("#uploadImage2").length == 0)
      ) {
        // this.slickModal.slickGoTo(currentIndex);
        continueFurther = false;
      }
    }
    $(".upload-teeth-continue-btn").attr("disabled", true);
    setTimeout(() => {
      let currentIndex = $(".slick-active").attr("data-slick-index");
      this.currentIndex = Number(currentIndex) + 1;
      if (currentIndex >= 3) {
        this.uploadImgClass = "v2";
      }
      this._cd.detectChanges();

      if (currentIndex == "3") {
        this.forthSlideVisited = true;
        if ($("#uploadImage3").length == 0) {
          this.displaySkipBtn = "visibility_block";
        }
      }
      if (currentIndex == "4") {
        this.fifthSlideVisited = true;
        if ($("#uploadImage4").length == 0) {
          this.displaySkipBtn = "visibility_block";
        }
      }
      if (currentIndex == "5") {
        this.sixthSlideVisited = true;
        $(".save_continue_btn_span").html("Get your Results");
        this.displaySkipBtn = "visibility_none";
      }
      this._cd.detectChanges();
      if ($("#uploadImage" + currentIndex).length > 0) {
        $(".upload-teeth-continue-btn").attr("disabled", false);
      }
      if (
        $("#uploadImage0").length != 0 &&
        $("#uploadImage1").length != 0 &&
        $("#uploadImage2").length != 0 &&
        $("#uploadImage3").length != 0 &&
        $("#uploadImage4").length != 0
      ) {
        $(".upload-teeth-continue-btn").attr("disabled", false);
      }
      if (this.ohrdata.length >= 3 && currentIndex == "5") {
        $(".upload-teeth-continue-btn").attr("disabled", false);
      }
    }, 500);
    this._cd.detectChanges();
  }


  /* @HostListener('window:popstate', ['$event'])
  onPopState(e: KeyboardEvent) {
    // this.modalRef.hide();
    window.location.reload();
    //console.log('Back button pressed');
  } */

  public getDeviceInfo() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details =
      userAgent.browser.name +
      " " +
      userAgent.os.name +
      " " +
      userAgent.browser.version;
    return browser_details;
  }

  getLocationAddress() {
    let locationDetails;
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
        locationDetails = {
          calling_code: "91",
          city: "Kolkata",
          country_code: "IN",
          country_name: "India",
          ip: "103.75.160.75",
          state: "undefined",
          time_zone: "Asia/Kolkata",
          time_zone_offset: "+0530",
        };
        this.isUserFromIndia = true;
        if (this.isUserFromIndia) {
          this.appservices.setSession("country_name", "IN", false);
          this.appservices.setSession("contactus_country_name", "IN", false);
        } else {
          this.appservices.setSession("country_name", "au", false);
          this.appservices.setSession("contactus_country_name", "au", false);
        }
        this.appservices.setSession("location_details", locationDetails, true);
      });
  }

  ngAfterViewInit() {
    let OHRflow = this.ohr_flow;
    let OHRlength = this.getOHRDataLength();

    $(".slick-dots")
      .find("li")
      .each(function () {
        $(this).find("button").prop("disabled", true);
      });
    // $('.icon-right-arrow').attr('aria-disabled','true');
    $(".slick-arrow").addClass("display_none");
    $(".slick-arrow").addClass("slick-disabled");
    if ($("#agree").length > 0) {
      $("#agree").change(function () {
        OHRlength = $(".base64Image").length;
        $(".upload-teeth-continue-btn").attr("disabled", true);
        if (this.checked && OHRlength > 0) {
          //Do stuff
          $(".upload-teeth-continue-btn").removeAttr("disabled");
          0
        }
      });
    }
  }
  ngOnInit(): void {
    this.imageSelectionCount = this.appservices.getSession(
      "images_selection_count",
      false
    );
    this.appservices.setSession("current_url", "upload-teeth-photos", false);

    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_session_uuid = this.appservices.getSession(
      "guest_session_uuid",
      false
    );
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);

    this.ohr_flow = this.appservices.getSession("ohr_flow", false);

    this.appservices.sendAnalyticsData(
      '',
      '',
      "smilo_event",
      "sa_wg_ohr_entered",
      true
    );
    this.appservices.setSession("bannerTitleText", this.displayHeader, false);
    // console.log(" Header Name : " + this.displayHeader);
    // let ohrStoredImages;
    $(".sec_inner_content1").show();
    $(".analysing_sec").hide();

    this.currentDate = new Date();
    // console.log(" this.currentDate :  " + this.currentDate);
    // this.todaysDataTime = '';
    this.todaysDataTime = formatDate(
      this.currentDate,
      "yyyyMMddhhmmss",
      "en-US"
    );
    this.appservices.setSession("ohr_unique_key", this.todaysDataTime, false);
    //this.appservices.setSession("ohr_unique_key",'', false);
    // console.log(" this.todaysDataTime : " + this.todaysDataTime);
    // console.log(" formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US') : " + formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US') );
    // console.log(" this.ohr_unique_key : " + this.appservices.getSession("ohr_unique_key", false));
    this.uploadImageInputForm = this.formBuilder.group({
      file0: ["", [Validators.required]],
      file1: ["", [Validators.required]],
      file2: ["", [Validators.required]],
      file3: ["", [Validators.required]],
      file4: ["", [Validators.required]],
      file5: ["", [Validators.required]],
    });
    this.extra_images = [{ image: "", title: "Any additional images" }];
    this.imagesInfo = [
      { url: "assets/images/teeth_front.png", title: "Teeth Front View" },
      { url: "assets/images/teeth_right.png", title: "Teeth Right View" },
      { url: "assets/images/teeth_left.png", title: "Teeth Left View" },
      { url: "assets/images/teeth_upper.png", title: "Teeth Upper Jaw" },
      { url: "assets/images/teeth_lower.png", title: "Teeth Lower Jaw" },
      { url: "assets/images/teeth_ref.png", title: "Any additional images" },
    ];
    this.responseData = [
      {
        url: "assets/images/teeth_1_D.png",
        pageNumber: "1 of 6",
        title: "Front View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by biting down on your back teeth and smile wide with your lips apart.",
      },
      {
        url: "assets/images/teeth_2.png",
        pageNumber: "2 of 6",
        title: "Right View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by turning your phone to the right side and bite evenly on your back teeth with your lips apart.",
      },
      {
        url: "assets/images/teeth_3.png",
        pageNumber: "3 of 6",
        title: "Left View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by turning your phone to the left side and bite evenly on your back teeth with your lips apart.",
      },
      {
        url: "assets/images/teeth_4.png",
        pageNumber: "4 of 6",
        title: "Upper Jaw",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by opening your mouth wide, lips away and turning your phone camera upside down to capture upper teeth.",
      },
      {
        url: "assets/images/teeth_5.png",
        pageNumber: "5 of 6",
        title: "Lower Jaw",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by opening your mouth wide, lips away and bringing your phone up to capture your lower teeth.",
      },
      {
        url: "assets/images/teeth_6.png",
        pageNumber: "6 of 6",
        title: "Additional images",
        base64: "",
        file_name: "",
        content: "Add any other images you like.",
      },
    ];
    this.responseDataSingleImage = [
      {
        url: "assets/images/teeth_1_D.png",
        pageNumber: "1 of 6",
        title: "Front View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by biting down on your back teeth and smile wide with your lips apart.",
      },
    ];

    this.imagesTitle = [
      { title: "Front view" },
      { title: "Right view" },
      { title: "Left view" },
      { title: "Upper jaw" },
      { title: "Lower jaw" },
      { title: "Additional picture" },
    ];

    setTimeout(function () {
      $(".alert_close").remove();
    }, 30000);

    this.youtube_1 = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/VCH8zdrlfyU/?enablejsapi=1&version=3&wmode=transparent"
    );
    this.youtube_2 = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/bIKvTz4ix38/?enablejsapi=1&version=3&wmode=transparent"
    );
    this.youtube_3 = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/nBx7CQCO7LM/?enablejsapi=1&version=3&wmode=transparent"
    );
    this.youtube_4 = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/OGTQLTImHk0/?enablejsapi=1&version=3&wmode=transparent"
    );
    this.youtube_5 = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/cLEklsoh4ic/?enablejsapi=1&version=3&wmode=transparent"
    );

    // if (this.appservices.hasSession("ohrStoredImages")) {
    //   this.ohrdata = this.appservices.getSession("ohr_data", true);
    //   this.ohrStoredImages = this.appservices.getSession("ohrStoredImages", true);
    //   ohrStoredImages = this.appservices.getSession("ohrStoredImages", true);
    //   // let extra_image = this.appservices.getSession("extra_images", true);
    //   for (let i = 0; i < ohrStoredImages.length; i++) {
    //     this.responseData[ohrStoredImages[i].id].base64 = ohrStoredImages[i].base64;
    //   }
    // }

    // Inherited from Contact Form
    // this.appservices.setSession("bannerTitleText",'Select Preferences' ,false);
    this.preSelectedBranch = this.appservices.getSession(
      "user_selected_practice_branch",
      false
    );

    // console.log("  branches_list : " + (this.appservices.getSession("branches_list",false)));
    this.practiceBranches = this.appservices.getSession("branches_list", false);
    if (this.practiceBranches != undefined && this.practiceBranches != "") {
      this.practiceBranches = JSON.parse(this.practiceBranches);
      // console.log("this.practiceBranches : " + this.practiceBranches);
      //  this.appservices.setSession("has_branches",'yes', false);
      if (this.practiceBranches.length > 0) {
        this.display_branches_div = true;
        this.display_contactForm_div = false;
      }
      // this.appservices.setSession("branches_list",this.practiceBranches, false);
    }

    this.reg =
      "/^({0,1}((0|+61)(2|4|3|7|8)){0,1}){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/";
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    }
    this.getLocationAddress();
    this.callAppDetailsAPI();
    this.location = this.appservices.getSession("location_details", true);
    // console.log(" localStorage.getItem('Input_form_data') : " + localStorage.getItem('Input_form_data'));
    if (localStorage.getItem("Input_form_data") != "")
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$";
    this.InputForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(1)]],
      lastName: ["", [Validators.required, Validators.minLength(1)]],
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],

      phoneNumber: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{10}$")],
      ],
    });
    this.inputFormOtp = this.formBuilder.group({
      firstDigit: ["", [Validators.required]],
      secondDigit: ["", [Validators.required]],
      thirdDigit: ["", [Validators.required]],
      fourthDigit: ["", [Validators.required]],
    });
    // console.log(" Inside ");
    if (this.user_details) {
      // this.InputForm.controls["firstName"].setValue(
      //   this.user_details.firstName
      // );
      // this.InputForm.controls["lastName"].setValue(this.user_details.lastName);
      // this.InputForm.controls["email"].setValue(this.user_details.email);
      // this.InputForm.controls["phoneNumber"].setValue(
      //   this.user_details.phoneNumber
      // );
      // console.log(
      //   "🚀 ~ file: upload-teeth-photos.component.ts:669 ~ ngOnInit ~ this.InputForm:",
      //   this.InputForm
      // );
      this.isButtonDisabled = false;
    }
    //  $('#agree').prop('checked', true);
    //  this.checkboxChecked = true;

    // if (!this.appservices.hasSession("country_name")) {
    //   this.appservices.setSession("country_name", 'au', false);
    // }

    // if (!this.appservices.hasSession("contactus_country_name")) {
    //   this.appservices.setSession("contactus_country_name", "au", false);
    // }
    // let otpProcess = this.appservices.getSession(
    //   "user_otp_verification_process",
    //   false
    // );
    // if (otpProcess === "true") {
    //   this.isOtpVerificationProcessExist = true;
    // } else {
    //   this.isOtpVerificationProcessExist = false;
    // }
    // console.log(otpProcess, typeof otpProcess);
  }

  onBack() {
    this.appservices.sendAnalyticsData(
      '',
      '',
      "smilo_event",
      "sa_wg_Upload_images_back_clicked",
      true
    );
    this.router.navigate(["/consent-agreement"]);
  }

  onBackToUploadImages() {
    this.showContactForm = false;
    this.isDataLoaded = false;
    $(".sec_inner_content1").show();
  }

  //   get termsAndConditions() {
  //     return this.InputForm.get('termsAndConditions');
  // }

  get f() {
    return this.uploadImageInputForm.controls;
  }
  // displayInputBox(id) {
  //   if (id == 5) {
  //     $(".input_box").removeClass("d-none").addClass("d-flex");
  //     $(".form_control").val($("#lblName5").html());
  //     $(".submit_btn").addClass("pt-4");
  //     $("#lblName5").hide();
  //   }
  // }

  // CopyToLabel(id) {
  //   var txtName = $("#txtName");
  //   var lblName = $("#lblName5");
  //   if (!this.allLetter(txtName.val())) {
  //     window.scrollTo(0, 0);
  //     $(window).scrollTop(0);
  //     this.appservices.serviceMessages(
  //       "vs-messages",
  //       "Label must contains alphabets and spaces only",
  //       "danger"
  //     );
  //   } else if (txtName.val().trim().length < 5) {
  //     window.scrollTo(0, 0);
  //     $(window).scrollTop(0);
  //     this.appservices.serviceMessages(
  //       "vs-messages",
  //       "Label must be minimum 5 characters",
  //       "danger"
  //     );
  //   } else {
  //     this.appservices.serviceMessages("vs-messages", "", "");
  //     lblName.html(txtName.val()).show();
  //     $(".input_box").removeClass("d-flex").addClass("d-none");
  //     this.pushImageDataInToArray(5, lblName.html(), "");
  //   }
  // }

  onSubmit() {
    if (this.imageSelectionCount != 1) {
      let message =
        "Please upload at least three images (Front view, Left view, Right view, Upper jaw or Lower Jaw) to continue";
      this.count = $(".base64Image").length;
      this.isfrontImage = $("#uploadImage0").length;

      this.displayPreviousBtn = "visibility_block";

      this.after_image_upload_div = "after_image_upload_div";
      this._cd.detectChanges();

      if (this.count < 3) {
        setTimeout(() => {
          this.disableArrow();
        }, 500);

        // $('#uploadImage0').length >0

        if (
          this.count == 1 &&
          this.ohr_flow == "2" &&
          $("#agree").is(":checked")
        ) {
          $(".visibility_block a").addClass("visibility_none");
          setTimeout(() => {
            $(".visibility_block")
              .removeClass("visibility_block")
              .addClass("visibility_none");
          }, 500);
        }

        if (this.getOHRDataLength() > 0) {
          $(".next-slide").trigger("click");
          $(".prev-btn").removeAttr("disabled");
          $(".prev-btn").removeClass("disabled");
          $(".next-btn").attr("disabled", true);
          $(".upload-teeth-continue-btn").attr("disabled", true);
        }

        // window.scrollTo(0, 0);
        // $(window).scrollTop(0);
        // this.appservices.serviceMessages('vs-messages', message, 'danger')
      }
      if (this.count >= 3) {
        if (this.isfrontImage == 0) {
          window.scrollTo(0, 0);
          $(window).scrollTop(0);
          this.appservices.serviceMessages(
            "vs-messages",
            "Teeth Front View Image is required",
            "danger"
          );
        } else {
          // setTimeout(() => {

          //   if(currentIndex!=5)
          //   {
          //     $(".icon-right-arrow").trigger("click");
          //     $('.upload-teeth-continue-btn').attr("disabled", true);
          //     return false;
          //   }
          // }, 500);

          if (
            this.forthSlideVisited == false ||
            this.fifthSlideVisited == false ||
            this.sixthSlideVisited == false
          ) {
            $(".next-slide").trigger("click");
            $(".upload-teeth-continue-btn").attr("disabled", true);
            return false;
          } else {
            let currentIndex = $(".slick-active").attr("data-slick-index");
            // console.log(" currentIndex : "+ currentIndex);
            if (currentIndex != 5) {
              $(".next-slide").trigger("click");
              $(".upload-teeth-continue-btn").attr("disabled", true);
              return false;
            }
          }

          // if(this.ohr_flow=='2'&&!$('#agree').is(':checked')){
          //   this.termsAndConditions="0";
          // }else{

          // console.log(" this.get_contact_information_later : " + this.get_contact_information_later);
          if (this.get_contact_information_later == "yes") {
            // debugger;
            // this.showLoadingForm = true;
            setTimeout(() => {
              // this.isDataLoaded = true;
              // setTimeout(() => {

              //   this.showLoadingForm = false;

              //   this._cd.detectChanges();
              // }, 3000);
              this.appservices.sendAnalyticsData(
                '',
                '',
                "smilo_event",
                "sa_wg_Upload_images_done_visit_to_get_user_detail_form",
                true
              );
              this.showContactForm = true;
              this._cd.detectChanges();
            }, 100);

            this._cd.detectChanges();
            this.sendSubjectMessage = {
              backgroundProcessing: this.backgroundProcessing,
            };
            this.messageService.sendMessage(this.sendSubjectMessage);
            // this.appservices.sendAnalyticsData(gtag, fbq,  'smilo_event', 'sa_wg_lean_flow_Ohr_image_uploaded',false);
            // this.appservices.sendPageViewAnalyticsData(gtag, '',environment.BASE_PATH+'/contact-information', 'Contact Information - Lean Flow');
          } else {
            this.showLoadingForm = true;
          }
          // console.log(" Went outside ");
          this.isUploadButtonDisabled = false;
          $(".sec_inner_content1").hide();
          if (this.ohr_flow != 2) $(".analysing_sec").show();
          this.appservices.setSession("extra_images", this.extra_images, true);
          this.appservices.setSession("ohr_data", this.ohrdata, true);
          // this.appservices.setSession("ohrStoredImages", this.ohrStoredImages, true);

          // Removing the unique key from here to oninit
          // this.appservices.setSession("ohr_unique_key", this.todaysDataTime, false);

          //  console.log(" this.ohr_unique_key : " + this.appservices.getSession("ohr_unique_key", false));
          this.processOHRImages();
          //  }
        }
      }
    } else {
      this.count = $(".base64Image").length;
      this.isfrontImage = $("#uploadImage0").length;
      if (this.get_contact_information_later == "yes") {
        setTimeout(() => {
          this.appservices.sendAnalyticsData(
            '',
            '',
            "smilo_event",
            "sa_wg_Upload_images_done_visit_to_get_user_detail_form",
            true
          );
          this.showContactForm = true;
          this._cd.detectChanges();
        }, 100);
        this._cd.detectChanges();
        this.sendSubjectMessage = {
          backgroundProcessing: this.backgroundProcessing,
        };
        this.messageService.sendMessage(this.sendSubjectMessage);
      } else {
        this.showLoadingForm = true;
      }
      this.isUploadButtonDisabled = false;
      $(".sec_inner_content1").hide();
      if (this.ohr_flow != 2) $(".analysing_sec").show();
      this.appservices.setSession("extra_images", this.extra_images, true);
      this.appservices.setSession("ohr_data", this.ohrdata, true);
      this.processOHRImages();
    }
  }
  onAgree(event) {
    if (event.target.checked) this.termsAndConditions = "-1";
  }
  allLetter(inputtxt) {
    var letters = /^[a-zA-Z ]*$/;
    if (inputtxt.match(letters)) {
      return true;
    } else {
      return false;
    }
  }

  disableArrow() {
    let currentIndexPosition = $(".slick-active").attr("data-slick-index");
    // console.log(" currentIndexPosition : " + currentIndexPosition);
    // $('.slick-arrow').addClass('slick-disabled');
    $(".prev-btn").removeAttr("disabled");
    $(".prev-btn").removeClass("disabled");
    if (currentIndexPosition == "0") {
      $(".prev-btn").attr("disabled", true);
      $(".prev-btn").addClass("disabled");
      if ($("#uploadImage0").length > 0) {
        $(".next-btn").removeAttr("disabled");
      }
      this.displayPreviousBtn = "visibility_none";
      this.displaySkipBtn = "visibility_none";
      this._cd.detectChanges();
    }
    if (currentIndexPosition == "1") {
      this.displaySkipBtn = "visibility_none";
      $(".upload-teeth-continue-btn").attr("disabled", false);
      $(".next-btn").removeAttr("disabled");
      if ($("#uploadImage1").length == 0) {
        $(".next-btn").attr("disabled", true);
        $(".icon-right-arrow").addClass("slick-disabled");
        $(".upload-teeth-continue-btn").attr("disabled", true);
      }
    }

    if (currentIndexPosition == "2") {
      this.displaySkipBtn = "visibility_none";
      if ($("#uploadImage2").length == 0) {
        $(".icon-right-arrow").addClass("slick-disabled");
      }
    }
    if (currentIndexPosition == "4") {
      if ($("#uploadImage4").length == 0) {
        this.displaySkipBtn = "visibility_block";
      }
    }
    if (currentIndexPosition == "5") {
      $(".next-btn").attr("disabled", true);
    }
    this._cd.detectChanges();
  }

  processOHRImages() {
    this.input = {};
    this.apirequest = [];
    this.appservices.sendPageViewAnalyticsData(
      '',
      '',
      this.router.url,
      "OHR – Processing input images"
    );
    let extra_image = this.appservices.getSession("extra_images", true);
    if (extra_image[0].image)
      this.apirequest["extra_images"] = this.extra_images;
    this.apirequest["images"] = this.appservices.getSession("ohr_data", true);
    this.apirequest["oral_unique_id"] = this.appservices.getSession(
      "ohr_unique_key",
      false
    );

    this.apirequest["get_contact_information_later"] =
      this.get_contact_information_later;
    // this.apirequest["guest_session_user_id"]         = this.guest_session_uuid;
    this.apirequest["guest_user_id"] = this.guest_user_id;

    if (this.ohr_flow == "2") this.apirequest["image_upload_function"] = "true";

    this.apirequest["process_ml_function"] = "true";

    this.input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(" this.input : " + this.input);
    this.appservices
      .callJSONAPI(
        this.appservices.PROCESS_ORAL_HEALTH_IMAGES,
        this.input,
        "post",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          // console.log(" after PROCESS_ORAL_HEALTH_IMAGES ");
          // console.log(" data : " + data);
          if (data.status) {
            // console.log(" Before FETCHINGINDIVIDUALSCORES ");
            this.appservices
              .callJSONAPI(
                this.appservices.FETCHINGINDIVIDUALSCORES +
                "?oral_unique_id=" +
                this.appservices.getSession("ohr_unique_key", false) +
                "&timezone=Asia/Calcutta&get_contact_information_later=" +
                this.get_contact_information_later +
                "&guest_user_id=" +
                this.guest_user_id,
                "",
                "get",
                this,
                "127.0.0.1"
              )
              .subscribe(
                (data: any) => {
                  // console.log(
                  //   "🚀 ~ file: upload-teeth-photos.component.ts:974 ~ processOHRImages ~ data:",
                  //   data
                  // );
                  // console.log(" Before FETCHINGINDIVIDUALSCORES ");
                  if (data.status) {
                    this.appservices.sendAnalyticsData(
                      '',
                      '',
                      "smilo_event",
                      "sa_wg_ohr_pic_selected",
                      true
                    );
                    this.appservices.setSession(
                      "created_date",
                      data.result.created_date,
                      false
                    );
                    this.appservices.setSession(
                      "is_all_images_invalid",
                      data.result.is_all_images_invalid,
                      false
                    );
                    this.appservices.setSession(
                      "process_images",
                      data.result.scores,
                      true
                    );

                    if (this.appservices.hasSession("process_images")) {
                      let api_aish_key = this.appservices.getSession(
                        "api_key",
                        false
                      );
                      // console.log("Aishwarya- api key",api_aish_key);
                      // if(!this.appservices.getSession("api_key", false)) {

                      this.appservices
                        .callJSONAPI(
                          this.appservices.GETPRACTICEPAYMENTDETAILS +
                          "?widget_token=" +
                          this.appservices.getSession("token", false) +
                          "&timezone=Asia/Calcutta&selected_branch_id=" +
                          this.appservices.getSession(
                            "user_selected_practice_branch",
                            false
                          ) +
                          "&get_contact_information_later=" +
                          this.get_contact_information_later +
                          "&guest_user_id=" +
                          this.guest_user_id,
                          "",
                          "get",
                          this,
                          "127.0.0.1"
                        )
                        .subscribe(
                          (data: any) => {
                            //  console.log(data);

                            /*admin_practice_settings.ohr_flow,
                                            admin_practice_settings.title,
                                            admin_practice_settings.payment_enabled,
                                            admin_practice_settings.sub_title,
                                            admin_practice_settings.benefits,
                                            admin_practice_settings.amount,
                                            admin_practice_settings.title_for_button,
                                            admin_practice_settings.button_text,
                                            admin_practice_settings.payment_gateway,
                                            payment_gateways.gateway_name */

                            //console.log(" Before ");

                            this.sendSubjectMessage = {
                              backgroundProcessing: false,
                            };
                            this.messageService.sendMessage(
                              this.sendSubjectMessage
                            );

                            if (data.status) {
                              this.appservices.setSession(
                                "widget_flow_type",
                                "",
                                false
                              );
                              if (data.result.ohr_flow != "0") {
                                //  console.log(" inside ");
                                this.appservices.setSession(
                                  "widget_flow_type",
                                  "payment",
                                  false
                                );
                                //  console.log(" session set ");

                                this.appservices.setSession(
                                  "payment_title",
                                  "Your Oral Health Rating",
                                  false
                                );
                                if (
                                  !this.appservices.isEmpty(data.result.title)
                                ) {
                                  this.appservices.setSession(
                                    "payment_title",
                                    data.result.title,
                                    false
                                  );
                                }

                                this.appservices.setSession(
                                  "payment_sub_title",
                                  "The smilo.ai rating is for awareness only. Any information or result provided by Smilo.ai is indicative only and not a diagnosis.",
                                  false
                                );
                                if (
                                  !this.appservices.isEmpty(
                                    data.result.sub_title
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "payment_sub_title",
                                    data.result.sub_title,
                                    false
                                  );
                                }

                                this.appservices.setSession(
                                  "benefits_title",
                                  "Get Benifited with More Insights",
                                  false
                                );
                                if (
                                  !this.appservices.isEmpty(
                                    data.result.benefits_title
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "benefits_title",
                                    data.result.benefits_title,
                                    false
                                  );
                                }

                                this.appservices.setSession(
                                  "benefits_sub_title",
                                  "Visuals of AI Annotated Personalized Results",
                                  false
                                );
                                if (
                                  !this.appservices.isEmpty(
                                    data.result.benefits_sub_title
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "benefits_sub_title",
                                    data.result.benefits_sub_title,
                                    false
                                  );
                                }

                                this.appservices.setSession(
                                  "benefits_one",
                                  "Personalized Insights",
                                  false
                                );
                                if (
                                  !this.appservices.isEmpty(
                                    data.result.benefits_one
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "benefits_one",
                                    data.result.benefits_one,
                                    false
                                  );
                                }

                                if (
                                  !this.appservices.isEmpty(
                                    data.result.benefits_two
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "benefits_two",
                                    data.result.benefits_two,
                                    false
                                  );
                                }

                                if (
                                  !this.appservices.isEmpty(
                                    data.result.benefits_three
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "benefits_three",
                                    data.result.benefits_three,
                                    false
                                  );
                                }

                                if (
                                  !this.appservices.isEmpty(data.result.amount)
                                ) {
                                  this.appservices.setSession(
                                    "payment_amount",
                                    data.result.amount,
                                    false
                                  );
                                }

                                if (
                                  !this.appservices.isEmpty(
                                    data.result.title_for_button
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "title_for_button",
                                    data.result.title_for_button,
                                    false
                                  );
                                }

                                this.appservices.setSession(
                                  "payment_button_text",
                                  "Make Payment",
                                  false
                                );
                                if (data.result.payment_enabled == "0") {
                                  this.appservices.setSession(
                                    "payment_button_text",
                                    "Get my personalised report",
                                    false
                                  );
                                }
                                if (
                                  !this.appservices.isEmpty(
                                    data.result.button_text
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "payment_button_text",
                                    data.result.button_text,
                                    false
                                  );
                                }

                                if (
                                  !this.appservices.isEmpty(
                                    data.result.gateway_name
                                  )
                                ) {
                                  this.appservices.setSession(
                                    "payment_type",
                                    data.result.gateway_name,
                                    false
                                  );

                                  if (data.result.gateway_name === "stripe") {
                                    if (
                                      !this.appservices.isEmpty(
                                        data.result.api_key
                                      )
                                    ) {
                                      this.appservices.setSession(
                                        "api_key",
                                        data.result.api_key,
                                        false
                                      );
                                    }

                                    if (
                                      !this.appservices.isEmpty(
                                        data.result.secret_key
                                      )
                                    ) {
                                      this.appservices.setSession(
                                        "secret_key",
                                        data.result.secret_key,
                                        false
                                      );
                                    }
                                  }
                                }
                              }

                              // console.log("Outer");

                              this.widget_flow_type =
                                this.appservices.getSession(
                                  "widget_flow_type",
                                  false
                                );
                              // console.log(" this.widget_flow_type : " + this.widget_flow_type);
                              if (this.widget_flow_type === "payment") {
                                let user_id = this.appservices.getSession(
                                  "user_id",
                                  false
                                );
                                this.appservices
                                  .callJSONAPI(
                                    this.appservices.CALCULATINGSCORES +
                                    "?device_details=" +
                                    this.getDeviceInfo() +
                                    "&oral_unique_id=" +
                                    this.appservices.getSession(
                                      "ohr_unique_key",
                                      false
                                    ) +
                                    "&widget_token=" +
                                    this.appservices.getSession(
                                      "token",
                                      false
                                    ) +
                                    "&timezone=Asia/Calcutta&selected_branch_id=" +
                                    this.appservices.getSession(
                                      "user_selected_practice_branch",
                                      false
                                    ) +
                                    "&payment_transaction=true&get_contact_information_later=" +
                                    this.get_contact_information_later +
                                    "&guest_user_id=" +
                                    this.guest_user_id +
                                    "&user_id=" +
                                    user_id,
                                    "",
                                    "get",
                                    this,
                                    "127.0.0.1"
                                  )
                                  .subscribe(
                                    (data: any) => {
                                      if (data.status) {
                                        // console.log(" data.result.scores.score_id : " + data.result.scores.score_id);
                                        this.appservices.setSession(
                                          "health_score_id",
                                          data.result.scores.score_id,
                                          false
                                        );
                                        this.appservices.setSession(
                                          "overall_score",
                                          data.result.scores.overall_score,
                                          false
                                        );
                                        this.appservices.setSession(
                                          "redirecting_url",
                                          "payment-access-report",
                                          false
                                        );
                                        // this.isDataLoaded = true;
                                        // setTimeout(() => {
                                        //   this.appservices.sendAnalyticsData(
                                        //     gtag,
                                        //     fbq,
                                        //     "smilo_event",
                                        //     "sa_wg_Upload_images_done_visit_to_get_user_detail_form",
                                        //     true
                                        //   );
                                        //   this.showLoadingForm = false;
                                        //   this.router.navigate([
                                        //     "get-user-details",
                                        //   ]);
                                        // }, 3000);
                                        this.isAllApiDone = true;
                                        if (
                                          this.get_contact_information_later ==
                                          "yes"
                                        ) {
                                          // this.appservices.sendAnalyticsData(gtag, fbq,  'smilo_event', 'sa_wg_lean_flow_Ohr_image_uploaded',false);
                                          // this.appservices.sendPageViewAnalyticsData(gtag, '', this.router.url, 'Contact Information - Lean Flow');
                                          // console.log(" changed ");
                                          this.changeDetails();
                                          this._cd.detectChanges();
                                        } else {
                                          this.router.navigate([
                                            "payment-access-report",
                                          ]);
                                        }
                                        // this.contactFormBtn = false;
                                        // this.analyzingText = 'Please complete the contact form to move forward';
                                        // console.log(" this.analyzingText : " + this.analyzingText);
                                        // this.router.navigate(['oral-health-score']);
                                        // this.router.navigate(['payment-oral-health-score']);

                                        // this.router.navigate(['payment-access-report']);
                                        // if(this.get_contact_information_later=="yes")
                                        // {
                                        //   this.appservices.setSession("redirecting_url",'payment-access-report', false);
                                        //   this.router.navigate(['contact-information']);
                                        // }
                                        // else
                                        // {
                                        //   this.router.navigate(['payment-access-report']);
                                        // }
                                      } else {
                                        this.router.navigate(["no-internet"]);
                                      }
                                    },
                                    (error) => {
                                      // console.log(
                                      //   " CALCULATINGSCORES Error " + error
                                      // );
                                      //  this.appservices.showDebugMessage(" Error while Processing! Please Try once again.  ");
                                      // setTimeout(function() {
                                      //     window.location.reload();
                                      // }, 5000);
                                      this.router.navigate(["no-internet"]);
                                    }
                                  );
                              } else {
                                // console.log(" Else 553 ");
                                // console.log(" this.get_contact_information_later : " + this.get_contact_information_later);
                                if (
                                  this.get_contact_information_later == "yes" &&
                                  this.widget_flow_type === "payment"
                                ) {
                                  this.changeDetails();
                                  this._cd.detectChanges();
                                  this.appservices.setSession(
                                    "redirecting_url",
                                    "process-ohr-images",
                                    false
                                  );
                                  // this.router.navigate(['contact-information']);
                                } else if (
                                  this.get_contact_information_later == "yes" &&
                                  this.widget_flow_type === ""
                                ) {
                                  // console.log(" Here ");
                                  this.backgroundProcessing = false;
                                  this.sendSubjectMessage = {
                                    backgroundProcessing:
                                      this.backgroundProcessing,
                                  };
                                  this.messageService.sendMessage(
                                    this.sendSubjectMessage
                                  );

                                  this.appservices.setSession(
                                    "redirecting_url",
                                    "process-ohr-images",
                                    false
                                  );
                                  // console.log(" this.contactFormSubmitted : " + this.contactFormSubmitted);
                                  if (this.contactFormSubmitted === true) {
                                    // console.log(" Inside true ");
                                    // this.showLoadingForm = false;
                                    // this.showContactForm = true;
                                    // this.checkboxChecked = true;
                                    setTimeout(() => {
                                      // console.log(" Inside timeout ");
                                      this.onContactFormSubmit();
                                    }, 1000);
                                  }
                                } else {
                                  // console.log(" Else 561 ");
                                  this.router.navigate(["process-ohr-images"]);
                                }
                              }
                            } else {
                              // console.log(" Else 568 ");
                              // this.router.navigate(['process-ohr-images']);
                              this.router.navigate(["no-internet"]);
                            }
                          },
                          (error) => {
                            this.router.navigate(["no-internet"]);
                          }
                        );
                    }
                  } else {
                    //  this.appservices.showDebugMessage(data.message);
                    this.router.navigate(["no-internet"]);
                  }
                },
                (error) => {
                  this.router.navigate(["no-internet"]);
                }
              );
          } else {
            this.router.navigate(["no-internet"]);
          }
        },
        (error) => {
          //  this.appservices.showDebugMessage(error);
          this.router.navigate(["no-internet"]);
        }
      );
  }

  onInstructions() {
    let htmlElement: HTMLIFrameElement;
    let src;
    let elements = document.getElementsByClassName("yt_players");
    for (let i = 0; i <= elements.length; i++) {
      htmlElement = <HTMLIFrameElement>elements[0];
      src = htmlElement.getAttribute("src");
      htmlElement.setAttribute("src", src);
    }
  }

  async openCropperDialog(event: Event, rowid, title) {
    // console.log(" File selected ");
    this.currentSelectedRow = rowid;
    this.appservices.setSession(
      "currentSelectedRow",
      this.currentSelectedRow,
      false
    );
    $("body").addClass("modal-open");
    this.appservices.serviceMessages("vs-messages", "", "");
    let user_id = this.appservices.getSession("user_id", false);

    // console.log(" Before dialouge open ");

    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      //debugger;
      // console.log(target.files[0].name);
      var file = target.files[0];
      // console.log(" file : " + typeof file);

      var fileExt = file.name.split(".").pop();
      $(".please_wait_msg" + rowid).html("Please wait...");
      $(".continue_btn").html("Please wait...").attr("disabled", true);
      $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
      $(".upload_preview_span" + rowid).addClass("spinner-border");
      $(".upload_inner_section" + rowid).addClass("image_brightness");
      // $('.img_'+rowid).removeClass('opac');

      if (!$("#upload_image_div" + rowid).is(":visible")) {
        if (screen.width > 576) {
          //debugger;
          $("#overlay1" + rowid).css("width", "100%");
        } else {
          $("#overlay1" + rowid).css("width", "100%");
        }
        $("#overlay1" + rowid).addClass("overlay");
      } else {
        $("#overlay1" + rowid).addClass("overlay");
      }

      var uploadedImageHeight = 0;
      var uploadedImageWidth = 0;

      // console.log(" uploadedImageHeight  : " + uploadedImageHeight + "  uploadedImageWidth : "+ uploadedImageWidth );

      // console.log( " file.size : " +  file.size/1048576 );

      // compressing the file if the image is greater than 1mb
      if (Number(file.size / 1048576) >= 1) {
        // console.log(" Inside ");
        await this.readFile(file).then(async (data) => {
          await this.imageCompress
            .compressFile(data.toString(), 0, 50, 75)
            .then(async (result) => {
              // console.log(" result :  " + result);
              var blobFile = this.appservices.convertDataUrlToBlob(result);
              file = new File([blobFile], file.name, { type: file.type });
              // console.log(" file : " + typeof file);
              // console.log( " file.size : " +  file.size/1048576 );
              // const URL = window.URL || window.webkitURL;
              // const Img = new Image();
              // Img.src = URL.createObjectURL(file);
              // Img.onload = (e: any) => {
              //   uploadedImageHeight = e.path[0].height;
              //   uploadedImageWidth  = e.path[0].width;
              //   console.log(" uploadedImageHeight in : " + uploadedImageHeight + "  uploadedImageWidth : "+ uploadedImageWidth );
              // }
              await this.getImageDimensions(file).then(
                async (imageDimensionsData) => {
                  uploadedImageHeight = <any>imageDimensionsData["height"];
                  uploadedImageWidth = <any>imageDimensionsData["width"];
                }
              );
            });
        });
      } else {
        // console.log(" Outside asadsad ");
        await this.getImageDimensions(file).then(
          async (imageDimensionsData) => {
            uploadedImageHeight = <any>imageDimensionsData["height"];
            uploadedImageWidth = <any>imageDimensionsData["width"];
          }
        );
      }

      // console.log(" uploadedImageHeight out : " + uploadedImageHeight + "  uploadedImageWidth : "+ uploadedImageWidth );

      // console.log('finfo',file.name,file.size,file.type,fileExt);
      //max file size is 4 mb
      // if((file.size/1048576)<=4)
      // {
      // uploadToS3(file, file_name, folder_name, type)
      this.folder = "oralhealthscore_images/";
      let thisUploadObject = this;
      let greetingPromise = this.appservices.uploadToS3(
        file,
        target.files[0].name,
        this.folder,
        fileExt
      );
      greetingPromise.then(function (resolveOutput) {
        // console.log(" resolveOutput : " + JSON.stringify(resolveOutput));
        thisUploadObject.apirequest = [];
        thisUploadObject.apirequest["fileLocation"] = resolveOutput["Location"];
        if (thisUploadObject.ohr_flow == "2") {
          //debugger;
          thisUploadObject.apirequest["get_contact_information_later"] = "yes";
        }
        thisUploadObject.input =
          thisUploadObject.appservices.generteJSONFromArray(
            thisUploadObject.apirequest
          );
        thisUploadObject.appservices
          .callJSONAPI(
            thisUploadObject.appservices.GOOGLE_VISION_API,
            thisUploadObject.input,
            "post",
            thisUploadObject,
            ""
          )
          .subscribe((data: any) => {
            //this.vision_api_result= data.result;
            //console.log(this.vision_api_result);
            //debugger;
            $("#overlay1" + rowid).removeClass("overlay");
            $(".upload_preview_span" + rowid).removeClass("spinner-border");
            $(".upload_inner_section" + rowid).removeClass("image_brightness");
            $(".please_wait_msg" + rowid).html("");
            // console.log(data);
            //  if(!data.status)
            // {
            //   $('.errorFaceImageDiv').addClass('display_block').removeClass('display_none');
            //   setTimeout(() => {
            //      $('.errorFaceImageDiv').removeClass('display_block').addClass('display_none');
            //   },4500);

            //   return false;
            //  }

            thisUploadObject.appservices.setSession(
              "currentOhrImage",
              resolveOutput["Location"],
              true
            );
            thisUploadObject.appservices.setSession(
              "currentOhrImageCoordinates",
              data,
              true
            );

            thisUploadObject.appservices.setSession(
              "uploadedImageHeight",
              uploadedImageHeight,
              false
            );
            thisUploadObject.appservices.setSession(
              "uploadedImageWidth",
              uploadedImageWidth,
              false
            );

            thisUploadObject._dialog
              .open<CropperDialogComponent, Event>(CropperDialogComponent, {
                data: event,
                width: 150,
                disableClose: true,
              })
              .afterClosed.subscribe((result?: ImgCropperEvent) => {
                $("body").removeClass("modal-open");
                if (result) {
                  $("#upload_image_div" + rowid)
                    .removeClass("display_block")
                    .addClass("display_none");
                  if (rowid == 3 || rowid == 4) {
                    thisUploadObject.displaySkipBtn = "visibility_none";
                  }
                  if (screen.width > 576) {
                    $("#overlay1" + rowid).css("width", "100%");
                  }
                  $("#uploadImage" + (rowid + 1))
                    .removeClass("display_block")
                    .addClass("display_none");
                  // $('.image_upload' + rowid).parent().find('.upload_image_div').addClass();

                  thisUploadObject.count_value =
                    thisUploadObject.count_value + 1;
                  $(".please_wait_msg" + rowid).html("Please wait...");
                  $(".continue_btn")
                    .html("Please wait...")
                    .attr("disabled", true);
                  $("#overlay1" + rowid).addClass("overlay");
                  $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
                  $(".upload_preview_span" + rowid).addClass("spinner-border");
                  $(".upload_inner_section" + rowid).addClass(
                    "image_brightness"
                  );

                  // $('.image_upload' + rowid).removeClass('cursor_pointer').hide();

                  thisUploadObject.flag = true;
                  thisUploadObject.responseData[rowid].base64 = result.dataURL;
                  thisUploadObject.cropped_url_blob =
                    thisUploadObject.appservices.convertDataUrlToBlob(
                      result.dataURL
                    );
                  let fileExt = result.dataURL.split(";")[0].split("/")[1];
                  thisUploadObject.folder = "oralhealthscore_images/";
                  // $(".img_" + rowid).remove(); //sample image remove after image upload
                  if (thisUploadObject.get_contact_information_later == "yes") {
                    thisUploadObject.responseData[rowid].file_name =
                      thisUploadObject.guest_session_uuid +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  } else {
                    thisUploadObject.responseData[rowid].file_name =
                      user_id +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  }

                  // console.log(" thisUploadObject.responseData[rowid].file_name : " + this.responseData[rowid].file_name);

                  let thisobject = thisUploadObject;
                  let greetingPromise = thisobject.appservices.uploadToS3(
                    thisobject.cropped_url_blob,
                    thisobject.responseData[rowid].file_name,
                    thisobject.folder,
                    fileExt
                  );
                  greetingPromise.then(
                    function (resolveOutput) {
                      $("#uploadImage" + (rowid + 1))
                        .removeClass("display_none")
                        .addClass("display_block");
                      $(".errorImageDiv")
                        .removeClass("display_block")
                        .addClass("display_none");

                      // console.log(" resolveOutput : " + JSON.stringify(resolveOutput));
                      // console.log(" $(#lblName5).html() : " + $("#lblName5").html());
                      thisobject.pushImageDataInToArray(
                        rowid,
                        $("#lblName5").html(),
                        resolveOutput["Location"]
                      );
                      thisobject.count_value = thisobject.count_value - 1;
                      $(".avatar_preview" + rowid).addClass("edit_icon_hover");
                      $("#pointer_link" + rowid).addClass("pointer_link");

                      // console.log(" this.ohrdata.length : " + thisobject.ohrdata.length);
                      // console.log(" this.ohrdata.length : " + thisobject.getOHRDataLength());
                      // console.log(" rowid : " + rowid);

                      $("#checkMeOut").prop("checked"); // true

                      // $('.image_upload' + rowid).parent().find('.upload_image_icon').removeClass('mt-1').addClass('mt-3');
                      // $('.image_upload' + rowid).removeClass('mt-4').addClass('mt-5');

                      // upload_here_btn

                      // $('#after_image_upload_div' + rowid).find('.after_image_upload_span').html(thisobject.responseData[rowid].title + ' has been uploaded successfully please click on save & continue button');

                      // $("#upload_image_div" + rowid).addClass("display_none");

                      $("#uploadImage" + rowid).addClass("right_zero");

                      if (thisobject.ohr_flow != "2") {
                        if (rowid != "5")
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " picture uploaded successfully.<br> Please click on Save & Continue."
                          );
                        else
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " uploaded successfully.<br> Please click on Get your Results."
                          );
                      }
                      // $('#after_image_upload_div'+ rowid).removeClass('display_none').addClass('display_block');

                      // this.responseData[rowid].

                      // if(rowid==0&& thisobject.ohr_flow == '2' && !$('#agree').prop('checked'))
                      // {
                      //   $('.upload-teeth-continue-btn').attr("disabled", true);
                      // }

                      // if(rowid==0&& thisobject.ohr_flow == '2' && $('#agree').prop('checked'))
                      // {
                      //   $('.visibility_block a').addClass('visibility_none');
                      //   setTimeout(() => {
                      //     $('.visibility_block').removeClass('visibility_block').addClass('visibility_none');
                      //   }, 500);
                      // }

                      // if(thisobject.getOHRDataLength() > 0 && thisobject.getOHRDataLength() <= 2 )
                      // {
                      //   $('.upload-teeth-continue-btn').removeAttr('disabled');
                      // }

                      thisobject.appservices.sendAnalyticsData(
                        '',
                        '',
                        "smilo_event",
                        "sa_wg_ohr_image" + (rowid + 1),
                        true
                      );

                      if (rowid == "0") {
                        $(".icon-right-arrow").removeClass("slick-disabled");
                        $(".next-btn").removeAttr("disabled");
                      } else $(".slick-arrow").removeClass("slick-disabled");
                      $(".next-btn").removeAttr("disabled");
                      if (
                        thisobject.getOHRDataLength() >= 3 &&
                        $("#uploadImage0").length > 0
                      ) {
                        $("#num2").removeClass("disabled");
                        // $('.upload-teeth-continue-btn').removeAttr('disabled');
                      }
                      // console.log(" rowid : " + rowid);
                      if (rowid == 5) {
                        $(".next-btn").attr("disabled", true);
                        $(".save_continue_btn_span").html("Get your Results");
                      }

                      if (thisobject.ohr_flow == "2") {
                        thisobject.appservices.setSession(
                          "ohr_data",
                          thisobject.ohrdata,
                          true
                        );
                        let extra_image = thisobject.appservices.getSession(
                          "extra_images",
                          true
                        );
                        // console.log(" extra_image : " +  extra_image);
                        // if (extra_image[0].image)
                        if (!thisobject.appservices.isEmpty(extra_image)) {
                          thisobject.apirequest["extra_images"] =
                            thisobject.extra_images;
                        }
                        thisobject.apirequest["images"] =
                          thisobject.appservices.getSession("ohr_data", true);
                        thisobject.apirequest["ohrStoredImages"] =
                          thisobject.ohrStoredImages;

                        thisobject.apirequest["oral_unique_id"] =
                          thisobject.appservices.getSession(
                            "ohr_unique_key",
                            false
                          );

                        thisobject.apirequest["get_contact_information_later"] =
                          thisobject.get_contact_information_later;
                        // thisobject.apirequest["guest_session_user_id"]         = thisobject.guest_session_uuid;
                        thisobject.apirequest["guest_user_id"] =
                          thisobject.guest_user_id;

                        thisobject.apirequest["image_upload_function"] = "true";

                        thisobject.apirequest["process_ml_function"] = "false";

                        thisobject.input =
                          thisobject.appservices.generteJSONFromArray(
                            thisobject.apirequest
                          );
                        // console.log(" thisobject.input : " + thisobject.input);
                        // thisobject.input = thisobject.appservices.generteJSONFromArray(thisobject.apirequest);
                        //console.log(" thisobject.input : " + thisobject.input)
                        thisobject.appservices
                          .callJSONAPI(
                            thisobject.appservices.PROCESS_ORAL_HEALTH_IMAGES,
                            thisobject.input,
                            "post",
                            thisobject,
                            ""
                          )
                          .subscribe((data: any) => {
                            // console.log(" data.status : " + data.status);
                            if (data.status) {
                              if (rowid != "5")
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " picture uploaded successfully.<br> Please click on Save & Continue."
                                );
                              else
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " uploaded successfully.<br> Please click on Get your Results."
                                );

                              $("#overlay1" + rowid).removeClass("overlay");
                              $(".upload_preview_span" + rowid).removeClass(
                                "spinner-border"
                              );
                              $(".upload_inner_section" + rowid).removeClass(
                                "image_brightness"
                              );
                              $(".please_wait_msg" + rowid).html("");
                              $(".upload-teeth-continue-btn").removeAttr(
                                "disabled"
                              );

                              $("#overlay1" + rowid).css("width", "");
                            } else {
                              this.appservices.setSession(
                                "has_technical_error",
                                "true",
                                false
                              );
                              thisobject.router.navigate(["no-internet"]);
                            }
                          });
                      } else {
                        $("#overlay1" + rowid).removeClass("overlay");
                        $(".upload_preview_span" + rowid).removeClass(
                          "spinner-border"
                        );
                        $(".upload_inner_section" + rowid).removeClass(
                          "image_brightness"
                        );
                        $(".please_wait_msg" + rowid).html("");
                        $(".upload-teeth-continue-btn").removeAttr("disabled");

                        $("#overlay1" + rowid).css("width", "");
                      }

                      //  console.log(this.ohrdata);
                      // if(thisobject.getOHRDataLength()==5 && $('#uploadImage5').length >0)
                      // {
                      //   $('.save_continue_btn_span').html('Get your Results');
                      // }
                      if (thisobject.count_value == 0)
                        // $('#circles > div').removeClass('disabled');
                        $(".continue_btn")
                          .html("Continue")
                          .attr("disabled", false);
                    },
                    function (rejectOutput) { }
                  );
                  thisobject.uploadImageInputForm.patchValue({
                    file: result.dataURL,
                  });
                  thisobject._cd.markForCheck();
                }
              });
          });
      });

      // }else{
      //   //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
      // }
    }
  }
  getOHRDataLength() {
    return this.ohrdata.length;
  }

  get myForm() {
    return this.uploadImageInputForm.get("file");
  }
  pushImageDataInToArray(rowid, title, s3Location) {
    if (rowid != 5) {
      if (this.ohrdata.length == 0) {
        if (this.imageSelectionCount != 1) {
          this.ohrdata.push({
            image: this.responseData[rowid].file_name,
            title: this.responseData[rowid].title,
          });
          this.ohrStoredImages.push({
            image: this.responseData[rowid].file_name,
            base64: s3Location,
            title: this.responseData[rowid].title,
            id: rowid,
          });
        } else {
          this.ohrdata.push({
            image: this.responseDataSingleImage[rowid].file_name,
            title: this.responseDataSingleImage[rowid].title,
          });
          this.ohrStoredImages.push({
            image: this.responseDataSingleImage[rowid].file_name,
            base64: s3Location,
            title: this.responseDataSingleImage[rowid].title,
            id: rowid,
          });
        }
      } else {
        if (this.imageSelectionCount != 1) {
          let found = false;
          this.ohrdata.forEach((element) => {
            if (element.image == this.responseData[rowid].file_name) {
              found = true;
            }
          });
          if (found == false) {
            // let obj = { "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title };
            // this.ohrdata.splice(rowid, 1, obj)
            this.ohrdata.push({
              image: this.responseData[rowid].file_name,
              title: this.responseData[rowid].title,
            });
            this.ohrStoredImages.push({
              image: this.responseData[rowid].file_name,
              base64: s3Location,
              title: this.responseData[rowid].title,
              id: rowid,
            });
            // console.log(this.ohrdata);
          } else {
            let foundMe = false;
            this.ohrStoredImages.forEach((element) => {
              if (element.image == this.responseData[rowid].file_name) {
                foundMe = true;
              }
            });
            if (foundMe == false) {
              this.ohrStoredImages.push({
                image: this.responseData[rowid].file_name,
                base64: s3Location,
                title: this.responseData[rowid].title,
                id: rowid,
              });
              this.ohrStoredImages.sort((a, b) => a.id - b.id);
            }
          }
        } else {
          let found = false;
          this.ohrdata.forEach((element) => {
            if (
              element.image == this.responseDataSingleImage[rowid].file_name
            ) {
              found = true;
            }
          });
          if (found == false) {
            // let obj = { "image": this.responseDataSingleImage[rowid].file_name, "title": this.responseDataSingleImage[rowid].title };
            // this.ohrdata.splice(rowid, 1, obj)
            this.ohrdata.push({
              image: this.responseDataSingleImage[rowid].file_name,
              title: this.responseDataSingleImage[rowid].title,
            });
            this.ohrStoredImages.push({
              image: this.responseDataSingleImage[rowid].file_name,
              base64: s3Location,
              title: this.responseDataSingleImage[rowid].title,
              id: rowid,
            });
            // console.log(this.ohrdata);
          } else {
            let foundMe = false;
            this.ohrStoredImages.forEach((element) => {
              if (
                element.image == this.responseDataSingleImage[rowid].file_name
              ) {
                foundMe = true;
              }
            });
            if (foundMe == false) {
              this.ohrStoredImages.push({
                image: this.responseDataSingleImage[rowid].file_name,
                base64: s3Location,
                title: this.responseDataSingleImage[rowid].title,
                id: rowid,
              });
              this.ohrStoredImages.sort((a, b) => a.id - b.id);
            }
          }
        }
      }
    } else {
      // console.log(" thisobject.getOHRDataLength() : " + this.getOHRDataLength());

      this.extra_images = [];
      this.extra_images.push({
        image: this.responseData[rowid].file_name,
        title: title,
      });
      this.ohrStoredImages.push({
        image: this.responseData[rowid].file_name,
        base64: s3Location,
        title: this.responseData[rowid].title,
        id: rowid,
      });
      this.appservices.setSession("extra_images", this.extra_images, true);
    }
  }
  closeModal(option: string = "default") {
    // if (option === "otp") {
    //   this.isInValidOtp = false;
    //   this.digitOne = "";
    //   this.digitTwo = "";
    //   this.digitThree = "";
    //   this.digitFour = "";
    //   this.isTimerOver = false;
    //   this.timerSubscription.unsubscribe();
    // }
    this.modalRef.hide();
  }
  openModalVerifyOtp(template: TemplateRef<any>) {
    this.isSubmitted = true;
    if (this.InputForm.invalid || this.isOtpSuccess) {
      return;
    }
    if (this.InputForm.valid) {
      let paramArray: any[] = [];
      let paramsJson: any;
      paramArray["mobile_number"] = this.phoneNumber;
      paramsJson = this.appservices.generteJSONFromArray(paramArray);
      console.log(
        "🚀 ~ file: upload-teeth-photos.component.ts:1979 ~ openModalVerifyOtp ~ paramsJson:",
        paramsJson
      );
      this.appservices
        .callJSONAPI(this.appservices.REQUEST_OTP, paramsJson, "post", this, "")
        .subscribe((data: any) => {
          console.log(
            "🚀 ~ file: upload-teeth-photos.component.ts:1986 ~ .subscribe ~ data: ",
            data
          );
          // debugger;
          if (data.status) {
            this.isOtpReceive = true;
            this.modalRef = this.modalService.show(template, {
              class: "custom_notifications_modal vertical_align_center",
              animated: true,
              backdrop: "static",
            });
            this.timer = 30;
            this.startTimer();
          } else {
            this.isOtpReceive = false;
          }
        });
    }
  }

  openModal(template: TemplateRef<any>) {
    // e.stopPropagation();
    loadScript();
    this.modalRef = this.modalService.show(template, {
      class: "custom_notifications_modal vertical_align_center",
      animated: true,
      backdrop: "static",
    });
  }
  openViewModal(template: TemplateRef<any>, id) {
    this.displayImage = this.imagesInfo[id].url;
    this.displayTitle =
      id != 5 ? this.imagesInfo[id].title : $("#lblName5").html();
    this.modalRef = this.modalService.show(template, {
      class: "custom_notifications_modal vertical_align_center",
      animated: true,
      backdrop: "static",
    });
  }

  // Inherited from Contact Form

  async onEnterData(event) {
    // this.signInHasError(event);
    let nameType = event.target.getAttribute("formControlName");
    let nameVal = event.target.value;
    if (nameType === "firstName") {
      this.firstName = nameVal;
    } else if (nameType === "lastName") {
      this.lastName = nameVal;
    } else if (nameType === "phoneNumber") {
      this.phoneNumber = nameVal;
      this.isSubmitted = false;
      // await this.signInHasError(event);
    }
    // console.log(" this.signInValidNumber : " + this.signInValidNumber );
    if (this.InputForm.valid) {
      this.isButtonDisabled = false;
    } else this.isButtonDisabled = true;
  }
  onCheckboxChange(e, type) {
    this.appservices.serviceMessages("vs-messages", "", "");
  }
  onContactFormSubmit() {
    this.isSubmitted = true;
    this.isOtpReceive = false;
    // console.log("submited");
    // if(!this.signInValidNumber) { return false; }

    if (this.InputForm.invalid) {
      return;
    }
    if (
      (this.InputForm.valid && !this.isOtpVerificationProcessExist) ||
      (this.InputForm.valid &&
        this.isOtpVerificationProcessExist &&
        this.isOtpSuccess)
    ) {
      this.isOtpReceive = false;
      localStorage.setItem(
        "Input_form_data",
        JSON.stringify(this.InputForm.value)
      );
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.appservices.setUserEmail(this.InputForm.value.email.trim());
      this.contactFormSubmitted = true;
      if (this.backgroundProcessing === false) {
        this.analyzingText = "Please wait while we analyse your oral health";
        this.showContactForm = false;
        this.showLoadingForm = true;
        this.isDataLoaded = false;
        this.callContactAPI();
      } else if (this.backgroundProcessing === true) {
        this.analyzingText = "Please wait while we analyse your oral health";
        this.showContactForm = false;
        this.showLoadingForm = true;
        this.isDataLoaded = false;
      }
      // this.router.navigate(['email-confirmation']);
      //  }
    }
  }
  callContactAPI() {
    if (true) {
      $(".coninue_btn").html("Please wait...").attr("disabled", true);
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.apirequest = [];
      this.apirequest["first_name"] = this.user_details.firstName;
      this.apirequest["last_name"] = this.user_details.lastName;
      this.apirequest["email"] = this.user_details.email;
      this.apirequest["phone"] = this.user_details.phoneNumber;
      this.apirequest["widget_token"] = this.appservices.getSession(
        "token",
        false
      );
      let dail_code = this.appservices.getSession("dial_code", false)
        ? this.appservices.getSession("dial_code", false).toString()
        : "1";
      this.apirequest["dial_code"] = dail_code;

      this.apirequest["country_name"] = this.country_name;

      this.apirequest["branch_id"] = this.appservices.getSession(
        "user_selected_practice_branch",
        false
      );

      this.input =
        '"user":' + this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices
        .callJSONAPI(
          this.appservices.WIDGET_LOGIN,
          this.input,
          "post",
          this,
          ""
        )
        .subscribe(
          (data: any) => {
            if (data.status) {
              this.appservices.setSession("existing_user", "no", false);
              this.header_display_msg =
                "Smilo.ai will create an account with auto generated password for you and send an email with login details. please use login details to view/download Oral Health Rating and Design my smile report.";
              if (data.result.existing_user === "true") {
                this.appservices.setSession("existing_user", "yes", false);
              }

              let full_name =
                data.result.user.first_name + data.result.user.last_name;
              this.appservices.setSession("is_logged_in", "true", false);
              this.appservices.setSession(
                "user_access_token",
                data.result.user.user_access_token,
                false
              );
              this.appservices.setSession(
                "first_name",
                data.result.user.first_name,
                false
              );
              this.appservices.setSession(
                "last_name",
                data.result.user.last_name,
                false
              );
              this.appservices.setSession("full_name", full_name, false);
              this.appservices.setSession(
                "email",
                data.result.user.email,
                false
              );
              this.appservices.setSession(
                "user_id",
                data.result.user.user_id,
                false
              );
              if (data.result.user.phone)
                this.appservices.setSession(
                  "phoneNumber",
                  data.result.user.phone,
                  false
                );

              if (data.result.user.dial_code)
                this.appservices.setSession(
                  "dial_code",
                  data.result.user.dial_code,
                  false
                );

              if (data.result.user.city)
                this.appservices.setSession(
                  "city",
                  data.result.user.city,
                  false
                );

              if (data.result.user.state_id)
                this.appservices.setSession(
                  "state_id",
                  data.result.user.state_id,
                  false
                );

              if (data.result.user.country_id)
                this.appservices.setSession(
                  "country_id",
                  data.result.user.country_id,
                  false
                );

              if (data.result.user.dial_country_code)
                this.appservices.setSession(
                  "country_name",
                  data.result.user.dial_country_code,
                  false
                );

              this.appservices.setSession(
                "profile_picture",
                data.result.user.profile_picture,
                false
              );

              this.appservices.sendAnalyticsData(
                '',
                '',
                "smilo_event",
                "sa_wg_personal_details_entrered",
                true
              );

              this.apirequest = [];
              this.apirequest["first_name"] = this.user_details.firstName;
              this.apirequest["last_name"] = this.user_details.lastName;
              this.apirequest["phone"] = this.user_details.phoneNumber;
              this.apirequest["report_id"] = this.appservices.getSession(
                "ohr_unique_key",
                false
              );
              this.apirequest["user_id"] = this.appservices.getSession(
                "user_id",
                false
              );
              this.apirequest["guest_user_id"] = this.guest_user_id;
              this.apirequest["widget_token"] = this.appservices.getSession(
                "token",
                false
              );
              this.apirequest["overall_score"] = this.appservices.getSession(
                "overall_score",
                false
              );
              this.apirequest["selected_branch_id"] =
                this.appservices.getSession("selected_branch_id", false);
              this.apirequest["health_score_id"] = this.appservices.getSession(
                "health_score_id",
                false
              );
              this.apirequest["timezone"] = "Asia/Calcutta";
              this.apirequest["device_details"] = this.getDeviceInfo();
              let report_input = this.appservices.generteJSONFromArray(
                this.apirequest
              );
              if (!this.isTeethWhiteningType2 && !this.isTeethWhitening) {
                this.appservices
                  .callJSONAPI(
                    this.appservices.UPDATE_OHR_USER_ID,
                    report_input,
                    "post",
                    this,
                    ""
                  )
                  .subscribe((data: any) => {
                    if (data.status) {
                      this.callMedicalQueSaveAPI();
                    } else {
                      this.router.navigate(["no-internet"]);
                    }
                  });
              } else {
                this.router.navigate(["teeth-whitening-result"]);
              }
            } else {
              this.appservices.serviceMessages(
                "vs-messages",
                data.message,
                "danger"
              );
              if (!this.appservices.getSession("token", false)) {
                this.router.navigate([
                  "get-started/" + this.appservices.getSession("token", false),
                ]);
              } else {
                this.router.navigate(["no-internet"]);
              }
            }
          },
          (error) => {
            // this.appservices.showDebugMessage(error);
            this.router.navigate(["no-internet"]);
          }
        );
    }
  }
  callMedicalQueSaveAPI() {
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession(
      "token",
      false
    );
    this.apirequest["questions"] = this.appservices.getSession(
      "questions_array",
      true
    );
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices
      .callJSONAPI(
        this.appservices.MEDICAL_QUESTIONNAIRE_SAVE,
        input,
        "post",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          if (data.status) {
            // this.appservices.serviceMessages('vs-messages', data.message, 'success');
            this.appservices.sendAnalyticsData(
              '',
              '',
              "smilo_event",
              "sa_wg_Upload_images_user_info_submitted",
              true
            );
            this.redirecting_url = this.appservices.getSession(
              "redirecting_url",
              false
            );

            if (!this.appservices.isEmpty(this.redirecting_url)) {
              this.isDataLoaded = true;
              this._cd.detectChanges();
              setTimeout(() => {
                // this.appservices.setSession("get_contact_information_later",'', false);

                this.appservices.setSession(
                  "hasRedirectedFromContactForm",
                  "true",
                  false
                );

                this.router.navigate([this.redirecting_url]);
              }, 2000);
            } else {
              this.uploadImageBtnSelected = this.appservices.getSession(
                "uploadImageBtnSelected",
                false
              );
              if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "false"
              ) {
                this.router.navigate(["oral-health-score-preference"]);
              } else if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "true"
              ) {
                this.router.navigate(["practice-images"]);
                // this.router.navigate(['oral-health-score-preference']);
              }
              // this.router.navigate(['oral-health-score-preference']);
            }
          } else {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "danger"
            );
            this.router.navigate(["no-internet"]);
          }
        },
        (error) => {
          //  this.appservices.showDebugMessage(error);
          this.router.navigate(["no-internet"]);
        }
      );
  }

  // numberOnly(event): boolean {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }
  get ci() {
    return this.InputForm.controls;
  }
  get inputOtpForm() {
    return this.inputFormOtp.controls;
  }

  nameLength(classname) {
    if ($("." + classname).val().length > 30) {
      $("." + classname).val(
        $("." + classname)
          .val()
          .substr(0, 30)
      );
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  countryChange(country: any) {
    this.dialCode = country?.dialCode;
    let a = this.appservices.getSession("contactus_country_name", false);
    if (!a) {
      return;
      this.appservices.setSession("country_name", "IN", false);
      this.appservices.setSession("dial_code", "91", false);
      this.appservices.setSession("contactus_country_name", "IN", false);
    } else {
      this.country_name = country.iso2;
      this.countryCodeNumber = country.dialCode;
      this.appservices.setSession("country_name", this.country_name, false);
      this.appservices.setSession("dial_code", this.countryCodeNumber, false);
      this.appservices.setSession(
        "contactus_country_name",
        this.country_name,
        false
      );
    }
    this.country_name = country.iso2;
    this.countryCodeNumber = country.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.appservices.setSession(
      "contactus_country_name",
      this.country_name,
      false
    );
    this.setValidators();
  }

  telInputObject(obj) {
    if (this.appservices.hasSession("contactus_country_name")) {
      this.contactus_country_name = this.appservices.getSession(
        "contactus_country_name",
        false
      );
      obj.setCountry(this.contactus_country_name.toUpperCase());
    } else {
      this.getCountry(obj);
    }
  }

  setValidators() {
    if (this.country_name == "au") {
      this.InputForm.get("phoneNumber").setValidators([
        Validators.required,
        Validators.pattern(
          /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/
        ),
      ]);
      this.InputForm.get("phoneNumber").updateValueAndValidity();
    } else {
      this.InputForm.get("phoneNumber").setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{10}$"),
      ]);
      this.InputForm.get("phoneNumber").updateValueAndValidity();
    }
  }

  callAppDetailsAPI() {
    this.appservices
      .callJSONAPI(
        this.appservices.APPDETAILS + "?",
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          if (data.status) {
            this.appservices.setSession(
              "app_details",
              data.result.app_details,
              true
            );
            this.appservices.setSession(
              "smile_design",
              data.result.app_details.smile_design,
              true
            );
            this.appservices.setSession(
              "gum_types",
              data.result.app_details.smile_design.gum_types,
              true
            );
            this.appservices.setSession(
              "teeth_shade",
              data.result.app_details.smile_design.teeth_shade,
              true
            );
            this.appservices.setSession(
              "teeth_template",
              data.result.app_details.smile_design.teeth_template,
              true
            );
          } else {
            this.router.navigate(["no-internet"]);
          }
        },
        (error) => {
          // this.appservices.showDebugMessage(error);
          this.router.navigate(["no-internet"]);
        }
      );
  }

  onSelectPractice(selectedPractice) {
    if (selectedPractice.target.value != "") {
      this.isBranchesButtonDisabled = false;
      this.appservices.setSession(
        "user_selected_practice_branch",
        selectedPractice.target.value,
        false
      );
      let selectedIndex: number = selectedPractice.target["selectedIndex"];
      this.userSelectedAddress =
        selectedPractice.target.options[selectedIndex].getAttribute(
          "data-isocode"
        );
    } else {
      this.userSelectedAddress = "";
      this.isBranchesButtonDisabled = true;
    }
  }

  moveToContactForm() {
    this.display_branches_div = false;
    this.display_contactForm_div = true;
  }

  hasError1(event: any): void {
    if (!event && this.InputForm.value.cellnumber1) {
      this.InputForm.get("cellnumber1").setErrors(["invalid_cell_phone", true]);
    }
  }

  signInHasError(status: any) {
    if (status == null) {
      this.signInValidNumber = false;
      this.phoneSignIn.setValue(null);
    } else {
      this.signInValidNumber = status;
    }
  }

  signInGetNumber(n: string) {
    this.signInPhoneNumber = n;
  }

  signInOnCountryChange(c: any) {
    this.signInSelectedCountryCode = "+" + c.dialCode;
    let up: string = c.iso2;
    this.signInSelectedCountry = up.toUpperCase();

    this.country_name = c.iso2;
    this.countryCodeNumber = c.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.isSubmitted = false;
    // this.setValidators();
  }

  changeDetails() {
    this.backgroundProcessing = false;
    this.sendSubjectMessage = {
      backgroundProcessing: this.backgroundProcessing,
    };
    this.messageService.sendMessage(this.sendSubjectMessage);
    this.contactFormBtn = false;
    // this.analyzingText = 'Please complete the contact form to move forward';
    if (this.contactFormSubmitted === true) {
      // this.showContactForm = true;
      // this.showLoadingForm = false;
      // this.checkboxChecked = true;
      setTimeout(() => {
        this.onContactFormSubmit();
      }, 500);
    }
  }

  updateImgAfterLoad() {
    this.applyStyle = "uploadedImage";
    this._cd.detectChanges();
  }

  trigger_upload_btn() {
    $(".upload_here_btn").trigger("click");
  }

  moveCarousel(movementArea: any) {
    if (movementArea === "left") {
      this.sixthSlideVisited = false;
      $(".next-btn").removeAttr("disabled");
      $(".prev-slide").trigger("click");
      setTimeout(() => {
        this.after_image_upload_div = "after_image_upload_div_v2";
        this._cd.detectChanges();
        $(".errorImageDiv")
          .removeClass("display_block")
          .addClass("display_none");
      }, 500);
    } else {
      $(".next-slide").trigger("click");
    }
  }

  checkImageUploaded() {
    $(".errorImageDiv").removeClass("display_block").addClass("display_none");
    if ($(".upload-teeth-continue-btn").is(":disabled")) {
      $(".errorImageDiv").removeClass("display_none").addClass("display_block");
    }
  }

  getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      const URL = window.URL || window.webkitURL;
      const Img = new Image();
      Img.src = URL.createObjectURL(file);
      Img.onload = (e: any) => {
        resolve(Img);
      };
      Img.onerror = function (error) {
        reject();
      };
    });
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject();
      };
    });
  }

  getCountry(obj) {
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
        obj.setCountry(res["country_code"] === "IN" ? "IN" : "IN");
      });
  }

  onOtpFormSubmit() { }

  onKeyOtp(event: any, index: number) {
    if (event.keyCode === 9 || event.keyCode === 8 || event.keyCode === 46) {
      if (event.keyCode === 8) {
        if (index === 0) {
          this.digitOne = "";
        }
        if (index === 1) {
          this.digitTwo = "";
        }
        if (index === 2) {
          this.digitThree = "";
        }
        if (index === 3) {
          this.digitFour = "";
        }
      }
      return true;
    } else if (event.keyCode >= 96 && event.keyCode <= 105) {
      if (index === 0) {
        this.digitOne = event.key;
      }
      if (index === 1) {
        this.digitTwo = event.key;
      }
      if (index === 2) {
        this.digitThree = event.key;
      }
      if (index === 3) {
        this.digitFour = event.key;
      }
      return true;
    } else {
      if (index === 0) {
        this.digitOne = "";
      }
      if (index === 1) {
        this.digitTwo = "";
      }
      if (index === 2) {
        this.digitThree = "";
      }
      if (index === 3) {
        this.digitFour = "";
      }
      return false;
    }
  }

  setFocus(value: any, index: number) {
    setTimeout(() => {
      if (value) {
        const inputElements = document.getElementsByName(`otpNum${index + 1}`);
        if (inputElements && index < 3) {
          inputElements[0]?.focus();
        }
      }
    }, 50);
  }

  onVerifyOtp() {
    if (
      this.digitOne !== "" &&
      this.digitTwo !== "" &&
      this.digitThree !== "" &&
      this.digitFour !== ""
    ) {
      let otpString =
        this.digitOne + this.digitTwo + this.digitThree + this.digitFour;
      const otp = parseInt(otpString);
      let paramArray: any[] = [];
      let paramsJson: any;
      paramArray["mobile_number"] = this.phoneNumber;
      paramArray["otp"] = otp;
      paramsJson = this.appservices.generteJSONFromArray(paramArray);
      this.isInValidOtp = false;
      this.isOtpSuccess = true;
      this.closeModal("otp");
      this.appservices
        .callJSONAPI(this.appservices.VERIFY_OTP, paramsJson, "post", this, "")
        .subscribe((data: any) => {
          if (data.status) {
          }
        });
    } else {
      this.isInValidOtp = true;
    }
  }

  startTimer() {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.timerSubscription.unsubscribe();
        this.isTimerOver = true;
        // Handle timer expiration here (e.g., show a message)
      }
    });
  }

  onResendCode() {
    this.isTimerOver = false;
    this.timer = 30;
    this.timerSubscription.unsubscribe();
    this.startTimer();
  }

  onDeleteClick(data: any, index: any, e: any) {
    let requestData: any = [];
    requestData["title"] = data?.title;
    requestData["oral_unique_id"] = this.appservices.getSession(
      "ohr_unique_key",
      false
    );
    let input = this.appservices.generteJSONFromArray(requestData);

    this.appservices
      .callJSONAPI(this.appservices.DELETE_IMAGE, input, "post", this, "")
      .subscribe((apiRes: any) => {
        if (apiRes.status) {
          if (index !== 5) {
            $(".upload-teeth-continue-btn").attr("disabled", true);
            // this.ohrdata.splice(index, 1);
            // this.ohrdata = this.ohrdata.filter((item) => item.id != index);
            this.appservices.setSession("ohr_data", this.ohrdata, true);
          }
          this.ohrStoredImages = this.ohrStoredImages.filter(
            (item) => item.id != index
          );
          if (index == 5) {
            this.extra_images = [];
            this.extra_images.push({
              image: "",
              title: "Any additional images",
            });
            this.appservices.setSession(
              "extra_images",
              this.extra_images,
              true
            );
          }
          data.base64 = "";
          this._cd.detectChanges();
          $("#upload_image_div" + index)
            .removeClass("display_none")
            .addClass("display_block");
          $("#after_image_upload_text_div" + index).html("");
        }
      });
  }

  // whiteningImage;
  // teethImage = '';
  // teethwhitening = false;
  base64ToBlob(base64, mime) {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mime });
  }

  // teethWhiteningContinue(continueBtn) {
  //   if (continueBtn && (localStorage.getItem("teethWhiteningUploadedImage") && localStorage.getItem("teethWhiteningResultImage"))) {
  //     this.router.navigate(["teeth-whitening-result"]);
  //     this._cd.detectChanges();
  //   } else {
  //     this.renderer.listen("window", "message", (event: MessageEvent) => {
  //       if (
  //         event.origin === "https://smilo-iframe.tech-active.com" ||
  //         event.origin === "http://127.0.0.1:8080"
  //       ) {
  //         this.whiteningImage = event.data ? event.data : '';
  //         let blob;
  //         this.teethwhitening = this.whiteningImage.teethWhitening;
  //         if (this.whiteningImage.value) {
  //           blob = this.base64ToBlob(this.whiteningImage.value, 'application/octet-stream');
  //         }
  //         let file = new File([blob], this.whiteningImage.fileUrl, { type: fileExt });
  //         this.folder = "oralhealthscore_images/";
  //         var fileExt = this.whiteningImage.fileUrl.split(".").pop();
  //         let greetingPromise = this.appservices.uploadToS3(file, this.whiteningImage.fileUrl, this.folder, fileExt);
  //         let thisobject = this;
  //         greetingPromise.then((resolveOutput) => {
  //           if (this.teethwhitening == true) {
  //             localStorage.setItem("teethWhiteningResultImage", JSON.stringify(resolveOutput));
  //           } else {
  //             localStorage.setItem("teethWhiteningUploadedImage", JSON.stringify(resolveOutput));
  //           }
  //         }, function (rejectOutput) {
  //         });
  //       }
  //     });
  //   }
  // }

  onDeleteWhitening(rowid) {
    this.whiteninguploadedImage1 = '';
    $("#upload_image_div" + (rowid))
      .removeClass("display_none")
      .addClass("display_block");
    // this.whiteningContrastValue = 0;
    // this.whiteningBrightnessValue = 0;
    this.isUploadWhiteningImage = false;
    this._cd.detectChanges();
  }

  whiteninguploadedImage1 = '';
  getTeethWhiteningResult(event, rowid, title) {
    let target = event.target;
    if (target.files && target.files.length > 0) {
      let fileObj = target.files[0];
      let file_name = fileObj.name;
      let folder = "oralhealthscore_images/";
      var fileExt = file_name.split(".").pop();
      let greetingPromise = this.appservices.uploadToS3(
        fileObj,
        file_name,
        folder,
        fileExt
      );
      greetingPromise.then((resolveOutput) => {
        if (resolveOutput) {
          this.whiteninguploadedImage1 = resolveOutput["Location"];
          $("#uploadImage" + (rowid))
            .removeClass("display_none")
            .addClass("display_block");
          $(".errorImageDiv")
            .removeClass("display_block")
            .addClass("display_none");
          $("#upload_image_div" + (rowid))
            .removeClass("display_block")
            .addClass("display_none");
          $(".avatar_preview" + rowid).addClass("edit_icon_hover");
          $("#pointer_link" + rowid).addClass("pointer_link");
          //  $("#upload_image_div" + rowid)
          //    .removeClass("display_block")
          //    .addClass("display_none");
          //  $(".please_wait_msg" + rowid).html("Please wait...");
          //  $(".continue_btn").html("Please wait...").attr("disabled", true);
          //  $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
          //  $(".upload_preview_span" + rowid).addClass("spinner-border");
          //  $(".upload_inner_section" + rowid).addClass("image_brightness");

          //  if (!$("#upload_image_div" + rowid).is(":visible")) {
          //    if (screen.width > 576) {
          //      $("#overlay1" + rowid).css("width", "100%");
          //    } else {
          //      $("#overlay1" + rowid).css("width", "100%");
          //    }
          //    $("#overlay1" + rowid).addClass("overlay");
          //  } else {
          //    $("#overlay1" + rowid).addClass("overlay");
          //  }
          $(".upload-teeth-continue-btn").removeAttr("disabled");
          this.uploadImageInputForm.patchValue({
            file: this.whiteninguploadedImage1,
          });
          this._cd.markForCheck();
        }
      });
    }
  }

  onSubmitTeethWhitening() {
    $(".upload-teeth-continue-btn").attr("disabled", true);
    $(".save_continue_btn_span").html("Please wait...").attr("disabled", true);
    this.count = $(".base64Image").length;
    this.isfrontImage = $("#uploadImage0").length;
    // if ((this.isfrontImage && this.isUploadWhiteningImage ) || this.isWebCamImage) {
    //testing
    // if (this.isfrontImage){
      this.apirequest['image_urls'] = this.whiteninguploadedImage1;
      // let input = this.appservices.generteJSONFromArray(this.apirequest);
      // this.appservices
      //   .callJSONAPI(
      //     "https://ml-api.qs1.smilo.ai/smile_design/teeth_whitening_v2",
      //     input,
      //     "post",
      //     this,
      //     ""
      //   )
      //   .subscribe(
      //     (data: any) => {
      //       if (data.status) {
      //       }
      //     })
      const requestFormData = new FormData();
      requestFormData.append('image_urls', this.whiteninguploadedImage1);
      // requestFormData.append('contrast', this.whiteningContrastValue);
      // requestFormData.append('whiteness', this.whiteningBrightnessValue);

      const headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json',
      });
      this.httpClient.post("https://ml-api.qs1.smilo.ai/smile_design/teeth_whitening_v2", requestFormData, { headers }).subscribe((res: any) => {
        if (res.status && res.status_code == 200) {
          let responseObj = {
            type2ImageUploaded: res.result['input'],
            type2ImageResult: res.result['output']
          };
          this.appservices.setSession("teethWhitheningApiResult", responseObj, true);
          if (this.get_contact_information_later == "yes") {
            setTimeout(() => {
              this.showContactForm = true;
              this._cd.detectChanges();
            }, 100);
            this._cd.detectChanges();
            this.backgroundProcessing = false;
            this.sendSubjectMessage = {
              backgroundProcessing: this.backgroundProcessing,
            };
            this.messageService.sendMessage(this.sendSubjectMessage);
          } else {
            this.showLoadingForm = true;
            this.router.navigate(["teeth-whitening-result"]);
          }
          $(".sec_inner_content1").hide();
        } else {
          $(".continue_btn").html("Please wait...").attr("disabled", false);
          $(".save_continue_btn_span").html("Get your Results");
          this.router.navigate(["no-internet"]);
        }
      },
        (error) => {
          $(".continue_btn")
            .html("Please wait...")
            .attr("disabled", false);
          $(".save_continue_btn_span").html("Get your Results");
          console.error('Error:', error);
        });
    // }
  }

  async openCropperDialogForSingleImage(event: Event, rowid, title) {
    this.currentSelectedRow = rowid;
    this.appservices.setSession(
      "currentSelectedRow",
      this.currentSelectedRow,
      false
    );
    $("body").addClass("modal-open");
    this.appservices.serviceMessages("vs-messages", "", "");
    let user_id = this.appservices.getSession("user_id", false);

    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      var file = target.files[0];

      var fileExt = file.name.split(".").pop();
      $(".please_wait_msg" + rowid).html("Please wait...");
      $(".continue_btn").html("Please wait...").attr("disabled", true);
      $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
      $(".upload_preview_span" + rowid).addClass("spinner-border");
      $(".upload_inner_section" + rowid).addClass("image_brightness");
      // $('.img_'+rowid).removeClass('opac');

      if (!$("#upload_image_div" + rowid).is(":visible")) {
        if (screen.width > 576) {
          $("#overlay1" + rowid).css("width", "100%");
        } else {
          $("#overlay1" + rowid).css("width", "100%");
        }
        $("#overlay1" + rowid).addClass("overlay");
      } else {
        $("#overlay1" + rowid).addClass("overlay");
      }

      var uploadedImageHeight = 0;
      var uploadedImageWidth = 0;

      // compressing the file if the image is greater than 1mb
      if (Number(file.size / 1048576) >= 1) {
        await this.readFile(file).then(async (data) => {
          await this.imageCompress
            .compressFile(data.toString(), 0, 50, 75)
            .then(async (result) => {
              var blobFile = this.appservices.convertDataUrlToBlob(result);
              file = new File([blobFile], file.name, { type: file.type });
              await this.getImageDimensions(file).then(
                async (imageDimensionsData) => {
                  uploadedImageHeight = <any>imageDimensionsData["height"];
                  uploadedImageWidth = <any>imageDimensionsData["width"];
                }
              );
            });
        });
      } else {
        await this.getImageDimensions(file).then(
          async (imageDimensionsData) => {
            uploadedImageHeight = <any>imageDimensionsData["height"];
            uploadedImageWidth = <any>imageDimensionsData["width"];
          }
        );
      }
      this.folder = "oralhealthscore_images/";
      let thisUploadObject = this;
      let greetingPromise = this.appservices.uploadToS3(
        file,
        target.files[0].name,
        this.folder,
        fileExt
      );
      greetingPromise.then(function (resolveOutput) {
        thisUploadObject.apirequest = [];
        thisUploadObject.apirequest["fileLocation"] = resolveOutput["Location"];
        if (thisUploadObject.ohr_flow == "2") {
          thisUploadObject.apirequest["get_contact_information_later"] = "yes";
        }
        thisUploadObject.input =
          thisUploadObject.appservices.generteJSONFromArray(
            thisUploadObject.apirequest
          );
        thisUploadObject.appservices
          .callJSONAPI(
            thisUploadObject.appservices.GOOGLE_VISION_API,
            thisUploadObject.input,
            "post",
            thisUploadObject,
            ""
          )
          .subscribe((data: any) => {
            $("#overlay1" + rowid).removeClass("overlay");
            $(".upload_preview_span" + rowid).removeClass("spinner-border");
            $(".upload_inner_section" + rowid).removeClass("image_brightness");
            $(".please_wait_msg" + rowid).html("");
            //  if(!data.status)
            // {
            //   $('.errorFaceImageDiv').addClass('display_block').removeClass('display_none');
            //   setTimeout(() => {
            //      $('.errorFaceImageDiv').removeClass('display_block').addClass('display_none');
            //   },4500);
            //   return false;
            //  }

            thisUploadObject.appservices.setSession(
              "currentOhrImage",
              resolveOutput["Location"],
              true
            );
            thisUploadObject.appservices.setSession(
              "currentOhrImageCoordinates",
              data,
              true
            );

            thisUploadObject.appservices.setSession(
              "uploadedImageHeight",
              uploadedImageHeight,
              false
            );
            thisUploadObject.appservices.setSession(
              "uploadedImageWidth",
              uploadedImageWidth,
              false
            );

            thisUploadObject._dialog
              .open<CropperDialogComponent, Event>(CropperDialogComponent, {
                data: event,
                width: 150,
                disableClose: true,
              })
              .afterClosed.subscribe((result?: ImgCropperEvent) => {
                $("body").removeClass("modal-open");
                if (result) {
                  $("#upload_image_div" + rowid)
                    .removeClass("display_block")
                    .addClass("display_none");
                  if (rowid == 3 || rowid == 4) {
                    thisUploadObject.displaySkipBtn = "visibility_none";
                  }
                  if (screen.width > 576) {
                    $("#overlay1" + rowid).css("width", "100%");
                  }
                  $("#uploadImage" + (rowid + 1))
                    .removeClass("display_block")
                    .addClass("display_none");
                  thisUploadObject.count_value =
                    thisUploadObject.count_value + 1;
                  $(".please_wait_msg" + rowid).html("Please wait...");
                  $(".continue_btn")
                    .html("Please wait...")
                    .attr("disabled", true);
                  $("#overlay1" + rowid).addClass("overlay");
                  $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
                  $(".upload_preview_span" + rowid).addClass("spinner-border");
                  $(".upload_inner_section" + rowid).addClass(
                    "image_brightness"
                  );
                  thisUploadObject.flag = true;
                  thisUploadObject.responseDataSingleImage[rowid].base64 =
                    result.dataURL;
                  thisUploadObject.cropped_url_blob =
                    thisUploadObject.appservices.convertDataUrlToBlob(
                      result.dataURL
                    );
                  let fileExt = result.dataURL.split(";")[0].split("/")[1];
                  thisUploadObject.folder = "oralhealthscore_images/";
                  // $(".img_" + rowid).remove(); //sample image remove after image upload
                  if (thisUploadObject.get_contact_information_later == "yes") {
                    thisUploadObject.responseDataSingleImage[rowid].file_name =
                      thisUploadObject.guest_session_uuid +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  } else {
                    thisUploadObject.responseDataSingleImage[rowid].file_name =
                      user_id +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  }
                  let thisobject = thisUploadObject;
                  let greetingPromise = thisobject.appservices.uploadToS3(
                    thisobject.cropped_url_blob,
                    thisobject.responseDataSingleImage[rowid].file_name,
                    thisobject.folder,
                    fileExt
                  );
                  greetingPromise.then(
                    function (resolveOutput) {
                      $("#uploadImage" + (rowid + 1))
                        .removeClass("display_none")
                        .addClass("display_block");
                      $(".errorImageDiv")
                        .removeClass("display_block")
                        .addClass("display_none");
                      thisobject.pushImageDataInToArray(
                        rowid,
                        $("#lblName5").html(),
                        resolveOutput["Location"]
                      );

                      thisobject.count_value = thisobject.count_value - 1;
                      $(".avatar_preview" + rowid).addClass("edit_icon_hover");
                      $("#pointer_link" + rowid).addClass("pointer_link");
                      $("#checkMeOut").prop("checked"); // true             
                      $("#uploadImage" + rowid).addClass("right_zero");

                      if (thisobject.ohr_flow != "2") {
                        if (rowid != "5")
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " picture uploaded successfully.<br> Please click on Save & Continue."
                          );
                        else
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " uploaded successfully.<br> Please click on Get your Results."
                          );
                      }
                      thisobject.appservices.sendAnalyticsData(
                        '',
                        '',
                        "smilo_event",
                        "sa_wg_ohr_image" + (rowid + 1),
                        true
                      );

                      if (rowid == "0") {
                        $(".icon-right-arrow").removeClass("slick-disabled");
                        $(".next-btn").removeAttr("disabled");
                      } else $(".slick-arrow").removeClass("slick-disabled");
                      $(".next-btn").removeAttr("disabled");
                      if (
                        thisobject.getOHRDataLength() >= 3 &&
                        $("#uploadImage0").length > 0
                      ) {
                        $("#num2").removeClass("disabled");
                        // $('.upload-teeth-continue-btn').removeAttr('disabled');
                      }
                      if (rowid == 5) {
                        $(".next-btn").attr("disabled", true);
                        $(".save_continue_btn_span").html("Get your Results");
                      }

                      if (thisobject.ohr_flow == "2") {
                        thisobject.appservices.setSession(
                          "ohr_data",
                          thisobject.ohrdata,
                          true
                        );
                        let extra_image = thisobject.appservices.getSession(
                          "extra_images",
                          true
                        );
                        // if (extra_image[0].image)
                        if (!thisobject.appservices.isEmpty(extra_image)) {
                          thisobject.apirequest["extra_images"] =
                            thisobject.extra_images;
                        }
                        thisobject.apirequest["images"] =
                          thisobject.appservices.getSession("ohr_data", true);
                        thisobject.apirequest["ohrStoredImages"] =
                          thisobject.ohrStoredImages;

                        thisobject.apirequest["oral_unique_id"] =
                          thisobject.appservices.getSession(
                            "ohr_unique_key",
                            false
                          );

                        thisobject.apirequest["get_contact_information_later"] =
                          thisobject.get_contact_information_later;
                        // thisobject.apirequest["guest_session_user_id"]         = thisobject.guest_session_uuid;
                        thisobject.apirequest["guest_user_id"] =
                          thisobject.guest_user_id;

                        thisobject.apirequest["image_upload_function"] = "true";

                        thisobject.apirequest["process_ml_function"] = "false";

                        thisobject.input =
                          thisobject.appservices.generteJSONFromArray(
                            thisobject.apirequest
                          );
                        console.log(" thisobject.input : " + thisobject.input);
                        // thisobject.input = thisobject.appservices.generteJSONFromArray(thisobject.apirequest);
                        thisobject.appservices
                          .callJSONAPI(
                            thisobject.appservices.PROCESS_ORAL_HEALTH_IMAGES,
                            thisobject.input,
                            "post",
                            thisobject,
                            ""
                          )
                          .subscribe((data: any) => {
                            if (data.status) {
                              if (rowid != "5")
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " picture uploaded successfully.<br> Please click on Save & Continue."
                                );
                              else
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " uploaded successfully.<br> Please click on Get your Results."
                                );

                              $("#overlay1" + rowid).removeClass("overlay");
                              $(".upload_preview_span" + rowid).removeClass(
                                "spinner-border"
                              );
                              $(".upload_inner_section" + rowid).removeClass(
                                "image_brightness"
                              );
                              $(".please_wait_msg" + rowid).html("");
                              $(".upload-teeth-continue-btn").removeAttr(
                                "disabled"
                              );

                              $("#overlay1" + rowid).css("width", "");
                            } else {
                              this.appservices.setSession(
                                "has_technical_error",
                                "true",
                                false
                              );
                              thisobject.router.navigate(["no-internet"]);
                            }
                          });
                      } else {
                        $("#overlay1" + rowid).removeClass("overlay");
                        $(".upload_preview_span" + rowid).removeClass(
                          "spinner-border"
                        );
                        $(".upload_inner_section" + rowid).removeClass(
                          "image_brightness"
                        );
                        $(".please_wait_msg" + rowid).html("");
                        $(".upload-teeth-continue-btn").removeAttr("disabled");

                        $("#overlay1" + rowid).css("width", "");
                      }
                      // if(thisobject.getOHRDataLength()==5 && $('#uploadImage5').length >0)
                      // {
                      //   $('.save_continue_btn_span').html('Get your Results');
                      // }
                      if (thisobject.count_value == 0)
                        // $('#circles > div').removeClass('disabled');
                        $(".continue_btn")
                          .html("Continue")
                          .attr("disabled", false);
                    },
                    function (rejectOutput) { }
                  );
                  thisobject.uploadImageInputForm.patchValue({
                    file: result.dataURL,
                  });
                  thisobject._cd.markForCheck();
                }
              });
          });
      });
    }
  }

isUploadWhiteningImage=false;
isWebCamImage=false;
  onClickUploadTeethImage(type){
    if(type == 'browse') {
      this.isUploadWhiteningImage = true;
    }
     if(type == 'camera') {
      this.isWebCamImage = true;
     }
  }

  selectWebCameraImage(value){
    console.log(value,"selectWebCameraImage")
      if (value) {
      this.folder = "oralhealthscore_images/";
      // var fileExt = value.fileUrl.split(".").pop();
      var fileExt = 'png'
      let blob = this.base64ToBlob(value, 'application/octet-stream');
        let file = new File([blob], 'webcam_image.png', { type: fileExt });
        let fileUrl = 'webcam_image.png';
      console.log(file,fileUrl,fileExt)
        let greetingPromise = this.appservices.uploadToS3(file, fileUrl, this.folder, fileExt);
          let thisobject = this;
          greetingPromise.then((resolveOutput) => {
            console.log(resolveOutput)
            if (this.isWebCamImage == true) {
                this.whiteninguploadedImage1 = resolveOutput["Location"];
              $(".save_continue_btn_span").html("Get your Results");
                $(".upload-teeth-continue-btn").removeAttr("disabled");
                this._cd.markForCheck();
                localStorage.setItem("teethWhiteningUploadedImage", JSON.stringify(this.whiteninguploadedImage1));
                //testing
                this.onSubmitTeethWhitening();
            }
          }, function (rejectOutput) {
          });
      }
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
