import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Services } from '../helpers/Services';
import { Options } from '@angular-slider/ngx-slider';
import * as $ from "jquery";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-teeth-whitening-result',
  templateUrl: './teeth-whitening-result.component.html',
  styleUrls: ['./teeth-whitening-result.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeethWhiteningResultComponent implements OnInit,OnDestroy {
  report_id: any = "";
  practice_id: any = "";
  user_id: any = "";
  // report_type: any = " ";
  // payment_amount: any = "";
  apirequest: any = [];
  widget_flow_type: any = "";
  isProductDiscountCard: boolean = false;
  isRequestForAppointmentCard: boolean = false;
  isPaymentSuccessful: boolean = false;
  appServices: Services;
  modalRef: BsModalRef;
  session_id: any = "";
  get_contact_information_later: any = "";
  guest_user_id: any = "";
  // showLoadingForm: boolean = true;
  isDataLoaded: boolean = false;
  analyzingText: any = "Please wait";
  successText: string = "Successfully Done";
  imageSelectionCount: any = "5";
  private _subscriptions = new Subscription();
  teethWhiteResultImage;
  uploadedteethImage;
  flag: boolean = true;
  whiteningBrightnessValue: any = 0;
  brightnessOptions: Options = {
    floor: 0,
    ceil: 100,
    showTicksValues: true,
    stepsArray: [
      { value: 10, legend: "Week 1" },
      { value: 20 },
      { value: 30, legend: "Week 2" },
      { value: 40 },
      { value: 50, legend: "Week 3" },
      { value: 60 },
      { value: 70, legend: "Week 4" },
      { value: 80 },
      { value: 90, legend: "Week 5" }
    ]
  };
  teethWhiteningFinalResultImg;
  isResultButtonDisabled = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private _cd: ChangeDetectorRef,
  ) {
    this.appServices = new Services();
    if (this.appServices.getSession("teethWhitening", false)) {    //Teeth whitening Type 1
      let uploadedteethImage = JSON.parse(localStorage.getItem("teethWhiteningUploadedImage"));
      if (uploadedteethImage) {
        this.uploadedteethImage = uploadedteethImage['Location'];
      }
      let teethWhiteResult = JSON.parse(localStorage.getItem("teethWhiteningResultImage"));
      if (teethWhiteResult) {
        this.teethWhiteResultImage = teethWhiteResult['Location'];
      }
      this.flag = false;
    }
    if (this.appServices.getSession("teethWhiteningType2", false)) {
      let Type2ApiResult: any = this.appServices.getSession("teethWhitheningApiResult", true);
      let Type2ApiFinalResult: any = this.appServices.getSession("teethWhitheningApiFinalResult", true);
      console.log(Type2ApiResult);
      if (Type2ApiResult) {
        this.uploadedteethImage = Type2ApiResult?.type2ImageUploaded;
        this.teethWhiteResultImage = Type2ApiResult?.type2ImageResult;
        this.flag = false;
      }
      this.teethWhiteningFinalResultImg = Type2ApiFinalResult?.type2ImageFinalResult;
      this.whiteningBrightnessValue = Type2ApiFinalResult?.type2FinalWhitenessValue;
    }
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appServices.getSession(
      "images_selection_count",
      false
    );
    this.widget_flow_type = this.appServices.getSession(
      "widget_flow_type",
      false
    );
    this.practice_id = this.appServices.getSession("practice_id", false);
    this.get_contact_information_later = this.appServices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_user_id = this.appServices.getSession("guest_user_id", false);
    this.isRequestForAppointmentCard =
      this.appServices.getSession("is_request_for_appointment", false) ===
      "true";
    this.isProductDiscountCard =
      this.appServices.getSession("is_request_for_coupan_code", false) ===
      "true";
    this.isPaymentSuccessful =
      this.appServices.getSession("payment_successful", false) === "true";
    // if (
    //   this.appServices.isEmpty(this.widget_flow_type) ||
    //   this.appServices.isEmpty(this.report_id)
    // ) {
    //   this.router.navigate([
    //     "get-started/" + this.appServices.getSession("token", false),
    //   ]);
    // }
    this.route.queryParams.subscribe((params) => {
      this.session_id = params.payment_transaction_id;
      if (!this.appServices.isEmpty(this.session_id)) {
        this.appServices.setSession("session_id", this.session_id, false);
      }
    });
    this.isResultButtonDisabled = false;
    this._cd.markForCheck();
  }

  getFinalWhitenessResult() {
    // this.teethWhiteningFinalResultImg = '';
    this.isResultButtonDisabled = true;
    $(".upload-teeth-continue-btn").attr("disabled", true);
    $(".save_continue_btn_span").html("Please wait...").attr("disabled", true);
    this._cd.markForCheck();
    if (this.uploadedteethImage) {
      this.apirequest['image_urls'] = this.uploadedteethImage;
      const requestFormData = new FormData();
      requestFormData.append('image_urls', this.uploadedteethImage);
      requestFormData.append('whiteness', this.whiteningBrightnessValue);

      const headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json',
      });
      this.httpClient.post("https://ml-api.qs1.smilo.ai/smile_design/teeth_whitening_v2", requestFormData, { headers }).subscribe((res: any) => {
        if (res.status && res.status_code == 200) {
          let responseObj = {
            type2FinalWhitenessValue: this.whiteningBrightnessValue,
            type2ImageFinalResult: res.result['output']
          };
          this.teethWhiteningFinalResultImg = res.result['output'];
          this.appServices.setSession("teethWhitheningApiFinalResult", responseObj, true);
          $(".continue_btn").html("Please wait...").attr("disabled", false);
          $(".upload-teeth-continue-btn").removeAttr("disabled");
          this._cd.markForCheck();
          $(".save_continue_btn_span").html("Get your Results");
          this.isResultButtonDisabled = false;
        } else {
          $(".continue_btn").html("Please wait...").attr("disabled", false);
          $(".save_continue_btn_span").html("Get your Results");
          this.isResultButtonDisabled = false;
          this.router.navigate(["no-internet"]);
        }
      },
        (error) => {
          this.isResultButtonDisabled = false;
          $(".continue_btn")
            .html("Please wait...")
            .attr("disabled", false);
          $(".save_continue_btn_span").html("Get your Results");
          console.error('Error:', error);
        });
    }
  }

  onBack() {
    this.router.navigate(["./payment-access-report"]);
  }

  // onBookNow() {
  //   this.appServices.sendAnalyticsData(
  //     '',
  //     '',
  //     "smilo_event",
  //     "sa_wg_payment_success_dental_appointment_book_open",
  //     true
  //   );
  // }

  onProductDiscountClick() {
    this.appServices.sendAnalyticsData(
      '',
      '',
      "smilo_event",
      "sa_wg_payment_success_product_discount_open",
      true
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    localStorage.removeItem("teethWhitheningApiFinalResult");
  }
}
