import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Services } from "../../app/helpers/Services";
declare var gtag;
declare const fbq = "";
@Component({
  selector: "app-getting-start-page-widget",
  templateUrl: "./getting-start-page-widget.component.html",
  styleUrls: ["./getting-start-page-widget.component.css"],
})
export class GettingStartPageWidgetComponent implements OnInit {
  appServices: Services;
  ENVIRONMENT = environment;
  imageSelectionCount: any = "5";
  isDaburFlow: boolean = false;
  constructor(private router: Router) {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appServices.getSession(
      "images_selection_count",
      false
    );
    this.isDaburFlow =
      this.appServices.getSession("isDaburFlow", false) === "true";
    this.appServices.sendPageViewAnalyticsData(
      "",
      "",
      this.router.url,
      "OHR – Getting widget start page"
    );
  }
  onGettingStart() {
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_getting_start_now_clicked",
      true
    );
    this.router.navigate(["purpose-of-visit"]);
  }
  onBack() {
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_getting_start_now_back_to_landing_page_clicked",
      true
    );
    if (this.isDaburFlow) {
      window.location.href = environment.LANDING_PAGE_DABUR;
    } else if (this.imageSelectionCount == 1) {
      window.location.href = environment.LANDING_PAGE_SINGLE_IMAGE;
    } else {
      window.location.href = environment.LANDING_PAGE;
    }
  }

  onClickHere() {
    window.open(
      this.isDaburFlow
        ? environment.DABUR_DISCLAIMER_PAGE
        : environment.DISCLAIMER_PAGE,
      "_blank"
    );
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_disclaimer_clicked",
      true
    );
    return false;
  }

  goToLink(url: string, option: number) {
    window.open(url, "_blank");
  }
}
