import { Component, OnInit, HostListener } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Services } from "../../../../app/helpers/Services";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HelperServiceService } from "../../../../app/helpers/services/helper-service.service";

declare var gtag;
declare const fbq ='';

@Component({
  selector: "app-view-processed-report",
  templateUrl: "./view-processed-report.component.html",
  styleUrls: ["./view-processed-report.component.css"],
})
export class ViewProcessedReportComponent implements OnInit {
  sliderData: any[] = [];
  process_images: any;
  appServices: Services;
  slideConfig = {
    swipe: false,
    swipeToSlide: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow:
      "<div onclick=\"Window?.myComponent?.checkingImageExist('right',this)\" class='sh-chevron-right-outline slick-next'></div>",
    prevArrow:
      "<div onclick=\"Window?.myComponent?.checkingImageExist('left',this)\" class='sh-chevron-left-outline slick-prev'></div>",
    infinite: false,
  };
  updatedData: any[] = [];
  imageSelectionCount: any = "5";
  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private helperServiceService: HelperServiceService
  ) {
    this.appServices = new Services();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(e: KeyboardEvent) {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appServices.getSession(
      "images_selection_count",
      false
    );
    this.sliderData = [
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "1 of",
        title: "Front View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by biting down on your back teeth and smile wide with your lips apart.",
      },
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "2 of",
        title: "Right View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by turning your phone to the right side and bite evenly on your back teeth with your lips apart.",
      },
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "3 of",
        title: "Left View",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by turning your phone to the left side and bite evenly on your back teeth with your lips apart.",
      },
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "4 of",
        title: "Upper Jaw",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by opening your mouth wide, lips away and turning your phone camera upside down to capture upper teeth.",
      },
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "5 of",
        title: "Lower Jaw",
        base64: "",
        file_name: "",
        content:
          "Take a selfie by opening your mouth wide, lips away and bringing your phone up to capture your lower teeth.",
      },
      {
        url: "assets/images/teeth_1.png",
        pageNumber: "6 of",
        title: "Additional images",
        base64: "",
        file_name: "",
        content: "Add any other images you like.",
      },
    ];
    this.process_images = JSON.parse(
      JSON.stringify(this.appServices.getSession("process_images", true))
    );
    console.log(
      "🚀 ~ file: view-processed-report.component.ts:102 ~ ViewProcessedReportComponent ~ ngOnInit ~ this.process_images:",
      this.process_images
    );
    this.updateSliderData();
  }

  updateSliderData() {
    if (this.process_images?.length) {
      const itemCount = this.process_images.length;
      this.sliderData = this.sliderData.slice(0, itemCount);

      this.sliderData.forEach((item: any, index) => {
        if (index < itemCount) {
          item.url = this.process_images[index].output_image;
        }
      });
      console.log(
        "🚀 ~ file: view-processed-report.component.ts:119 ~ ViewProcessedReportComponent ~ this.sliderData.forEach ~ this.sliderData:",
        this.sliderData
      );
    }
  }

  slickInit(e) {}

  closeModal() {
    this.bsModalRef.hide();
  }

  onStartAgain() {
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_success_view_report_popup_start_again_clicked",
      true
    );
    this.clearUserSession();
    this.closeModal();
    setTimeout(() => {
      this.router.navigate(["./purpose-of-visit"]);
    }, 1000);
  }

  onContactUs() {
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_success_view_report_popup_contact_us_whatsapp_clicked",
      true
    );
    window.open(environment.CLIENT_WHATSAPP_CONTACT, "_blank");
  }

  clearUserSession() {
    this.appServices.setSession("is_logged_in", "false", false);
    this.appServices.setSession("isUserLoggedIn", "false", false);
    this.appServices.setSession("first_name", "", false);
    this.appServices.setSession("last_name", "", false);
    this.appServices.setSession("email", "", false);
    this.appServices.setSession("user_id", "", false);
    this.appServices.setSession("phoneNumber", "", false);
    this.appServices.setSession("dial_code", "", false);
    this.appServices.setSession("profile_picture", "", false);
    this.appServices.setSession("city", "", false);
    this.appServices.setSession("state_id", "", false);
    this.appServices.setSession("country_id", "", false);
    this.appServices.setSession("country_name", "", false);
    this.appServices.setSession("ohr_unique_key", "", false);
    this.appServices.setSession("overall_score", "", false);
    this.appServices.setSession("payment_successful", "false", false);
    localStorage.setItem("Input_form_data", "");
  }
}
