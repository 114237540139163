<div>
    <div class="input-group d-flex flex-column">
        <div id="filterdropdown">
            <span class="dropdown-name form-text sub_head">Date Of Birth</span>
        </div>
        <div class="d-flex  border-outline date-container bg-white p-3 rounded justify-content-between"
            style="gap:7px;">
            <input type="text" #dp="bsDatepicker" placeholder="Enter Date of Birth"
                (bsValueChange)="onDateSelection($event)" bsDatepicker [(bsValue)]="myDateValue" [maxDate]="maxDate">
            <button class="calender" (click)="dp.toggle()" type="button">
            </button>
        </div>
    </div>
</div>