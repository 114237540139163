import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bs-datepicker',
  templateUrl: './bs-datepicker.component.html',
  styleUrls: ['./bs-datepicker.component.css']
})
export class BsDatepickerComponent implements OnInit {

  constructor() { }
@Input() selectDate:any
  ngOnInit(): void {
  }
}
