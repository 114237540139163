<div class="oral-health_wrapper position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container">
          <div class="splash-content_wrapper d-flex align-items-center justify-content-center">
            <div class="splash-image">
              <img src="assets/images/splash-image.png" class="img-fluid" alt="" />
            </div>
            <div class="splash-content">
              <span class="d-block mb-1">POWERED BY AI, DESIGNED BY DENTISTS</span>
              <h1 class="mb-2">
                Start Your Oral<br />
                Health Check Now
              </h1>
              <p>
                Assess your oral health with our AI-powered checkup. Take a few
                selfies to get personalized insights.
              </p>
              <button (click)="onGettingStart()" class="btn btn-primary btn-rounded-corner mb-3">
                Start Now
              </button>
              <div class="note mb-2">
                Your pictures are processed securely and confidentially.
              </div>
              <div class="note mb-2">
                We value your privacy and protect your data.
                <i>
                  <b>
                    <a [href]="
                        isDaburFlow
                          ? ENVIRONMENT.DABUR_DISCLAIMER_PAGE
                          : ENVIRONMENT.DISCLAIMER_PAGE
                      " target="_blank" class="text-light mt-2" (click)="onClickHere()">Click here for full
                      disclaimer.</a></b></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- new changes footer start -->
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <footer class="footer-class">
          <ul>
            <li><a href="javascript:void(0)" (click)="onBack()">Home</a></li>
            <li>
              <a href="javascript:void(0)" (click)="goToLink(ENVIRONMENT.GET_IN_TOUCH, 4)">Get In Touch</a>
            </li>
            <li *ngIf="!isDaburFlow">
              <a href="javascript:void(0)" (click)="
                  goToLink(
                    isDaburFlow
                      ? ENVIRONMENT.DABUR_PRIVACY
                      : ENVIRONMENT.PRIVACY,
                    3
                  )
                ">Privacy Policy</a>
            </li>
            <li *ngIf="!isDaburFlow">
              <a href="javascript:void(0)" (click)="
                  goToLink(
                    isDaburFlow ? ENVIRONMENT.DABUR_TERMS : ENVIRONMENT.TERMS,
                    2
                  )
                ">Terms and Conditions</a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="
                  goToLink(
                    isDaburFlow
                      ? ENVIRONMENT.DABUR_CONSENT_FORM
                      : ENVIRONMENT.CONSENT_FORM,
                    1
                  )
                ">Consent Form</a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  </div>
</div>
<!-- new changes footer end -->