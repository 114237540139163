import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export interface filterDropdown {
  isLoading: boolean;
  values: Array<any>;
}

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent implements OnInit {
  @Input() labelName: string;
  @Input() DropdownValues$: Observable<filterDropdown>;
  @Input() selectedValue: any;
  @Input() selecteById: any;
  @Input() placeholder: string = 'All';
  @Output() onChangeValue = new EventEmitter();
  searchStream$: Subject<string> = new Subject();

  constructor() {
  }

  ngOnInit(): void {
    this.DropdownValues$ = combineLatest([
      this.searchStream$.pipe(
        debounceTime(100),
        startWith(''),
        map(searchKey => searchKey.toLowerCase()) // Convert searchKey to lowercase for case-insensitive comparison
      ),
      this.DropdownValues$ // Assuming this is an existing Observable<DropdownValues>
    ]).pipe(
      map(([searchKey, dropdownvalues]) => {
        const filteredValues = (dropdownvalues?.values || []).filter(value =>
          value.name.toLowerCase().includes(searchKey)
        );
        const selectedValue = dropdownvalues?.values.find(value => value.id === this.selecteById) || null;
        return {
          isLoading: dropdownvalues?.isLoading,
          values: filteredValues,
          selectedValue: selectedValue
        };
      })
    );
  }

  filterDropdown() {
    this.DropdownValues$ = combineLatest([
      this.searchStream$.pipe(
        debounceTime(100),
        startWith(''),
        map(searchKey => searchKey.toLowerCase()) // Convert searchKey to lowercase for case-insensitive comparison
      ),
      this.DropdownValues$ // Assuming this is an existing Observable<DropdownValues>
    ]).pipe(
      map(([searchKey, dropdownvalues]) => {
        const filteredValues = (dropdownvalues?.values || []).filter(value =>
          value.name.toLowerCase().includes(searchKey)
        );
        const selectedValue = dropdownvalues?.values.find(value => value.id === this.selecteById) || null;
        return {
          isLoading: dropdownvalues?.isLoading,
          values: filteredValues,
          selectedValue: selectedValue
        };
      })
    );
  }

  setSelected(data: any) {
    this.selectedValue = data;
    this.searchStream$.next('');
    this.onChangeValue.emit(this.selectedValue);
  }
}
