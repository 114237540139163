<div class="dynamic_wrapper home_page">
    <div class="dynamic_wrapper_custom">
        <div class="row flex_direction home-page-main-div mx-0 ">
            <div class="col-lg-6 col-md-12 text-center col_2 ">
                <div class="home_head py-5 padding">
                    <div [ngSwitch]="widget_button_type">
                        <p class="home-banner-title-text">Get an instant dental check in just a few clicks</p>
                        <ng-container *ngSwitchCase="0">
                            <div class="top welcome_box mb-3" (click)="onStart(false)">                             
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative">{{button_text_details[0].button_text}}
                                    </h4>
                                    <button
                                        class="btn button_links virtual_consult_btn btn_width_2 mx-0 d-flex align-items-center"><span>Start
                                            now</span><span class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <div class="top welcome_box  mb-3" (click)="onStart(true)">                             
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative mr-3">
                                        {{button_text_details[1].button_text}}</h4>
                                    <button
                                        class="btn button_links virtual_consult_btn uploadImgButton btn_width_2 mx-0 d-flex align-items-center"><span>Start
                                            now</span><span class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>

                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <div class="top welcome_box mb-3" (click)="onStart(false)">                            
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative">{{button_text_details[0].button_text}}
                                    </h4>
                                    <button
                                        class="btn button_links virtual_consult_btn btn_width_2 mx-0 d-flex align-items-center"><span>Start
                                            now</span><span class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>
                            </div>

                            <div class="top welcome_box" (click)="onStart(true)">                              
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative mr-3">
                                        {{button_text_details[1].button_text}}</h4>
                                    <button
                                        class="btn button_links virtual_consult_btn uploadImgButton btn_width_2 mx-0 d-flex align-items-center"><span>Start
                                            now</span><span class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>
                            </div>              
                        </ng-container>


                        <div class="mt-3 display_uses font_18">
                            <div class="text-left" *ngIf="widget_benefit_one!=''"> <b>Benefits</b> </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_one!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_one}}</div>
                            </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_two!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_two}}</div>
                            </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_three!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_three}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0 ">
                <div class="home_img med-ques-parent-div med-ques">
                    <img class="med-ques-img" [defaultImage]="defaultImage" [lazyLoad]="staticImage"
                        [errorImage]="defaultImage" alt="Home">
                </div>
            </div>
        </div>
    </div>
</div>