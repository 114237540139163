<div class="dynamic_wrapper position-relative text-center medical_questions">
    <div>
        <div class="loading_div analysing_sec sub_wrapper p-3" *ngIf="!flag">
            <div class="img_section text-center position-relative">
                <div class="loader_img_sec mt-4">
                    <img src="assets/images/loader.gif" />
                </div>
                <div class="analyzing_text mb-4">
                    <p class="thank_you_head">Please Wait</p>
                </div>
            </div>
        </div>
        <div *ngIf="flag" class="dynamic_wrapper_custom">
            <div class="vs-messages"></div>
            <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form"
                onkeydown="return event.key != 'Enter';">
                <div *ngFor="let data of responsedata let i = index" id=div_{{i}}>
                    <div class="title_sec" *ngIf="i!=4">
                        <div class="">

                        </div>

                    </div>
                    <div class="row mx-0 main_div" *ngIf="i==0">
                        <!-- title_div -->
                        <h5 class="Nunito-Bold font_12 select_opt w-100 text-center">SELECT AN OPTION</h5>
                        <h3 class="sub_head mb-3 text-center w-100">What's the reason for your visit?</h3>

                        <div class="col-md-4 medical-questions-options-div p-0 pointer_link"
                            [ngClass]="medicalQuestionSelectedOption === 'radio3' ? 'medical-questions-selected-option' : ''"
                            (click)="onCheckboxChange($event,'radio3')">
                            <div class="bg_div">
                                <div class="checkbox">
                                    <img src="assets/images/untick.png" alt="untick" class="untick">
                                    <img src="assets/images/tick.png" alt="tick" class="tick">
                                </div>
                                <div class="bg_div_responsive">
                                    <div class="select_image">
                                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage3"
                                            [errorImage]="defaultImage" alt="General Checkup">
                                    </div>

                                    <label class="form-check-label input_name post_label pointer_link mt-1"
                                        for="radio3">
                                        <input type="radio" class="form-check-input" id="radio3" name="optradio">
                                        <p class="med_head Nunito-Bold">General Checkup</p>
                                        <span class="med_span">General Checkup</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 medical-questions-options-div p-0 pointer_link"
                            [ngClass]="medicalQuestionSelectedOption === 'radio1' ? 'medical-questions-selected-option' : ''"
                            (click)="onCheckboxChange($event,'radio1')">
                            <div class="bg_div">
                                <div class="checkbox">
                                    <img src="assets/images/untick.png" alt="untick" class="untick">
                                    <img src="assets/images/tick.png" alt="tick" class="tick">
                                </div>
                                <div class="bg_div_responsive">
                                    <div class="select_image">
                                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage1"
                                            [errorImage]="defaultImage" alt="Toothache">
                                    </div>

                                    <label class="form-check-label input_name post_label pointer_link mt-1"
                                        for="radio1">
                                        <input type="radio" class="form-check-input" id="radio1" name="optradio">
                                        <p class="med_head Nunito-Bold">Toothache</p>
                                        <span class="med_span">Aching, Chipped or <br>Shattered teeth</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 medical-questions-options-div p-0 pointer_link"
                            [ngClass]="medicalQuestionSelectedOption === 'radio2' ? 'medical-questions-selected-option' : ''"
                            (click)="onCheckboxChange($event,'radio2')">
                            <div class="bg_div">
                                <div class="checkbox">
                                    <img src="assets/images/untick.png" alt="untick" class="untick">
                                    <img src="assets/images/tick.png" alt="tick" class="tick">
                                </div>
                                <div class="bg_div_responsive">
                                    <div class="select_image">
                                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage2"
                                            [errorImage]="defaultImage" alt="Cosmetic treatment">
                                    </div>

                                    <label class="form-check-label input_name post_label pointer_link mt-1"
                                        for="radio2">
                                        <input type="radio" class="form-check-input" id="radio2" name="optradio">
                                        <p class="med_head Nunito-Bold">Cosmetic treatment</p>
                                        <span class="med_span">Whitening, Veeners,<br> Aligners</span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="fixed_div w-100">
                            <button class="btn button_links btn_width_2 continue_btn mt-4"
                                [disabled]="continueBtnDisabled" (click)="onContinue()">Continue</button>
                        </div>
                    </div>
                </div>
            </form>
            <app-footer></app-footer>
        </div>
    </div>
</div>

<div class="dynamic_wrapper home_page">
    <div class="dynamic_wrapper_custom">
        <div class="row flex_direction home-page-main-div mx-0 ">
            <div class="col-lg-6 col-md-12 text-center col_2 ">
                <div class="home_head py-5 padding">

                    <div [ngSwitch]="widget_button_type">
                        <p class="home-banner-title-text">Get instant dental check up</p>
                        <p class="home-banner-sub-title-text  mb-3">with a click of your smile</p>

                        <ng-container *ngSwitchCase="0">
                            <div class="top welcome_box mb-3">

                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative">{{button_text_details[0].button_text}}
                                    </h4>
                                    <button
                                        class="btn button_links virtual_consult_btn btn_width_2 mx-0 d-flex align-items-center"
                                        (click)="onStart(false)"><span>Start now</span><span
                                            class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <div class="top welcome_box  mb-3">

                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative mr-3">
                                        {{button_text_details[1].button_text}}</h4>
                                    <button
                                        class="btn button_links virtual_consult_btn uploadImgButton btn_width_2 mx-0 d-flex align-items-center"
                                        (click)="onStart(true)"><span>Start now</span><span
                                            class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>

                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <div class="top welcome_box mb-3">

                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative">{{button_text_details[0].button_text}}
                                    </h4>
                                    <button
                                        class="btn button_links virtual_consult_btn btn_width_2 mx-0 d-flex align-items-center"
                                        (click)="onStart(false)"><span>Start now</span><span
                                            class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>
                            </div>

                            <div class="top welcome_box">

                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-0 custom_h3 position-relative mr-3">
                                        {{button_text_details[1].button_text}}</h4>
                                    <button
                                        class="btn button_links virtual_consult_btn uploadImgButton btn_width_2 mx-0 d-flex align-items-center"
                                        (click)="onStart(true)"><span>Start now</span><span
                                            class="ml-3 icon-right-arrow font_14"></span></button>
                                </div>

                            </div>


                        </ng-container>


                        <div class="mt-3 display_uses font_18">
                            <div class="text-left" *ngIf="widget_benefit_one!=''"> <b>Benefits</b> </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_one!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_one}}</div>
                            </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_two!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_two}}</div>
                            </div>
                            <div class="d-flex align-items-center mb-1 mt-2" *ngIf="widget_benefit_three!=''">
                                <div><img src="assets/images/green_tick.png" class="display_uses_img"></div>
                                <div class="ml-2 text-left word_breakall">{{widget_benefit_three}}</div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0 ">
                <div class="home_img med-ques-parent-div med-ques">
                    <img class="med-ques-img" [defaultImage]="defaultImage" [lazyLoad]="staticImage"
                        [errorImage]="defaultImage" alt="Home">
                </div>
            </div>

        </div>
    </div>
</div>