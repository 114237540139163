<div>
  <div class="next-card-header">
    <div
      class="next-card-top d-flex align-items-center justify-content-between"
    >
      <div class="card-content">
        <h4>
          Buy Your
          {{ imageSelectionCount == 1 ? "Smile Check" : "Oral Health" }} Report
        </h4>
        <!-- <h4>Buy An Oral Health Report</h4> -->
        <p>Gain personalised insights and recommendations instantly.</p>
        <!-- <p>Gain insights into your dental health by purchasing a report.</p> -->
        <button
          class="btn btn-primary btn-rounded-corner btn-icon-right btn-xs mt-2"
          (click)="onGetNow()"
        >
          Buy Now <i class="icon_arrow icon-right-arrow"></i>
        </button>
      </div>
      <div class="card-image">
        <img
          src="assets/images/health-report.png"
          class="img-fluid d-none d-sm-block"
          alt=""
        />
        <img
          src="assets/images/health-report-mobile.png"
          class="img-fluid d-block d-sm-none"
          alt=""
        />
      </div>
    </div>
    <div class="next-card-bottom">
      <a
        href="javascript:void(0)"
        (click)="isExpand = !isExpand"
        class="d-flex align-items-center justify-content-center"
        ><b class="mr-1">Learn More</b>About Buying Your Report<i
          class="ml-2"
          [ngClass]="!isExpand ? 'sh-angle-down' : 'sh-angle-up'"
        ></i
      ></a>
    </div>
  </div>
  <div class="next-card-body" *ngIf="isExpand">
    <h4>All Benefits</h4>
    <div class="card-detail-list d-flex flex-column">
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Personalized insights</h5>
          <p>
            Gain valuable insights about your oral health that can help you make
            informed decisions
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-lightbulb"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Instant Reports</h5>
          <p>
            Access and review your comprehensive
            {{
              imageSelectionCount == 1 ? "smile check" : "oral health"
            }}
            reports instantly, anytime, anywhere.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-file-text"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Monitor Your Oral Health</h5>
          <p>
            Access and compare previous reports to monitor changes, and make
            informed choices.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-tooth"></i>
        </div>
      </div>
    </div>
    <button
      class="btn btn-primary btn-rounded-corner btn-icon-right buy-now-btn btn-lg mx-auto mt-3"
      (click)="onGetNow()"
    >
      <span class="btn-text d-flex flex-column align-items-start"
        >Buy Now
        <span class="d-flex align-items-end ruppe-info"
          ><b>₹{{ paymentAmount }}</b> / Report</span
        ></span
      >
      <i class="icon_arrow icon-right-arrow"></i>
    </button>
  </div>
</div>
