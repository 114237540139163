<div class="loader-section" *ngIf="flag">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle fill="none" stroke="#f5821e" stroke-width="18" cx="100" cy="100" r="90" class="circle"
              stroke-linecap="round" transform="rotate(-90 100 100) " />
          </svg>
        </div>
        <div class="loading-arrow">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline fill="none" stroke="#ffffff" stroke-width="50" points="88,214 173,284 304,138"
              stroke-linecap="round" stroke-linejoin="round" class="tick" />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center" [ngClass]="{ 'text-green': isDataLoaded === true }">
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="section_wrapper pt-0 position-relative" *ngIf="!flag">
  <div class="section-banner_wrapper dentist-appointment position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
                        <i class="sh-chevron-left"></i> <span>back</span>
                    </a> -->
          <div class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center">
            <div class="banner-content">
              <h1>
                What's Next
              </h1>
            </div>
            <div class="banner-image">
              <img src="assets/images/health-report-banner.png" class="img-fluid d-none d-lg-block" alt="" />
              <img src="assets/images/health-report-mobile.png" class="img-fluid d-block d-lg-none" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="wrapper-container">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">Your Before & After photos are here.</h3>
              <p>Discover our services to improve your Oral Health</p>
            </div>
            <div class="next_wrapper">
              <div class="row">
                <div class="col-md-6">
                  <img [src]="uploadedteethImage" class="img-fluid" alt="" style="height: 300px;border-radius: 5px;" />
                  <h3 class="mb-1 text-center mt-1" style="font-weight:600"> Before</h3>
                </div>
                <div class="col-md-6">
                  <img [src]="teethWhiteResultImage" class="img-fluid" alt=""
                    style="height: 300px;border-radius: 5px;" />
                  <h3 class="mb-1 text-center mt-1" style="font-weight:600">After</h3>
                </div>
                <div class="col-md-6" style="margin-inline: auto;" *ngIf="teethWhiteningFinalResultImg">
                  <img [src]="teethWhiteningFinalResultImg" class="img-fluid" alt=""
                    style="height: 300px;border-radius: 5px;" />
                  <h3 class="mb-1 text-center mt-1" style="font-weight:600"> Final Result</h3>
                </div>
              </div>
            </div>
            <div class="whiteness-monitor-section">
              <h3 style="font-weight:600">Whiteness Monitor</h3>
              <div class="row d-flex align-items-center justify-content-center mt-2 mb-2">
                <div class="col-md-8 col-sm-12 col-12 row d-flex align-items-center whiteness-col">
                  <div class="col-md-3 col-3 mt-3 px-0 whitening-slide-label">Whiteness</div>
                  <div class="col-md-9 col-9">
                    <ngx-slider [(value)]="whiteningBrightnessValue" [options]="brightnessOptions"></ngx-slider>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 col-12 text-right pr-0 result-col">
                  <div class="save_button_div">
                    <button [disabled]="isResultButtonDisabled" (click)="getFinalWhitenessResult()"
                      class="upload-teeth-continue-btn btn btn-primary coninue_btn btn-rounded-corner">
                      <span class="save_continue_btn_span">Get your Results</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <b>Note:</b>
              While we make every effort to give you the best results, It may not always work for you due to different
              reasons. We
              recommend you seek professional opinion before starting any teeth whitening treatment.
              <!-- <br /><span style="color: #005DFF;font-weight: 600;">Read
                More</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="wrapper-container">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">What's Next?</h3>
              <p>Are you looking for a whiter, brighter and healthier smile?</p>
            </div>
            <div class="next_wrapper">
              <app-appointment-card *ngIf="true" class="next-card"
                [isTeethWhiteningResult]="true"></app-appointment-card>
              <app-product-discount-card *ngIf="false" class="next-card"
                (productDiscountClick)="onProductDiscountClick()"></app-product-discount-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>