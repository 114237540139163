import {
  Component,
  OnInit,
  TemplateRef,
  HostListener,
} from "@angular/core";
// import { Router, ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";
import * as $ from "jquery";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DomainService } from "src/core/service/domain/domain.service";
declare var gtag;
declare const fbq;

@Component({
  selector: "app-process-ohr-images",
  templateUrl: "./process-ohr-images.component.html",
  styleUrls: ["./process-ohr-images.component.css"],
})

export class ProcessOhrImagesComponent implements OnInit {
  modalRef: BsModalRef;
  defaultImage = "assets/images/tooth_loader.gif";
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  responsedata: any;
  input: any;
  process_images: any;
  first_name: any;
  created_date: any;
  is_images_invalid: any;
  flag: boolean = false;
  dentalInfo: any;
  gumlInfo: any;
  itemsList: any;
  label: any;
  fb_pixel_id: any = "";
  gtm_id: any = "";
  widget_flow_type: any = "";
  get_contact_information_later: any = "";
  report_id: any = "";
  guest_user_id: any = "";
  isDomainAus;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private domainService: DomainService
  ) {
    this.isDomainAus = this.domainService.isAusRegionDomain("");
    this.appservices = new Services();
  }
  
  @HostListener("window:popstate", ["$event"]) onPopState(e: KeyboardEvent) {
    // this.modalRef.hide()
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      "<div class='nav-btn icon-right-arrow next-slide blue_color font_22'></div>",
    prevArrow:
      "<div class='nav-btn icon-left-arrow prev-slide blue_color font_22'></div>",
    infinite: false,
  };

  slickInit(e) {}

  ngOnInit(): void {
    this.appservices.setSession("bannerTitleText", "Oral Health Rating", false);
    this.fb_pixel_id = this.appservices.getSession("fb_pixel_id", false);
    this.gtm_id = this.appservices.getSession("gtm_id", false);
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    this.dentalInfo = [
      { url: "assets/images/decay1.png", title: "Healthy Teeth" },
      { url: "assets/images/decay2.png", title: "Mild Decay" },
      { url: "assets/images/decay3.png", title: "Moderate Decay" },
      { url: "assets/images/decay4.png", title: "Severe Decay" },
    ];
    this.gumlInfo = [
      { url: "assets/images/decay1.png", title: "Healthy Teeth" },
      { url: "assets/images/gum2.png", title: "Mild Gum" },
      { url: "assets/images/gum3.png", title: "Moderate Gum" },
      { url: "assets/images/gum4.png", title: "Severe Gum" },
    ];
    this.first_name = this.appservices.getSession("first_name", false);
    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    if (this.first_name == "" && this.get_contact_information_later == "yes")
      this.first_name = "Guest User";

    this.created_date = this.appservices.getSession("created_date", false);
    this.is_images_invalid = this.appservices.getSession(
      "is_all_images_invalid",
      false
    );
    this.process_images = JSON.parse(
      JSON.stringify(this.appservices.getSession("process_images", true))
    );
  }

  getTitle(title, output_image) {
    if (output_image) {
      return title;
    } else {
      var output = "Additional Image - Oral Prediction";
      return output;
    }
  }

  onSubmit(is_images_invalid) {
    if (this.is_images_invalid == "true") {
      if (this.isDomainAus) {
        this.router.navigate(["upload-teeth-images"]);
      } else {
        this.router.navigate(["upload-teeth-photos"]);
      }
    } else {
      this.widget_flow_type = this.appservices.getSession(
        "widget_flow_type",
        false
      );
      if (this.widget_flow_type === "payment") {
        if (this.isDomainAus) {
          this.router.navigate(["upload-teeth-images"]);
        } else {
          this.router.navigate(["upload-teeth-photos"]);
        }
      } else {
        this.appservices.sendAnalyticsData(
          gtag, fbq,
          "smilo_event",
          "sa_wg_ohr_process",
          false
        );
        this.appservices.sendAnalyticsData(
        gtag, fbq,
          "smilo_event",
          "sa_wg_ohr_process",
          true
        );
        this.router.navigate(["oral-hygiene"]);
      }
    }
  }

  openViewModal(template: TemplateRef<any>, id) {
    this.itemsList = id == "1" ? this.dentalInfo : this.gumlInfo;
    this.label = id == "1" ? "dental_decay" : "gum";
    this.modalRef = this.modalService.show(template, {
      class: "custom_notifications_modal vertical_align_center",
      animated: true,
      backdrop: "static",
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
}
