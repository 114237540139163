import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-product-discount-card",
  templateUrl: "./product-discount-card.component.html",
  styleUrls: ["./product-discount-card.component.css"],
})
export class ProductDiscountCardComponent implements OnInit {
  isExpand: boolean = false;
  @Output() productDiscountClick: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onGetNow() {
    this.productDiscountClick.emit();
    this.router.navigate(["product-discount"]);
  }
}
