<div class="dynamic_wrapper">
    <div class="inner_dynamic_sec text-center">
        <div class="top_process_section">
            <div class="d-flex justify-content-center">
                <span class="icon-tick-fill1 tick_icon_color"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 tick_icon"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 tick_icon "></span>
            </div>
            <div class="container_content_sec">
                <div class="inner_container_content">
                    <div class="sec_inner_content">
                        <div class="vs-messages mb-2"></div>
                        <div class="title_sec mb-4">
                            <h4 class="heading">Email Confirmation</h4>
                        </div>
                     
                        <div class="mail_address_content">
                            <h5 class="text-left mb-1 font_w_500">Please confirm that your email address is correct</h5>
                            <p class="text-left mb-1 label_color"><span class="text-left"> Email Address</span></p>
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <div class="user_email ellipsis ">{{user_email}}</div>
                                <div class="edit_icon" (click)="onBackToContinue()"><span
                                        class="icon-edit1 icon_edi p-2"></span></div>
                            </div>
                            <div class="text-center">
                                <button type="submit" (click)="onContinue()"
                                    class="btn_width btn button_links mr-1 btn_width_1 coninue_btn btn_color">Continue</button>
                            </div>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>