import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";
import * as $ from "jquery";

declare var gtag;
declare const fbq = "";

declare const Gauge: any;

@Component({
  selector: "app-payment-oral-health-score",
  templateUrl: "./payment-oral-health-score.component.html",
  styleUrls: ["./payment-oral-health-score.component.css"],
})
export class PaymentOralHealthScoreComponent implements OnInit {
  @ViewChild("graphCanvas") graphCanvas: ElementRef;
  ctx: CanvasRenderingContext2D;
  canvasGraphEl: HTMLCanvasElement;

  overall_score: any = "";
  OHRImage: any;
  OHRText: any = "";
  created_date: any = "";
  appservices: Services;
  widget_flow_type: any = "";
  ohr_unique_key: any = "";
  payment_type: any = "";
  rating_explanation: any = "";
  app_details: any = "";
  demoGauge: any = "";
  meterClassName: string = "";
  needleImage: string = "display_none";
  displayHeader: any = "Oral Health Rating";

  constructor(private router: Router) {
    this.appservices = new Services();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.canvasGraphEl = this.graphCanvas.nativeElement;
      this.ctx = this.canvasGraphEl.getContext("2d");
      this.drawNewGraph();
      this.needleImage = "display_block";
    }, 1000);

    // console.log( 'sa_wg_payment_oral_screen'  );
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_oral_screen",
      true
    );

    this.payment_type = this.appservices.getSession("payment_type", false);
    this.app_details = JSON.parse(
      JSON.stringify(this.appservices.getSession("app_details", true))
    );
    this.appservices.setSession("bannerTitleText", this.displayHeader, false);
    this.widget_flow_type = this.appservices.getSession(
      "widget_flow_type",
      false
    );
    this.ohr_unique_key = this.appservices.getSession("ohr_unique_key", false);
    if (
      this.appservices.isEmpty(this.widget_flow_type) ||
      this.appservices.isEmpty(this.ohr_unique_key)
    ) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    }
    this.overall_score = this.appservices.getSession("overall_score", false);
    // this.overall_score = 1;
    if (this.overall_score == "0" || this.overall_score == 0) {
      this.overall_score = 1;
    }
    if (this.overall_score == "10" || this.overall_score == 10) {
      this.overall_score = 9;
    }
    this.OHRImage = this.appservices.getSmileyImage(this.overall_score);
    this.OHRText = this.appservices.getSmileyText(this.overall_score);
    this.created_date = this.appservices.getSession("created_date", false);

    if (this.overall_score <= 4) {
      this.rating_explanation = this.app_details.report_info_descriptions3;
    }

    if (this.overall_score >= 5 && this.overall_score <= 7) {
      this.rating_explanation = this.app_details.report_info_descriptions2;
    }

    if (this.overall_score >= 8 && this.overall_score <= 10) {
      this.rating_explanation = this.app_details.report_info_descriptions1;
    }
  }

  drawNewGraph() {
    var center = { x: 115, y: 115 };
    var radius = 100;
    var quadrants = [
      {
        angleStart: Math.PI * -0.5,
        angleEnd: 0,
        x1: center.x,
        y1: center.y - radius,
        x2: center.x + radius,
        y2: center.y,
        colorStops: [
          { stop: 0, color: "#ffc906" },
          { stop: 1, color: "red" },
        ],
      },
      {
        angleStart: Math.PI,
        angleEnd: Math.PI * 1.5,
        x1: center.x - radius,
        y1: center.y,
        x2: center.x,
        y2: center.y - radius,
        colorStops: [
          { stop: 0, color: "green" },
          { stop: 1, color: "#ffc906" },
        ],
      },
    ];

    // Draw arc quadrants.
    for (var i = 0; i < quadrants.length; ++i) {
      var quad = quadrants[i];
      var grad = this.ctx.createLinearGradient(
        quad.x1,
        quad.y1,
        quad.x2,
        quad.y2
      );
      // Color stops.
      for (var j = 0; j < quad.colorStops.length; ++j) {
        var cs = quad.colorStops[j];
        grad.addColorStop(cs.stop, cs.color);
      }
      // Draw arc.
      this.ctx.beginPath();
      this.ctx.arc(center.x, center.y, radius, quad.angleStart, quad.angleEnd);
      this.ctx.strokeStyle = grad;
      this.ctx.lineWidth = 30;
      this.ctx.stroke();

      var angle = 180 - Math.floor(this.overall_score) * 18;
      this.meterClassName = "rotate" + angle;
    }
  }

  getReportInsight() {
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_button_recommendations",
      true
    );
    if (this.payment_type === "") {
      this.appservices.setSession("payment_successful", "true", false);
      this.router.navigate(["payment-oral-health-score-completed"]);
    } else {
      this.router.navigate(["payment-access-report"]);
    }
  }
}
