import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";

@Component({
  selector: "app-ohr-email-success",
  templateUrl: "./ohr-email-success.component.html",
  styleUrls: ["./ohr-email-success.component.css"],
})
export class OhrEmailSuccessComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  bannerTitleText: string | JSON;
  responsedata: any;

  constructor(private router: Router) {
    this.appservices = new Services();
  }
  ngOnInit(): void {
    this.bannerTitleText = this.appservices.getSession(
      "bannerTitleText",
      false
    );
  }
  onBackToTest() {
    this.router.navigate(["upload-smile-photo"]);
  }
}
