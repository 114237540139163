<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div id="steps">
                <div id="circles" class="circle-steps">
                    <div data-num="1" id="num1"></div>
                    <div data-num="2" id="num2"></div>
                    <div data-num="3" id="num3"></div>
                </div>
            </div>
            <div class="before_after_images_sec">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="text-center">
                            <h3 class="sub_head mb-4">Your Before & After photos are here</h3>
                            <div class="grey_color font_13">
                                <p>Based on your selected gum colour, teeth template and teeth shade</p>
                            </div>
                            <div class="mx-3">
                                <div class="d-flex align-items-center justify-content-center mt-2">
                                    <img *ngFor="let data of selected_styles let i = index"
                                        [defaultImage]="defaultImage" [lazyLoad]="data" alt="style"
                                        [ngClass]="appservices.getImageWidth(i)">

                                </div>
                            </div>
                            <div class="select_btn text-center media_button">
                                <button type="submit"
                                    class="btn  w-100 button_links btn_width_2 continue_btn btn_color mx-0 mt-4 justify-content-center align-items-center"
                                    (click)="reportSend(template3)">Email report</button>
                                <div class="pt-4" *ngIf="displaySettings">
                                    <a href="{{button_url}}" class="btn  w-100 start_btn btn_width_2 ctabtn"
                                        target="_blank">{{button_text}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 pointer_events">
                        <div class="inner_points mt-2 max_width_300">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="before_img">
                                    <div class="image_look mr-3">
                                        <img [defaultImage]="defaultImage" class="pointer_link"
                                            [lazyLoad]="before_image" alt="preview_look"
                                            (click)="openImageViewModal(template,before_image)">
                                        <!-- <figcaption class="text-center mt-2 font_w_500"> Before </figcaption> -->
                                    </div>
                                </div>
                                <div class="before_img">
                                    <div class="image_look">
                                        <img [defaultImage]="defaultImage" class="pointer_link" [lazyLoad]="after_image"
                                            alt="preview_look" (click)="openImageViewModal(template,after_image)">
                                        <!-- <figcaption class="text-center mt-2 font_w_500"> After </figcaption> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="before_after_images mt-3">
                            <ngx-slick-carousel class="carousel m-0 before_after_carousel carousel_custom1"
                                #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                                <div ngxSlickItem *ngFor="let data of beforeAfterImages" class="slide">
                                    <div class="pointer_link smile_slicker_images">
                                        <img [defaultImage]="defaultImage" [lazyLoad]="data.images"
                                            [errorImage]="defaultImage" alt="score_one">
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                        <p class="text-left mt-2 font_10 grey_color note"><b>Note: </b>While we make every effort to
                            continually update the accuracy of our Artificial Intelligence engine, you should always
                            verify this report with a visit to a Registered Dentist. The accuracy of the results of this
                            Design My Smile rely on the quality of the image taken by you as explained in the
                            instructions within the platform. Design my smile provides an indication of what changes you
                            might consider for the appearance of your teeth if you choose. These indicative results
                            cannot be guaranteed. Not all cosmetic dental procedures are necessary or appropriate for
                            all people. This image is indicative only. The need for and results of any cosmetic dental
                            procedure will vary from person to person. Please follow the recommendations of your
                            Registered Dentist.
                        </p>
                    </div>
                </div>
                <div class="select_btn text-center responsive_button">
                    <button type="submit"
                        class="btn w-100 button_links btn_width_2 continue_btn btn_color mx-0 my-4 justify-content-center align-items-center"
                        (click)="reportSend(template3)">Email report</button>
                    <div class="" *ngIf="displaySettings">
                        <a href="{{button_url}}" class="btn  w-100 start_btn btn_width_2 ctabtn"
                            target="_blank">{{button_text}}</a>
                    </div>
                </div>
            </div>
            <div class="get_in_touch_sec">
                <div class="get_in_touch_msg">
                    <span> We have sent your report to your registered email address<a
                            href="mailto:{{user_email}}">({{user_email}})</a>. To view report, Please use your login
                        details which Smilo.ai sent while you access widget first time.</span>
                </div>
                <h3 class="sub_head mb-4 text-center">Get in touch with us</h3>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12 my-3 max_width">
                                <p class="grey_color font_16 MuseoSans-500 mx-1">
                                    {{app_details.design_my_smile_survey_question1}}</p>
                            </div>
                            <div class="col-lg-7 col-md-12 my-3 max_width">
                                <div class="row options_sec mx-0">
                                    <div class="text-center width_110"
                                        *ngFor="let data of questionOneOptions let i = index">
                                        <div class="mx-1">
                                            <input type="checkbox" id="answer{{i+1}}" value="{{data.name}}"
                                                (change)="questionOneSelectChangeHandler($event)"
                                                class="questionOneOptions">
                                            <label class="options" for="answer{{i+1}}">
                                                {{data.value}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12 my-3 max_width">
                                <p class="grey_color font_16 MuseoSans-500 mx-1">
                                    {{app_details.design_my_smile_survey_question2}}</p>
                            </div>
                            <div class="col-lg-7 col-md-12 my-3 max_width">
                                <div class="row options_sec mx-0">
                                    <div class="text-center" *ngFor="let data of questionTwoOptions let i = index">
                                        <div class="mx-1">
                                            <input type="checkbox" id="{{data.name}}"
                                                (change)="questionTwoSelectChangeHandler($event)" name="{{data.name}}"
                                                class="example questionTwoOptions">
                                            <label for="{{data.name}}" class="options">{{data.value}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center my-3 pt-3 d-flex justify-content-center flex_direction">
                    <div class="mb-3">
                        <button class="pointer_link btn btn_width_2 skip_btn mr-3"
                            (click)="navigate('survey_skip',template1)">Skip</button>
                        <button [disabled]="isButtonDisabled"
                            class="btn button_links submit_btn btn_color btn_width_2 finish_btn"
                            (click)="onSubmitSurvey(template1,'rating_section')">Finish</button>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body">
        <div class="model_section ">
            <div class="cancel_button_sec text-right">
                <button type="button" class="close close_button" (click)="modalRef.hide()"><span>×</span></button>
            </div>
            <div class="model_content_section">
                <div class="img_poto_smile">
                    <img [defaultImage]="defaultImage" class="pointer_link" [lazyLoad]="displayImage"
                        alt="preview_image">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template1>
    <div class="modal-body p-0">
        <div class="model_section">
            <div class="vs-messages-model text-center mb-2"></div>
            <div class="rating_section">
                <div class="text-center">
                    <h3 class="sub_head rating_head">Enjoying the <br> Virtual Consult?</h3>
                    <p class="grey_color font_14">Tap an emoji to rate us</p>
                </div>
                <hr>
                <div class="model_content_section">
                    <div class="d-flex align-items-center justify-content-around flex-wrap">

                        <div class="pointer_link text-center half_opacity rating_sub"
                            (click)="onSubmitRating(template1,'rating_section','Poor',1)">
                            <div class="rating">
                                <img src="assets/images/better.png" alt="better">
                            </div>
                            <p class="blue_color font_10 mt-1">Could be better</p>
                        </div>

                        <div class="pointer_link text-center half_opacity rating_sub"
                            (click)="onSubmitRating(template1,'rating_section','Great',4)">
                            <div class="rating">
                                <img src="assets/images/good.png" alt="good">
                            </div>
                            <p class="blue_color font_10 mt-1">Good</p>
                        </div>

                        <div class="pointer_link text-center half_opacity rating_sub border-right-0"
                            (click)="onSubmitRating(template1,'rating_section','Excellent',5)">
                            <div class="rating">
                                <img src="assets/images/amazing.png" alt="amazing">
                            </div>
                            <p class="blue_color font_10 mt-1">Amazing</p>
                        </div>
                    </div>                  
                </div>
            </div>
            <div class="feedback_section display_none">
                <div class="text-center">
                    <h4 class="font-weight-bold mb-2">How can we improve?</h4>
                    <h4 class="">Please share your ideas and suggestions</h4>
                </div>
                <div class="model_content_section mt-3">
                    <textarea placeholder="Type your suggestions" class="firstletter_capital form-control" rows="5"
                        id="feedback" maxlength="500"></textarea>
                </div>
                <div class="text-center mt-3 pt-3">
                    <button class="btn button_links btn-block mr-1 submit_btn btn_color mb-3"
                        (click)="onSubmitRating(template1,'feedback_section','','1')">Submit</button>
                    <span class="mt-3 pointer_link agree_text" (click)="navigate('rating_skip',template1)">Skip</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #template3>
    <div class="modal-body">
        <div class="model_section p-2">
            <div class="vs-messages-model text-center mb-2"></div>
            <div class="text-center">
                <h3 class="font-weight-bold blue_heading">Please let us know</h3>
            </div>
            <div class="model_content_section">
                <p class="font_w_600 text-left mt-3">{{app_details.design_my_smile_survey_question1}}</p>
                <div class="checkbox_round d-flex align-items-center mt-2"
                    *ngFor="let data of questionOneOptions let i = index">
                    <input id="answer{{i+1}}" type="checkbox" name="color" value="{{data.name}}">
                    <label for="answer{{i+1}}"><span class="check_text font_14">{{data.value}}</span></label>
                </div>
                <p class="font_w_600 text-left mt-3">{{app_details.design_my_smile_survey_question2}}</p>
                <div class="checkbox_round d-flex align-items-center mt-2"
                    *ngFor="let data of questionTwoOptions let i = index">
                    <input id="{{data.name}}" (change)="selectChangeHandler($event)" type="checkbox"
                        name="{{data.name}}" class="example">
                    <label for="{{data.name}}"> <span class="check_text font_14">{{data.value}}</span></label>
                </div>
                <div class="text-center mt-3 pt-3">
                    <button class="btn button_links btn-block mr-1 submit_btn btn_color mb-3"
                        (click)="onSubmitSurvey(template1,'rating_section')">Submit</button>
                    <span (click)="navigate('survey_skip',template1)" class="mt-3 pointer_link agree_text">Skip</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>