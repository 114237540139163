<div class="date-picker-container d-flex flex-column ">
    <div class="dp">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [minDate]="minDate" [dayTemplate]="t"
            outsideDays="hidden">
        </ngb-datepicker>
    </div>

    <ng-template #t let-date="date">
        <span class="custom-day" [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
            [class.faded]="isInside(date)">
            {{ date.day }}
        </span>
    </ng-template>

    <div class="date-btns-container d-flex flex-wrap" *ngIf="isBtnsRequired">
        <button class="btn-sm btn-outline-primary m-1" id="btn1" (click)="selectToday($event);">Today</button>
        <button class="btn-sm btn-outline-primary m-1" id="btn2" (click)="selectTomorrow($event);">Tomorrow</button>
        <button class="btn-sm btn-outline-primary m-1" id="btn3" (click)="selectThisWeek($event);">This Week</button>
        <button class="btn-sm btn-outline-primary m-1" id="btn4" (click)="selectNextWeek($event);">Next Week</button>
    </div>
</div>