import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-videos-instructions',
  templateUrl: './videos-instructions.component.html',
  styleUrls: ['./videos-instructions.component.css']
})
export class VideosInstructionsComponent implements OnInit, OnDestroy {

  public destroyed = new Subject<any>();
  youtube_1:any;
  youtube_2:any;
  youtube_3:any;
  youtube_4:any;
  youtube_5:any;
  constructor(private sanitizer: DomSanitizer) { 
    
  }


  ngOnInit(): void {
    this.youtube_1=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/VCH8zdrlfyU/");
    this.youtube_2=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/bIKvTz4ix38/");
    this.youtube_3=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/nBx7CQCO7LM/");
    this.youtube_4=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/OGTQLTImHk0/");
    this.youtube_5=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/cLEklsoh4ic/");
  }
  
  onclick(){
    $(".player").attr('src',''); 
  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
