import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { Services } from '../../helpers/Services';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ImgResolution,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent
} from '@alyle/ui/image-cropper';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  return {
    cropper: lyl`{
      // max-width: 400px
      height: 400px
    }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
      padding: 1em
    }`
  };
};

@Component({
  selector: 'app-aus-cropper-dialog',
  templateUrl: './aus-cropper-dialog.component.html',
  styleUrls: ['./aus-cropper-dialog.component.css']
})
export class AusCropperDialogComponent implements OnInit {
  appservices: Services;
  readonly classes = this.sRenderer.renderSheet(STYLES);
  scale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 300,
    height: 300,
    type: 'image/png',
    keepAspectRatio: false,
    output: {
      width: 700,
      height: 700
    },
    resizableArea: true
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef, private router: Router, private route: ActivatedRoute
  ) {
    this.appservices = new Services;
    if (router.url.search('/upload-teeth-photos') >= 0) {
      this.myConfig = {
        width: 300, height: 300, keepAspectRatio: false,
        resizableArea: true, type: 'image/png',
      };
    } else if (router.url.search('/upload-smile-photo') >= 0) {
      this.myConfig = {
        width: 300, height: 300, keepAspectRatio: true,
        // width: 500, height: 350, keepAspectRatio: true,
        resizableArea: true, type: 'image/png',
        output: {
          width: 700,
          height: 700
        },
      };
    }
  }
  ngOnInit(): void {
  }
  /**
  * ngAfterViewInit - Load image when dialog animation has finished
  * @since   1.0
  * @returns {String}  base64
  */
  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
  }
  onReady(e: ImgCropperEvent) {
    let url = this.router.url;
    if (url.search('/upload-teeth-photos') >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700, height: 700, keepAspectRatio: false,
        resizableArea: true, type: 'image/png',
        output: {
          width: 700,
          height: 700
        }
      };
    } else if (url.search('/upload-smile-photo') >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700, height: 700, keepAspectRatio: true,
        resizableArea: true, type: 'image/png',
        // output: ImgResolution.OriginalImage
        output: {
          width: 700,
          height: 700
        }
      };
    }
  }

  /**
  * onCropped - Method after cropped Image finished
  * @since   1.0
  * @returns {String}
  */
  onCropped(e: ImgCropperEvent) {
    // console.log(e)
    // this.showDebugMessage('cropped img: '+ e);
  }
  /**
  * onLoaded - Method  Image loaded for crop
  * @since   1.0
  * @returns {String} 
  */
  onLoaded(e: ImgCropperEvent) {
    // console.log(e);
    let imgObj = { width: e.width, height: e.height };
    this.appservices.setSession("imgObj", imgObj, true);
    // console.log(imgObj)
    // GlobalHelper.showDebugMessage('img loaded'+ e);
  }
  /**
  * onError - Method  Image cropped error triggered
  * @since   1.0
  * @returns {String} 
  */
  onError(e: ImgCropperErrorEvent) {
    console.log(e);
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

}

