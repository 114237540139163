import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Services } from "../../app/helpers/Services";
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";
import { HttpClient } from "@angular/common/http";
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { DomainService } from "src/core/service/domain/domain.service";

declare var gtag;
declare const fbq = "";

@Component({
  selector: "app-payment-access-report",
  templateUrl: "./payment-access-report.component.html",
  styleUrls: ["./payment-access-report.component.css"],
})
export class PaymentAccessReportComponent implements OnInit {
  @ViewChild("graphCanvas") graphCanvas: ElementRef;
  ctx: CanvasRenderingContext2D;
  canvasGraphEl: HTMLCanvasElement;

  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  responsedata: any;
  dentalImages: any;
  defaultImage: string = "assets/images/carousel_loader.gif";

  widget_flow_type: any = "";
  payment_title: any = "";
  payment_sub_title: any = "";
  benefits: any = "";
  payment_amount: any = "";
  title_for_button: any = "";
  payment_button_text: any = "";
  payment_type: any = "";
  overall_score: any = "";
  OHRImage: any = "";
  OHRText: any = "";

  benefits_title: any = "";
  benefits_sub_title: any = "";
  benefits_one: any = "";
  benefits_two: any = "";
  benefits_three: any = "";

  report_id: any = "";
  created_date: any = "";
  app_details: any = "";
  rating_explanation: any = "";

  displayHeader: any = "Oral Health Rating";

  meterClassName: string = "";
  imageContainer: string = "visibility_none";

  get_contact_information_later: any = "";
  guest_user_id: any = "";

  ohr_flow: any;
  show_benefits_div: boolean = true;
  isRequestForAppointmentCard: boolean = false;
  isBuyReportCard: boolean = false;
  isProductDiscountCard: boolean = false;
  isPaymentSuccessFul: boolean = false;
  isDaburFlow: boolean = false;
  imageSelectionCount: any = "5";
  public isDomainAus;

  constructor(private router: Router, private domainService: DomainService) {
    this.appservices = new Services();
    this.isDomainAus = this.domainService.isAusRegionDomain("");
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    nextArrow:
      "<div class='nav-btn icon-right-arrow next-slide blue_color font_30'></div>",
    prevArrow:
      "<div class='nav-btn icon-left-arrow prev-slide blue_color font_30'></div>",
    infinite: false,
  };

  slickInit(e) {
    // console.log('slick initialized');
  }

  ngOnInit(): void {
    if (this.isDomainAus == true) {
      setTimeout(() => {
        this.canvasGraphEl = this.graphCanvas.nativeElement;
        this.ctx = this.canvasGraphEl.getContext("2d");
        this.drawNewGraph();
        this.imageContainer = "visibility_block";
      }, 1000);

      // console.log('sa_wg_payment_access');
    } else {
      this.imageSelectionCount = this.appservices.getSession(
        "images_selection_count",
        false
      );
      this.appservices.setSession(
        "current_url",
        "payment-access-report",
        false
      );

      this.appservices.sendAnalyticsData(
        "",
        "",
        "smilo_event",
        "sa_wg_payment_access",
        true
      );
      this.appservices.sendPageViewAnalyticsData(
        gtag,
        "",
        this.router.url,
        "OHR – Payment access report page"
      );
      this.app_details = JSON.parse(
        JSON.stringify(this.appservices.getSession("app_details", true))
      );
      // console.log(this.app_details);
    }

    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_access",
      true
    );
    this.app_details = JSON.parse(
      JSON.stringify(this.appservices.getSession("app_details", true))
    );
    // console.log(this.app_details);

    this.appservices.setSession("bannerTitleText", this.displayHeader, false);
    this.widget_flow_type = this.appservices.getSession(
      "widget_flow_type",
      false
    );
    this.report_id = this.appservices.getSession("ohr_unique_key", false);
    let overall_score = this.appservices.getSession("overall_score", false);
    if (
      this.appservices.isEmpty(this.widget_flow_type) ||
      this.appservices.isEmpty(this.report_id) ||
      overall_score == ""
    ) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    }

    this.dentalImages = [
      { url: "assets/images/Slide1@2x.png", title: "Healthy Teeth" },
      { url: "assets/images/Slide2@2x.png", title: "Mild Decay" },
      { url: "assets/images/Slide3@2x.png", title: "Moderate Decay" },
    ];

    this.widget_flow_type = this.appservices.getSession(
      "widget_flow_type",
      false
    );
    this.payment_title = this.appservices.getSession("payment_title", false);
    this.payment_sub_title = this.appservices.getSession(
      "payment_sub_title",
      false
    );
    this.isPaymentSuccessFul =
      this.appservices.getSession("payment_successful", false) === "true";

    this.benefits = this.appservices.getSession("benefits", false);
    this.payment_amount = this.appservices.getSession("payment_amount", false);
    this.title_for_button = this.appservices.getSession(
      "title_for_button",
      false
    );
    this.payment_button_text = this.appservices.getSession(
      "payment_button_text",
      false
    );
    this.payment_type = this.appservices.getSession("payment_type", false);

    this.benefits_title = this.appservices.getSession("benefits_title", false);
    this.benefits_sub_title = this.appservices.getSession(
      "benefits_sub_title",
      false
    );
    this.benefits_one = this.appservices.getSession("benefits_one", false);
    this.benefits_two = this.appservices.getSession("benefits_two", false);
    this.benefits_three = this.appservices.getSession("benefits_three", false);

    this.overall_score = this.appservices.getSession("overall_score", false);
    // this.overall_score = 1;

    this.OHRImage = this.appservices.getSmileyImage(this.overall_score);
    this.OHRText = this.appservices.getSmileyText(this.overall_score);

    this.created_date = this.appservices.getSession("created_date", false);

    this.ohr_flow = this.appservices.getSession("ohr_flow", false);
    console.log(" this.ohr_flow ", this.ohr_flow);

    // console.log(" this.ohr_flow : " + this.ohr_flow + " this.widget_flow_type  " + this.widget_flow_type + " this.payment_type : " + this.payment_type);

    if (this.overall_score <= 4) {
      if (this.isDomainAus == true) {
        this.rating_explanation = this.app_details.report_info_descriptions3;
      }
      // this.rating_explanation = this.app_details.report_info_descriptions3;
      if (this.imageSelectionCount == 1) {
        this.rating_explanation =
          "Your Smile Health Assessment suggests your teeth and gums are in a relatively poor condition. Based on the images provided it appears there is a high possibility of dental decay and gum disease.";
      } else {
        this.rating_explanation =
          "Your Oral Health Assessment suggests your teeth and gums are in a relatively poor condition. Based on the images provided it appears there is a high possibility of dental decay and gum disease.";
      }

      this.overall_score = 1;
    }

    if (this.overall_score >= 5 && this.overall_score <= 7) {
      // this.rating_explanation = this.app_details.report_info_descriptions2;
      if (this.isDomainAus == true) {
        this.rating_explanation = this.app_details.report_info_descriptions2;
      } else {
        this.overall_score = 5;
        if (this.imageSelectionCount == 1) {
          this.rating_explanation =
            "Your Smile Health Assessment suggests your teeth and gums present in a relatively fair condition. Based on the images shown, it appears there are possibilities of dental decay and gum disease.";
        } else {
          this.rating_explanation =
            "Your Oral Health Assessment suggests your teeth and gums present in a relatively fair condition. Based on the images shown, it appears there are possibilities of dental decay and gum disease.";
        }
      }
    }

    if (this.overall_score >= 8 && this.overall_score <= 10) {
      if (this.isDomainAus == true) {
        this.rating_explanation = this.app_details.report_info_descriptions1;
      }
      // this.rating_explanation = this.app_details.report_info_descriptions1;
      else {
        this.overall_score = 9;
        if (this.imageSelectionCount == 1) {
          this.rating_explanation =
            "Your Smile Health Assessment suggests your teeth and gums are in a relatively good condition. Based on the images provided it appears there is a low possibility of the presence of dental decay and gum disease. Please regularly monitor your Smile Health Assessment and visit your dentist every 6 months.";
        } else {
          this.rating_explanation =
            "Your Oral Health Assessment suggests your teeth and gums are in a relatively good condition. Based on the images provided it appears there is a low possibility of the presence of dental decay and gum disease. Please regularly monitor your Oral Health Assessment and visit your dentist every 6 months.";
        }
      }
    }

    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);

    this.isRequestForAppointmentCard =
      this.appservices.getSession("is_request_for_appointment", false) ===
      "true";
    this.isBuyReportCard =
      this.appservices.getSession("is_purchase_detailed_report", false) ===
      "true";
    this.isProductDiscountCard =
      this.appservices.getSession("is_request_for_coupan_code", false) ===
      "true";
    this.isDaburFlow =
      this.appservices.getSession("isDaburFlow", false) === "true";
    console.log(
      "\nisPaymentSuccessFul:",
      this.isPaymentSuccessFul,
      "\nisRequestForAppointmentCard:",
      this.isRequestForAppointmentCard,
      "\nisBuyReportCard:",
      this.isBuyReportCard,
      "\nisProductDiscountCard:",
      this.isProductDiscountCard,
      "\nisDaburFlow:",
      this.isDaburFlow
    );
  }

  onBookNow() {
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_access_dental_appointment_book_open",
      true
    );
  }

  onProductDiscountClick() {
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_access_dental_product_discount_open",
      true
    );
  }

  onBuyReportClick() {
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_access_buy_report_card_open",
      true
    );
  }

  drawNewGraph() {
    var center = {
      x: 115,
      y: 115,
    };
    var radius = 100;
    var quadrants = [
      {
        angleStart: Math.PI * -0.5,
        angleEnd: 0,
        x1: center.x,
        y1: center.y - radius,
        x2: center.x + radius,
        y2: center.y,
        colorStops: [
          {
            stop: 0,
            color: "#ffc906",
          },
          {
            stop: 1,
            color: "red",
          },
        ],
      },
      {
        angleStart: Math.PI,
        angleEnd: Math.PI * 1.5,
        x1: center.x - radius,
        y1: center.y,
        x2: center.x,
        y2: center.y - radius,
        colorStops: [
          {
            stop: 0,
            color: "green",
          },
          {
            stop: 1,
            color: "#ffc906",
          },
        ],
      },
    ];

    // Draw arc quadrants.
    for (var i = 0; i < quadrants.length; ++i) {
      var quad = quadrants[i];
      var grad = this.ctx.createLinearGradient(
        quad.x1,
        quad.y1,
        quad.x2,
        quad.y2
      );
      // Color stops.
      for (var j = 0; j < quad.colorStops.length; ++j) {
        var cs = quad.colorStops[j];
        grad.addColorStop(cs.stop, cs.color);
      }
      // Draw arc.
      this.ctx.beginPath();
      this.ctx.arc(center.x, center.y, radius, quad.angleStart, quad.angleEnd);
      this.ctx.strokeStyle = grad;
      this.ctx.lineWidth = 30;
      this.ctx.stroke();

      if (
        typeof this.overall_score === "string" &&
        !this.appservices.isEmpty(this.overall_score)
      ) {
        this.overall_score = Number(this.overall_score);
      }
      if (this.overall_score <= 4) {
        this.overall_score = 1;
      } else if (this.overall_score >= 5 && this.overall_score <= 7) {
        this.overall_score = 5;
      } else {
        this.overall_score = 9;
      }

      // if()

      // this.overall_score = 1;
      var angle = 180 - Math.floor(this.overall_score) * 18;
      this.meterClassName = "rotate" + angle;
      // var angle = 0;

      //   img = document.getElementById('container');
      //   angle = (angle + 18);
      //   img.className = "rotate" + angle;
    }
  }

  makePayment() {
    // console.log('sa_wg_payment_button_make_payment');
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_button_make_payment",
      true
    );

    if (this.payment_type === "stripe") {
      this.router.navigate(["make-stripe-payment"]);
    } else {
      this.appservices.setSession("payment_successful", "true", false);
      this.router.navigate(["payment-oral-health-score-completed"]);
    }
  }

  show_benefits() {
    this.show_benefits_div = true;
  }
}
