<div class="dynamic_wrapper">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="d-flex justify-content-center">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_blue"></span>
            </div>
            <div class="container_content_sec">
                <div class="inner_container_content">
                    <div class="sec_inner_content">
                        <div class="title_sec">
                            <h4 class="heading">Select your Preference</h4>
                        </div>
                        <div class="img_section text-center">
                            <div class="d-flex justify-content-center">
                                <div class="position-relative select_preference">
                                    <div class="oral_score_img dental_img">
                                        <img src="assets/images/ohs_image.png" alt="OHS" class="select_img">
                                        <figcaption class="figure-caption fig_cap">Oral Health Rating</figcaption>
                                    </div>
                                    <div class="custom_checkbox position-absolute">
                                        <label class="selection">
                                            <input type="radio" class="preference_check" name="preference_check"><span
                                                class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="position-relative select_preference">
                                    <div class="smile_design dental_img">
                                        <img src="assets/images/smile_design.png" alt="smile_design" class="select_img">
                                        <figcaption class="figure-caption fig_cap">Design My Smile</figcaption>
                                    </div>
                                    <div class="custom_checkbox position-absolute">
                                        <label class="selection">
                                            <input type="radio" class="preference_check" name="preference_check"><span
                                                class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center select_img_sec mt-5">
                                <button class="btn button_links btn_width_1"
                                    [routerLink]="['/upload-smile-photo']">Continue</button>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>