import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smile-photo-preview',
  templateUrl: './smile-photo-preview.component.html',
  styleUrls: ['./smile-photo-preview.component.css']
})
export class SmilePhotoPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
