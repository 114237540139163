<div class="dynamic_wrapper">
  <div class="content_main_wrapper">
    <div class="vs-messages"></div>
    <div class="row mx-0 flex_direction">
      <div class="col-lg-6 col-md-12 col-sm-12 p-0 background_white position-relative">
        <div class="signup_img">
          <img [defaultImage]="defaultImage" [lazyLoad]="staticImage" [errorImage]="defaultImage" alt="Signup-image"
            class="id_img" />
        </div>
      </div>


      <div class="col-lg-6 col-md-12 col-sm-12 pad_25">
        <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form" (ngSubmit)="onSubmit()">

          <h3 class="sub_head contact-info-sub-head mb-3">
            Please enter your email address so we can send you your personalised
            report
          </h3>

          <div class="form-group position-relative min_height">
            <input (keyup)="onEnterData($event)" type="text" formControlName="firstName" maxlength="31"
              class="first_name form-control" (keyup)="length('first_name')" placeholder="First Name"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.firstName.errors }" trim="blur"
              value="{{ firstName | titlecase }}" appInputRestriction />
            <div *ngIf="isSubmitted && ci.firstName.errors" class="invalid-feedback">
              <div *ngIf="ci.firstName.errors.required" class="text-left">
                First Name is required
              </div>
              <div *ngIf="ci.firstName.errors.minlength" class="text-left">
                First Name should contain at least 3 characters.
              </div>
            </div>
          </div>
          <div class="form-group position-relative min_height">
            <input (keyup)="onEnterData($event)" type="text" formControlName="lastName" maxlength="31"
              class="last_name form-control" (keyup)="length('last_name')" placeholder="Last Name"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.lastName.errors }" trim="blur"
              value="{{ lastName | titlecase }}" appInputRestriction />
            <div *ngIf="isSubmitted && ci.lastName.errors" class="invalid-feedback">
              <div *ngIf="ci.lastName.errors.required" class="text-left">
                Last Name is required
              </div>
              <div *ngIf="ci.lastName.errors.minlength" class="text-left">
                Last Name should contain at least 3 characters.
              </div>
            </div>
          </div>
          <div class="form-group position-relative">
            <input (keyup)="onEnterData($event)" maxlength="50" type="email" formControlName="email"
              class="form-control name_holder" placeholder="Email Address"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.email.errors }" trim="blur" />
            <div *ngIf="isSubmitted && ci.email.errors" class="invalid-feedback">
              <div *ngIf="ci.email.errors.required" class="text-left">
                Email Address is required
              </div>

              <div *ngIf="ci.email.errors.pattern" class="text-left">
                Email Address is invalid.
              </div>
            </div>
          </div>
          <div class="form-group position-relative">
            <input (keyup)="onEnterData($event)" type="tel" (keydown.space)="$event.preventDefault()"
              onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              formControlName="phoneNumber" [maxlength]="isDomainAus? 15 :10" (countryChange)="countryChange($event)"
              (intlTelInputObject)="telInputObject($event)" placeholder="Mobile Number" ng2TelInput
              class="phone-format form-control" id="phoneNumber"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.phoneNumber.errors }" trim="blur" />


            <div *ngIf="isSubmitted && ci.phoneNumber.errors" class="invalid-feedback">
              <div *ngIf="ci.phoneNumber.errors.required" class="text-left">
                Mobile Number is required
              </div>
              <div *ngIf="ci.phoneNumber.errors.pattern" class="text-left">Please enter 10 digit Mobile Number.</div>

            </div>
          </div>

          <div *ngIf="display_branches_div" class="form-group position-relative">
            <span class="practice-location-span">
              Dental Practice near to your location
            </span>
            <select name="userSelectedPractice" id="userSelectedPractice" class="form-control"
              (change)="onSelectPractice($event)">
              <option value="">Please select any branch</option>
              <option value="" [selected]="true">Global Branch</option>
              <option *ngFor="let method of practiceBranches" [attr.data-isocode]="method.address" [value]="method.id"
                [selected]="method.id == preSelectedBranch">
                {{ method.name }}
              </option>
            </select>
          </div>

          <div class="mt-35">


            <div class="d-flex justify-content-end flex_start">
              <button [disabled]="" type="submit" class="my-4 btn btn_width_2 button_links coninue_btn btn_color mx-0">
                <span class="save_continue_btn_span">Next</span><span
                  class="icon_arrow icon-right-arrow ml-3 font_12"></span>
              </button>
            </div>


          </div>
        </form>
      </div>
    </div>
  </div>
</div>