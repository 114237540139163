import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Services } from '../../app/helpers/Services';
import * as moment from 'moment';
import 'moment-timezone';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as Bowser from "bowser";

declare var gtag;
declare const fbq = '';

@Component({
  selector: 'app-select-gum-color',
  templateUrl: './select-gum-color.component.html',
  styleUrls: ['./select-gum-color.component.css']
})
export class SelectGumColorComponent implements OnInit {
  modalRef: BsModalRef;
  // static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  // user_email: any;
  // user_details: any;
  gum_types: any;
  flag: boolean = false;
  Pleasewait: boolean = false;
  teeth_template: any;
  template_id: any;
  shade_id: any;
  imageURL: any;
  // responsedata: any;
  // is_first_time: boolean = false;
  // message: any;
  // onTemlateClick: boolean = false;
  // uploaded_image: any;
  // smile_design_url: any;
  smile_design_original_url: any;
  preview_image: any;
  defaultImage: string = "assets/images/tooth_loader.svg";
  teeth_shade: any;
  // selected_teeth_shade: any = 'a1';
  selected_teeth_shade: any;
  // template_flag: boolean = false;
  // isTeethSelected: boolean = false;
  message: string = "Please choose your preferred teeth style";
  // show_sec_inner_content: boolean = false;
  // loader_show: boolean = true;
  isButtonDisabled: boolean = true;
  errorMessage: any;
  smile_preview: boolean = false;
  selected_styles: any[] = [];
  shade_image: any;
  template_image: any;
  gum_image: any;
  before_image: any;
  shade_value: any;
  displayHeader: any = 'Design My Smile';
  get_contact_information_later: any = '';
  guest_user_id: any = '';
  hasRedirectedFromContactForm: any = '';
  slickCurrentIndex: any = 0;
  slideConfig: any = '';

  constructor(private router: Router, 
    private modalService: BsModalService,
    private httpClient: HttpClient, ) {
    this.appservices = new Services;
  }

  @HostListener('window:popstate', ['$event']) onPopState(e: KeyboardEvent) {
    this.modalRef.hide();
  }

  slickInit(e) {
    // console.log('slick initialized');
  }
  ngOnInit(): void {
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_preview', true);
    this.appservices.setSession("bannerTitleText", this.displayHeader, false);
    this.before_image = this.appservices.getSession("preview_image_url", false);
    this.gum_types = JSON.parse(JSON.stringify(this.appservices.getSession("gum_types", true)));
    this.teeth_template = JSON.parse(JSON.stringify(this.appservices.getSession("teeth_template", true)));
    this.teeth_shade = JSON.parse(JSON.stringify(this.appservices.getSession("teeth_shade", true)));
    this.preview_image = this.appservices.getSession("preview_image_url", false);

    this.get_contact_information_later = this.appservices.getSession("get_contact_information_later", false);
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);
    this.hasRedirectedFromContactForm = this.appservices.getSession("hasRedirectedFromContactForm", false);
    this.shade_id = this.appservices.getSession("shade_id", false);
    this.template_id = this.appservices.getSession("template_id", false);
    this.selected_teeth_shade = this.appservices.getSession("teeth_shade_id", false);

    if (this.hasRedirectedFromContactForm == 'true' && !this.appservices.isEmpty(this.shade_id) && !this.appservices.isEmpty(this.template_id) && !this.appservices.isEmpty(this.selected_teeth_shade)) {
      this.gum_image = this.appservices.getSession("gum_image", false);
      this.template_image = this.appservices.getSession("template_image", false);
      this.shade_image = this.appservices.getSession("teeth_shade_img", false);
      this.slickCurrentIndex = this.appservices.getSession("slickCurrentIndex", false);
      if (!this.appservices.isEmpty(this.slickCurrentIndex)) {
        if (this.slickCurrentIndex % 2 != 0) {
          this.slickCurrentIndex = this.slickCurrentIndex - 1;
        }
        else {
          // this.slickCurrentIndex = this.slickCurrentIndex/2;
        }
      }
      setTimeout(() => {
        // this.onTeethShade(this.selected_teeth_shade,this.shade_image);  
        this.get_contact_information_later = 'no';
        this.smileDesignPreview();
      }, 1000);
    }
    this.slideConfig = {
      "initialSlide": parseInt(this.slickCurrentIndex),
      "slidesToShow": 2,
      "slidesToScroll": 2,
      "nextArrow": "<div class='nav-btn icon-right-arrow next-slide blue_color font_12'></div>",
      "prevArrow": "<div class='nav-btn icon-left-arrow prev-slide blue_color font_12'></div>",
      "infinite": false
    };
    this.hideLoader();
  }

  hideLoader() {
    setTimeout(function () {
      $(".analysing_sec").hide();
      $(".sec_inner_content").show();
      $(".gum_border_0").addClass("gum_border_grey");
    }, 500);
  }

  onShade(shade_id, image) {
    this.appservices.setSession("shade_id", shade_id, false);
    this.appservices.setSession("gum_image", image, false);
    this.appservices.serviceMessages('vs-messages', '', 'danger');
    this.shade_id = shade_id;
    this.gum_image = image;
    $('.apply_btn').show();
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_gum_change', true);
    this.buttonDisabled();
  }

  onTemplate(template_id, image) {
    this.appservices.setSession("template_id", template_id, false);
    this.appservices.setSession("template_image", image, false);
    this.appservices.serviceMessages('vs-messages', '', 'danger');
    this.template_id = template_id;
    this.template_image = image;
    $('.apply_btn').show();
    this.slickCurrentIndex = $('.slick-current').attr('data-slick-index');
    this.appservices.setSession("slickCurrentIndex", this.slickCurrentIndex, false);
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_style_change', true);
    this.buttonDisabled();
  }

  onTeethShade(val, image) {
    this.appservices.setSession("teeth_shade_id", val, false);
    this.appservices.setSession("teeth_shade_img", image, false);
    this.shade_value = '';
    $('img.img_teeth_shade_a1').attr('src', 'https://s3.amazonaws.com/oraltech-dev/assets/teeth_shades/A1.png');
    $('img.img_teeth_shade_a2').attr('src', 'https://s3.amazonaws.com/oraltech-dev/assets/teeth_shades/A2.png');
    $('img.img_teeth_shade_b3').attr('src', 'https://s3.amazonaws.com/oraltech-dev/assets/teeth_shades/B3.png');
    this.appservices.serviceMessages('vs-messages', '', 'danger');
    this.selected_teeth_shade = val;
    this.shade_image = image;
    $('.apply_btn').show();
    this.shade_value = 'assets/images/teethshade_' + val + '.png';
    $('img.img_teeth_shade_' + val).attr('src', this.shade_value);
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_shade_change', true);
    this.buttonDisabled();
  }

  public getDeviceInfo() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details = userAgent.browser.name + ' ' + userAgent.os.name + ' ' + userAgent.browser.version;
    return browser_details;
  }

  smileDesignPreview() {
    /*if(this.get_contact_information_later=='yes')
    {
      this.appservices.setSession("redirecting_url",'select-gum-color', false);
      this.router.navigate(['contact-information']);
    }
    else     
    {*/
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_submit', true);
    this.selected_styles = [];
    this.preview_image = '';
    $('.pointer_events').css('pointer-events', 'none');
    $('.coninue_btn').html('Please Wait...').attr('disabled', true);
    this.imageURL = this.appservices.getSession("smile_image_url", false);
    this.apirequest["image_url"] = this.imageURL;
    this.apirequest["template_number"] = this.template_id;
    if (this.shade_id)
      this.apirequest["shade_number"] = this.shade_id;
    this.apirequest["time_zone"] = moment.tz.guess();
    if (this.selected_teeth_shade)
      this.apirequest["teeth_shade"] = this.selected_teeth_shade.toLowerCase();
    this.apirequest["device_details"] = this.getDeviceInfo();
    this.apirequest["selected_branch_id"] = this.appservices.getSession("user_selected_practice_branch", false);
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    this.input = this.appservices.generteJSONFromArray(this.apirequest);
    this.selected_styles.push(this.gum_image, this.template_image, this.shade_image);
    this.appservices.callJSONAPI(this.appservices.PROCESS_DESIGN_MY_SMILE, this.input, 'post', this, '').subscribe(
      (data: any) => {
        $('.pointer_events').css('pointer-events', 'auto');
        $('.coninue_btn').html('Next').append('<span class="icon_arrow icon-right-arrow ml-3 font_12"></span>').attr('disabled', false);
        this.isButtonDisabled = true;

        this.appservices.setSession("shade_id", '', false);
        this.appservices.setSession("gum_image", '', false);
        this.appservices.setSession("template_id", '', false);
        this.appservices.setSession("template_image", '', false);
        this.appservices.setSession("teeth_shade_id", '', false);
        this.appservices.setSession("teeth_shade_img", '', false);
        if (data.status) {
          $('.coninue_btn').attr('disabled', true);
          this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_process', true);
          this.appservices.setSession("selected_styles", this.selected_styles, true);
          this.appservices.setSession("smile_id", data.result.smile_id, false);
          this.appservices.setSession("before_image", data.result.smile_design_original_url, false);
          this.appservices.setSession("after_image", data.result.smile_design_url, false);
          this.preview_image = data.result.smile_design_url;
          setTimeout(() => {
            this.smile_preview = true;
          }, 1000);
        } else {
          this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_invalid', true);
          this.preview_image = this.appservices.getSession("preview_image_url", false);
          this.errorMessage = data.message;
          this.flag = true;
          $(".sec_inner_content").hide();
          this.Pleasewait = false;
          this.appservices.serviceMessages('vs-messages', data.message, 'danger');
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
    //}
  }

  buttonDisabled() {
    if (this.shade_id && this.template_id && this.selected_teeth_shade) {
      this.isButtonDisabled = false;
      $('#num3').removeClass('disabled');
    }
  }

  onPreview() {
    if (!this.shade_id) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Please choose your preferred current gum color', 'danger');
    }
    else if (!this.template_id) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', this.message, 'danger');
    }
    else if (!this.selected_teeth_shade) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Please choose your preferred teeth shade', 'danger');
    }
    else {
      this.smileDesignPreview();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }

  navigate() {
    this.appservices.sendAnalyticsData('', '', 'smilo_event', 'sa_wg_dms_teeth_before_after', true);
    this.router.navigate(["smile-before-after"]);
  }

  updateImgAfterLoad() {
    $('img.img_teeth_shade_' + this.selected_teeth_shade).attr('src', 'assets/images/teethshade_' + this.selected_teeth_shade + '.png');
  }

}
