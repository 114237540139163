import { Component, ElementRef, OnInit } from "@angular/core";
import { LottiePlayer, PlayMode } from "@lottiefiles/lottie-player";
import { EventEmitter } from "protractor";
import { Services } from "../helpers/Services";

@Component({
  selector: "app-booking-success",
  templateUrl: "./booking-success.component.html",
  styleUrls: ["./booking-success.component.css"],
})
export class BookingSuccessComponent implements OnInit {
  private _instance: LottiePlayer;
  booking_details: any;
  appservices: Services;

  constructor(private elementRef: ElementRef) {
    this.appservices = new Services();
    this.booking_details = JSON.parse(
      JSON.stringify(this.appservices.getSession("booking_details", true))
    );
  }

  ngOnInit(): void {
    this._instance = this.elementRef.nativeElement.querySelector(
      "lottie-player"
    );
  }

  ngOnDestroy() {}
}
