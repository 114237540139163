import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
// import { Router, ActivatedRoute } from '@angular/router';
import { Services } from "../../app/helpers/Services";

import { environment } from "../../../src/environments/environment";
import { DomainService } from "src/core/service/domain/domain.service";

declare var gtag;

@Component({
  selector: "app-oral-health-score-preference",
  templateUrl: "./oral-health-score-preference.component.html",
  styleUrls: ["./oral-health-score-preference.component.css"],
})
export class OralHealthScorePreferenceComponent implements OnInit {
  isSubmitted = false;
  testForm: FormGroup;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  testValue: any;
  bannerTitleText: any = "Select Preferences";
  user_details: any = "";
  user_email: any = "";
  defaultImage: string = "assets/images/loader_large.gif";

  staticImage1: string = "assets/images/ohr.png";
  staticImage2: string = "assets/images/dms.png";

  header_display_msg: any = "";

  existing_user: any = "";

  bypass_select_service: any;
  widget_service_type: any;
  isDomainAus;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private domainService: DomainService
  ) {
    this.isDomainAus = this.domainService.isAusRegionDomain("");
    this.appservices = new Services();
  }
  ngOnInit(): void {
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate(["get-started"]);
    }
    this.testForm = this.formBuilder.group({
      test: ["", [Validators.required]],
    });

    if (localStorage.getItem("Input_form_data") != "") {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.user_email = this.user_details.email;
    }

    if (this.appservices.getSession("existing_user", false) === "yes") {
      this.existing_user = "yes";
    }
    if (this.appservices.getSession("existing_user", false) === "no") {
      this.existing_user = "no";
    }

    this.appservices.setSession("existing_user", "", false);

    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
  }
  get myForm() {
    return this.testForm.get("test");
  }

  selectTestType(e, type) {
    this.testValue = type;
    this.bannerTitleText =
      this.testValue == "DMS" ? "Design My Smile" : "Oral Health Rating";
    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
    if (this.testValue == "DMS") {
      this.router.navigate(["upload-smile-photo"]);
    } else {
      if (this.isDomainAus) {
        this.router.navigate(["upload-teeth-images"]);
      } else {
        this.router.navigate(["upload-teeth-photos"]);
      }
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.testForm.valid) {
      return false;
    } else {
      this.testValue = this.testForm.get("test").value;
      this.bannerTitleText =
        this.testValue == "smile_design"
          ? "Design My Smile"
          : "Oral Health Rating";
      this.appservices.setSession(
        "bannerTitleText",
        this.bannerTitleText,
        false
      );
      if (this.testValue == "smile_design") {
        this.router.navigate(["upload-smile-photo"]);
      } else {
        if (this.isDomainAus) {
          this.router.navigate(["upload-teeth-images"]);
        } else {
          this.router.navigate(["upload-teeth-photos"]);
        }
      }
    }
  }
}
