<div class="dynamic_wrapper">
    <div class="inner_dynamic_sec text-center">
        <div class="smilo_img">
            <img src="assets/images/smilo_logo.png" alt="smilo" class="powered_by_con ">
        </div>
        <div class="pr-5 pl-5">
            <div class="img_section text-center position-relative mt-5">
                <div class="error_img_sec">
                    <img src="assets/images/error_logo.png" alt="score_img" />
                </div>
            </div>
            <div class="content_container">
                <h3 class="font-weight-bold text-center">Invalid Access Token</h3>
                <p class="text-center mt-2">Failed to get the access, please contact our admin to get access token</p>
            </div>
            <div class="button_section">
                <div class="text-center mt-4">
                    <button class="btn button_links btn_width_1" [routerLink]="['/error']">Contact us</button>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>