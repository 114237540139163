<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div class="loading_div analysing_sec">
                <div class="img_section text-center position-relative">
                    <div class="analyzing_text">
                        <div class="title_sec mt-4">
                            <h4 class="thank_you_head"><b>Thank you!</b></h4>
                        </div>

                    </div>
                    <div class="loader_img_sec">
                        <img src="assets/images/loader.gif" />
                    </div>
                    <div class="analyzing_text mb-4">

                        <p>Please wait while we uploading your photos</p>
                    </div>
                </div>
            </div>
            <div class="sec_inner_content1 p-0">

                <div id="steps">
                    <div id="circles" class="circle-steps">
                        <div data-num="1" id="num1"></div>
                        <div data-num="2" class="disabled" id="num2"></div>
                        <div data-num="3" class="disabled" id="num3"></div>
                    </div>
                </div>
                <form autocomplete="off" [formGroup]="InputForm" (ngSubmit)="onSubmit()">
                    <div class="row main_row">
                        <div class="col-lg-4 col-md-12 col-sm-12 text-center col_1">
                            <h3 class="sub_head mb-4 media_head">Upload at least <br> 1 photo of <br> your teeth</h3>
                            <h3 class="sub_head mb-4 responsive_head">Upload at least 1 photo of your teeth</h3>
                            <div class="grey_color">
                                <p class="mb-2 font_14">Our team will analyse the photos and give you an advise based on
                                    the images uploaded.</p>
                            </div>
                            <div class="select_btn apply_btn text-center media_button">
                                <button disabled
                                    class="upload-teeth-continue-btn btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span
                                        class="save_continue_btn_span">Upload Photos</span><span
                                        class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12 col-sm-12 col_2">
                            <div class="img_section">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 p-2"
                                        *ngFor="let data of responseData let i = index" id="{{i}}">
                                        <div class="teeth_col position-relative" class="upload_inner_section{{i}}">
                                            <div *ngIf="data.base64==''">
                                                <div class="white_bg p-2">

                                                    <div
                                                        class="d-flex justify-content-between align-items-center font_14">
                                                        <p class="blue_color font_10">{{data.title}}</p>
                                                        <span class="icon-information grey_color cursor_pointer"
                                                            (click)="openViewModal(template2,i)"></span>
                                                    </div>
                                                    <p class="grey_color font_10">{{data.content}}</p>
                                                </div>
                                                <div>
                                                    <div class="position-relative teeth_images">
                                                        <img [defaultImage]="defaultImage" [lazyLoad]="data.url"
                                                            class="score_one" [errorImage]="defaultImage">
                                                        <span class="icon-info1 custom_style_icon cursor_pointer"
                                                            (click)="openViewModal(template2,i)" id="elementID"></span>
                                                    </div>
                                                </div>
                                                <div class="overlay1"></div>
                                                <div class="camera_sec">
                                                    <span class="icon-camera icon_camera blue_color"></span>
                                                </div>
                                            </div>
                                            <div id="overlay1{{i}}"></div>
                                            <span class="upload_preview_span{{i}}"></span>
                                            <p class="please_wait_msg{{i}}"></p>
                                            <div class="avatar-preview cropping avatar_preview{{i}}"
                                                *ngIf="data.base64!=''">
                                                <img src="{{data.base64}}" [ngClass]="{'base64Image': i != '5'}"
                                                    id=uploadImage{{i}}>
                                                <label for="image_upload{{i}}" class="icon_edit"><i
                                                        class="icon-edit11 font_16"></i></label>
                                            </div>
                                            <label class="teeth_img_sec  image_upload{{i}} cursor_pointer"
                                                for="image_upload{{i}}"></label>
                                        </div>

                                        <div class="file_upload">
                                            <input type="file" formControlName="file{{i}}" accept="image/*" class="file"
                                                (change)="openCropperDialog($event,i,data.name)" id="image_upload{{i}}"
                                                hidden />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="select_btn apply_btn text-center responsive_button">
                                <button disabled
                                    class="upload-teeth-continue-btn btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span
                                        class="save_continue_btn_span">Upload Photos</span><span
                                        class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<ng-template #template2>
    <div class="modal-body">
        <div class="model_section p-4">
            <div class="cancel_button_sec text-right mt-4">

                <button type="button" class="close close_button" (click)="closeModal()"><span>×</span></button>
                <img src="{{displayImage}}">
                <h5 class="text-center mt-3">{{displayTitle}}</h5>
            </div>
        </div>
    </div>
</ng-template>