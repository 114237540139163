<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div class="loading_div analysing_sec">
                <div class="img_section text-center position-relative">
                    <div class="analyzing_text">
                        <div class="title_sec mt-4">
                            <h4 class="thank_you_head"><b>Thank you!</b></h4>
                        </div>
                    </div>
                    <div class="loader_img_sec">
                        <img src="assets/images/loader.gif" />
                    </div>
                    <div class="analyzing_text mb-4">
                        <p>Smilo.ai is analysing the pictures you have submitted</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showLoadingForm">
                <div class="img_section text-center position-relative">
                    <div class="analyzing_text">
                        <div class="title_sec mt-4">
                            <h4 class="thank_you_head"><b>Thank you!</b></h4>
                        </div>
                    </div>
                    <div class="loader_img_sec">
                        <img src="assets/images/loader.gif" />
                    </div>
                    <div class="analyzing_text mb-4">
                        <p>{{analyzingText}}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 showContactForm" *ngIf="showContactForm">
                <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form"
                    (ngSubmit)="onContactFormSubmit()">
                    <h3 class="sub_head contact-info-sub-head mb-3">Please enter your email address so we can send you
                        your personalised report</h3>
                    <div class="form-group position-relative min_height">
                        <input (keyup)="onEnterData($event)" type="text" formControlName="firstName" maxlength="31"
                            class="first_name form-control" (keyup)="nameLength('first_name')" placeholder="First Name"
                            [ngClass]="{ 'is-invalid': isSubmitted && ci.firstName.errors }" trim="blur"
                            value="{{ firstName | titlecase }}" appInputRestriction />
                        <div *ngIf="isSubmitted && ci.firstName.errors" class="invalid-feedback">
                            <div *ngIf="ci.firstName.errors.required" class="text-left">First name is required</div>
                            <div *ngIf="ci.firstName.errors.minlength" class="text-left">
                                First Name should contain at least 3 characters.
                            </div>
                        </div>
                    </div>
                    <div class="form-group position-relative min_height">
                        <input (keyup)="onEnterData($event)" type="text" formControlName="lastName" maxlength="31"
                            class="last_name form-control" (keyup)="nameLength('last_name')" placeholder="Last Name"
                            [ngClass]="{ 'is-invalid': isSubmitted && ci.lastName.errors }" trim="blur"
                            value="{{ lastName | titlecase }}" appInputRestriction />
                        <div *ngIf="isSubmitted && ci.lastName.errors" class="invalid-feedback">
                            <div *ngIf="ci.lastName.errors.required" class="text-left">Last name is required</div>
                            <div *ngIf="ci.lastName.errors.minlength" class="text-left">
                                Last Name should contain at least 3 characters.
                            </div>
                        </div>
                    </div>
                    <div class="form-group position-relative">
                        <input (keyup)="onEnterData($event)" maxlength="50" type="email" formControlName="email"
                            class="form-control name_holder" placeholder="Email Address"
                            [ngClass]="{ 'is-invalid': isSubmitted && ci.email.errors }" trim="blur" />
                        <div *ngIf="isSubmitted && ci.email.errors" class="invalid-feedback">
                            <div *ngIf="ci.email.errors.required" class="text-left">Email Address is required</div>
                            <div *ngIf="ci.email.errors.pattern" class="text-left">Email Address is invalid.</div>
                        </div>
                    </div>
                    <div class="form-group position-relative">
                        <input (keyup)="onEnterData($event)" type="tel" (keydown.space)="$event.preventDefault();"
                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            formControlName="phoneNumber" maxlength="15" (countryChange)="countryChange($event)"
                            (intlTelInputObject)="telInputObject($event)" placeholder="Mobile Number (Optional)"
                            ng2TelInput class="phone-format form-control" id="phoneNumber"
                            [ngClass]="{ 'is-invalid': isSubmitted && ci.phoneNumber.errors }" trim="blur">
                        <div *ngIf="isSubmitted && ci.phoneNumber.errors" class="invalid-feedback">
                            <div *ngIf="ci.phoneNumber.errors.required" class="text-left">Required</div>
                        </div>
                    </div>
                    <div *ngIf="display_branches_div" class="form-group position-relative">
                        <span class="practice-location-span"> Dental Practice near to your location </span>
                        <select name="userSelectedPractice" id="userSelectedPractice" class="form-control"
                            (change)="onSelectPractice($event)">
                            <option value="">Please select any branch</option>
                            <option value="" [selected]="true">Global Branch</option>
                            <option *ngFor="let method of practiceBranches" [attr.data-isocode]="method.address"
                                [value]="method.id" [selected]="method.id == preSelectedBranch">{{ method.name }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-35">
                        <div class="d-flex justify-content-end flex_start">
                            <button [disabled]="contactFormBtn" type="submit"
                                class="my-4 btn btn_width_2 button_links coninue_btn btn_color mx-0"><span
                                    class="save_continue_btn_span">Next</span><span
                                    class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="sec_inner_content1 p-0">
                <div id="steps">
                    <div id="circles" class="circle-steps">
                        <div data-num="1" id="num1"></div>
                        <div data-num="2" class="disabled" id="num2"></div>
                        <div data-num="3" class="disabled" id="num3"></div>
                    </div>
                </div>
                <form autocomplete="off" [formGroup]="uploadImageInputForm" (ngSubmit)="onSubmit()">
                    <div class="row main_row m-0">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center col_1">
                            <h3 class="sub_head mb-2 media_head">Please upload at least 3 pictures of your teeth</h3>
                            <h3 class="sub_head mb-2 responsive_head">Please upload at least 3 pictures of your teeth
                            </h3>
                            <div class="grey_color">
                                <p class="mb-2 font_14">Our AI engine will analyse your picture and give you
                                    personalised insights based on the input.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row  mt-3 position-relative">
                        <div class="error_message_row  position-absolute">
                            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 errorImageDiv display_none">
                                <div
                                    class="error mt-3 font_14 checkbox_rounded d-flex align-items-center justify-content-center">
                                    <div class="text-left">Please upload your picture</div>
                                </div>
                            </div>
                        </div>

                        <div class="prev_next_row row mt-md-3 mt-lg-4 position-absolute">
                            <div class="col-4 col-sm-6 col-md-6 col-lg-6 prev_next_div" [ngClass]="displayPreviousBtn">
                                <a href="javascript:;" disabled (click)="moveCarousel('left')"
                                    class="float-right prev-btn disabled"> <span
                                        class="icon_arrow icon-left-arrow font_12"></span><span
                                        class="ml-2"><u>Previous</u></span> </a>
                            </div>
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 save_button_div"
                                (click)="checkImageUploaded()">
                                <button disabled
                                    class="upload-teeth-continue-btn btn button_links btn_width_3 coninue_btn btn_color mx-0 justify-content-center align-items-center">
                                    <span class="save_continue_btn_span">Save & Continue</span><span
                                        class="icon_arrow icon-right-arrow ml-2 font_12"></span>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="image-container">
                                <ngx-slick-carousel class="teeth_carousel upload_teeth_carousel"
                                    #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                                    <div ngxSlickItem *ngFor="let data of responseData; let i = index" class="slide">
                                        <div class="teeth_col position-relative" class="upload_inner_section{{i}}">
                                            <p class="text-center teeth_carousel_title font_16 my-2">
                                                <b>{{data.title}}</b>
                                            </p>
                                            <p class="text-center teeth_carousel_title font_11 my-2">{{data.content}}
                                            </p>
                                            <div id="pointer_link{{i}}" class="teeth_img teeth_hover mt-3">
                                                <div id="overlay1{{i}}"></div>
                                                <span class="upload_preview_span{{i}}"></span>
                                                <p class="please_wait_msg{{i}}"></p>
                                                <div class="avatar-preview cropping avatar_preview{{i}}"
                                                    *ngIf="data.base64!=''">
                                                    <img src="{{data.base64}}" [ngClass]="{'base64Image': i != '5'}"
                                                        id=uploadImage{{i}}>
                                                    <label for="image_upload{{i}}" class="icon_edit">
                                                        <i class="icon-edit11 font_16"></i>
                                                    </label>
                                                </div>

                                                <img *ngIf="data.url" (load)="updateImgAfterLoad()"
                                                    [ngClass]="applyStyle" [defaultImage]="defaultImage"
                                                    [lazyLoad]="data.url" class="score_one img_{{i}} opac"
                                                    [errorImage]="defaultImage">
                                                <img *ngIf="!data.url" (load)="updateImgAfterLoad()"
                                                    [ngClass]="applyStyle" [defaultImage]="defaultImage"
                                                    [lazyLoad]="data.url" class="score_one img_{{i}} opac"
                                                    [errorImage]="defaultImage">
                                                <div class="upload_image_div" id="upload_image_div{{i}}">
                                                    <label
                                                        class=" mt-5 align-items-center image_upload{{i}} upload_here_btn cursor_pointer"
                                                        for="image_upload{{i}}">
                                                        <img class="mb-2 mt-3 upload_image_icon"
                                                            src="assets/images/upload-icon-Blue.png">
                                                        <span class="d-block click-here-span">Click here to upload your
                                                            picture</span></label>
                                                    <div class="file_upload mt-3">
                                                        <input type="file" formControlName="file{{i}}" accept="image/*"
                                                            class="file"
                                                            (change)="openCropperDialog($event,i,data.name)"
                                                            id="image_upload{{i}}" hidden />
                                                    </div>
                                                </div>
                                                <div class="after_image_upload_div display_none"
                                                    id="after_image_upload_div{{i}}">
                                                    <span class="after_image_upload_span"> </span>
                                                </div>
                                            </div>
                                            <div class="after_image_upload_text_div"
                                                id="after_image_upload_text_div{{i}}"> </div>
                                        </div>
                                    </div>
                                </ngx-slick-carousel>
                                <div class="upload-img-btn">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #template2>
    <div class="modal-body">
        <div class="model_section p-4">
            <div class="cancel_button_sec text-right mt-4">
                <button type="button" class="close close_button" (click)="closeModal()"><span>×</span></button>
                <img src="{{displayImage}}">
                <h5 class="text-center mt-3">{{displayTitle}}</h5>
            </div>
        </div>
    </div>
</ng-template>