// import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, OnInit,AfterViewInit, TemplateRef, HostListener } from '@angular/core';
import { Component, ChangeDetectorRef, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Services } from '../helpers/Services';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialogComponent } from '../global_components/cropper-dialog/cropper-dialog.component';
import * as $ from 'jquery';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { MessageService } from '../profile-pic-service.service';
import Swal from 'sweetalert2';
import { Md5 } from 'ts-md5/dist/md5';
import { TitleCasePipe } from '@angular/common';
import { Location } from '@angular/common';
const md5 = new Md5();

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: [TitleCasePipe]
})

export class UserProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('myProfile', { static: false }) myProfile: ElementRef;
  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  isSubmitted = false;
  changePasswordForm: FormGroup;
  profile_status: any = 'active';
  // change_password_status: any = '';
  user_details: any = '';
  firstName: any = '';
  lastName: any = '';
  email: any = '';
  countryList: any = '';
  appservices: Services;
  selectedCountry: any = '';
  stateList: any = '';
  selectedState: any = '';
  // cityList: any = '';
  selectedCity: any = '';
  country_name: any = '';
  countryCodeNumber: any = '';
  phoneNumber: any = '';
  user_id: any = '';
  apirequest: any = '';
  input: any = '';
  deleted_msg_class: string = 'display_none';
  isButtonDisabled: boolean = false;
  updateProfileForm: FormGroup;
  isUpdateProfileButtonDisabled: boolean = false;
  // currentPasswordError: boolean = true;
  passwordUpdatedSuccessfully: string = 'display_none';
  passwordUpdatedError: string = 'display_none';
  passwordUpdatedErrorMsg: string = '';
  profileUpdatedSuccessfully: string = 'display_none';
  profileUpdatedError: string = 'display_none';
  profileUpdatedErrorMsg: string = '';
  user_selected_country: any = '';
  user_selected_state: any = '';
  cropped_url_blob: Blob;
  folder: string = '';
  file_name: string = '';
  todaysDataTime: any = '';
  currentDate: any = '';
  profile_img: any = 'assets/images/default-profile.png';
  profile_img_complete_path: string = '';
  tempProfilePic: any = '';
  sendSubjectMessage: any = {};
  userPassword: any = '';
  isPasswordFormSubmitted: any = false;
  signInValidNumber: any = true;
  phoneSignIn: any = '';
  signInPhoneNumber: any = '';
  signInSelectedCountryCode: any = '';
  signInSelectedCountry: any = '';
  isUserLoggedIn: any = '';

  constructor( private _dialog: LyDialog, 
    private _cd: ChangeDetectorRef, 
    private router: Router, 
    private httpClient: HttpClient, 
    private fb: FormBuilder, 
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe, 
    private _location: Location ) {
    this.appservices = new Services;
  }

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.appservices.setSession("bannerTitleText", "User Profile", false);
    this.isUserLoggedIn = this.appservices.getSession("isUserLoggedIn", false);
    if (this.isUserLoggedIn != 'true') {
      this.router.navigate(['login']);
    }

    this.firstName = this.appservices.getSession("first_name", false);
    this.lastName = this.appservices.getSession("last_name", false);
    this.email = this.appservices.getSession("email", false);
    this.phoneNumber = this.appservices.getSession("phoneNumber", false);
    this.user_selected_country = this.appservices.getSession("country_id", false);
    this.user_selected_state = this.appservices.getSession("state_id", false);
    this.selectedCity = this.appservices.getSession("city", false);
    if (this.appservices.getSession("profile_picture", false) != '') {
      this.profile_img = this.appservices.getSession("profile_picture", false);
    }

    this.updateProfileForm = this.fb.group({
      first_name: [this.firstName, [Validators.required, Validators.minLength(3), Validators.pattern(this.NoWhitespaceRegExp)]],
      last_name: [this.lastName, [Validators.required, Validators.minLength(1), Validators.pattern(this.NoWhitespaceRegExp)]],
      email: [this.email, [Validators.required]],
      // phoneNumber: [this.phoneNumber, [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
      phoneNumber: [this.phoneNumber, []],
      userSelectedCountry: [this.user_selected_country, [Validators.required]],
      userSelectedState: [this.user_selected_state, [Validators.required]],
      city: [this.selectedCity, [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.NoWhitespaceRegExp)]],
      profile_image: [this.profile_img]
    });

    this.currentDate = new Date();
    this.todaysDataTime = formatDate(this.currentDate, 'yyyyMMddhhmmss', 'en-US');

    this.changePasswordForm = this.fb.group({
      current_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password: ['', [Validators.required, Validators.minLength(6), checkPasswordValidation]],
      confirm_password: ['', [Validators.required, Validators.minLength(6), MatchPassword]]
    });

    this.user_selected_country = this.appservices.getSession("country_id", false);
    this.user_selected_state = this.appservices.getSession("state_id", false);
    this.selectedCity = this.appservices.getSession("city", false);
    this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?", '', 'get', this, '127.0.0.1').subscribe(
      (data: any) => {
        if (data.status && data.status_code === 1) {
          this.countryList = data.result.country_state_records;

          if (this.user_selected_country != '') {
            this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?country_id=" + this.user_selected_country, '', 'get', this, '127.0.0.1').subscribe(
              (data: any) => {
                if (data.status && data.status_code === 1) {
                  this.stateList = data.result.country_state_records;
                }
              });
          }
        }
      });
  }

  // selectedTab(type) {
  // }

  onSelectCountry(selectedCountry) {
    this.selectedCountry = selectedCountry.target.value;
    this.user_selected_state = '';
    this.updateProfileForm.controls['userSelectedState'].setValue('');

    if (this.selectedCountry != '') {
      this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?country_id=" + this.selectedCountry, '', 'get', this, '127.0.0.1').subscribe(
        (data: any) => {
          if (data.status && data.status_code === 1) {
            this.stateList = data.result.country_state_records;
          }
        });
    }
    else {
      this.stateList = [];
    }
  }

  onSelectState(selectedState) {
    this.selectedState = selectedState.target.value;
    if (this.selectedState != '') {
      this.user_selected_state = this.selectedState;
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get ci() { return this.updateProfileForm.controls; }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  countryChange(country: any) {
    this.country_name = country.iso2;
    this.countryCodeNumber = country.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.setValidators();
  }

  telInputObject(obj) {
    if (this.appservices.hasSession("country_name")) {
      this.country_name = this.appservices.getSession("country_name", false);
      obj.setCountry(this.country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
  }

  setValidators() {
    if (this.country_name == 'au') {
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/)]);
      this.updateProfileForm.get('phoneNumber').updateValueAndValidity();
    }
    else {
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.updateProfileForm.get('phoneNumber').updateValueAndValidity();
    }
  }

  onEnterData(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    if (nameType === 'phoneNumber') {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
      this.isSubmitted = false;
    }
  }

  onUpdateProfileEnterData(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    if (nameType === 'first_name') {
      this.firstName = nameVal;
    }
    else if (nameType === 'last_name') {
      this.lastName = nameVal;
    }
    else if (nameType === 'city') {
      this.selectedCity = nameVal;
    }
    else if (nameType === 'phoneNumber') {
      this.updateProfileForm.controls['phoneNumber'].setValue(nameVal);
    }
    if (nameType === 'userSelectedCountry') {
      this.user_selected_state = '';
    }
    if (this.updateProfileForm.valid) {
      //  this.isUpdateProfileButtonDisabled = false;
    }
    else {
      //  this.isUpdateProfileButtonDisabled = true;
    }
  }

  validateInput(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    nameVal = (nameVal).trim();
    if (nameVal.length > 0) {
      nameVal = (this.titleCasePipe.transform(nameVal)).trim();
    }
    this.updateProfileForm.controls[nameType].setValue(nameVal);
  }

  deleteMyAccount() {
    Swal.fire({
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    this.user_id = this.appservices.getSession("user_id", false);
    this.user_details = (localStorage.getItem('Input_form_data'));
    if (this.user_details != '') {
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.email = this.user_details.email;
    }
    if (!this.appservices.isEmpty(this.user_id) && !this.appservices.isEmpty(this.email)) {
      this.apirequest = [];
      this.apirequest["user_id"] = this.user_id;
      this.apirequest["email"] = this.email;
      this.apirequest["password"] = Md5.hashStr(this.userPassword); // md5.appendStr(this.userPassword);
      this.input = this.appservices.generteJSONFromArray(this.apirequest);

      this.appservices.callJSONAPI(this.appservices.DELETE_USER_ACCOUNT, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          if (data.status && data.status_code === 1) {
            this.appservices.setSession("city", '', false);
            this.appservices.setSession("state_id", '', false);
            this.appservices.setSession("country_id", '', false);
            $('.continue_btn').attr('disabled', true);
            this.deleted_msg_class = 'display_block';
            Swal.hideLoading();
            Swal.close();
            setTimeout(() => {                         // <<<---using ()=> syntax
              this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
            }, 1500);
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              showCloseButton: true,
              text: data.message
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.deleteUserAlert();
              }
            });
          }
        });
    }
  }

  onSubmit(form: FormGroup, formType: any) {
    this.passwordUpdatedSuccessfully = 'display_none';
    this.passwordUpdatedError = 'display_none';
    this.profileUpdatedSuccessfully = 'display_none';
    this.profileUpdatedError = 'display_none';

    if (formType === 'changePasswordForm') {
      this.isPasswordFormSubmitted = true;
    }
    else {
      this.isSubmitted = true;
    }

    const controls = this.changePasswordForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {

      }
    }

    if (this.changePasswordForm.valid && formType === 'changePasswordForm') {
      this.apirequest = [];
      this.apirequest["old_password"] = Md5.hashStr(form.value.current_password);
      this.apirequest["password"] = Md5.hashStr(form.value.new_password);
      this.apirequest["confirm_password"] = Md5.hashStr(form.value.confirm_password);
      this.input = this.appservices.generteJSONFromArray(this.apirequest);


      $('#save_change_pwd_btn').val('Please wait...').attr('disabled', true);

      this.appservices.callJSONAPI(this.appservices.CHANGE_PASSWORD, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          this.changePasswordForm.reset();
          this.isPasswordFormSubmitted = false;
          $('#save_change_pwd_btn').val('Change password').attr('disabled', false);
          if (data.status && data.status_code === 1) {
            this.passwordUpdatedSuccessfully = 'display_block';
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.passwordUpdatedSuccessfully = 'display_none';
              this.appservices.setSession("isUserLoggedIn", "false", false);
              this.router.navigate(['oral-health-score-preference']);
            }, 2000);
          }
          else {
            this.passwordUpdatedError = 'display_block';
            this.passwordUpdatedErrorMsg = data.message;
            setTimeout(() => {
              this.passwordUpdatedError = 'display_none';
            }, 5000);
          }
        });
    }

    if (this.updateProfileForm.valid && formType === 'updateProfileForm') {
      $('.continue_btn').val('Please wait...').attr('disabled', true);
      localStorage.setItem('Input_form_data', JSON.stringify(this.updateProfileForm.value));
      form.value.first_name = this.titleCasePipe.transform(form.value.first_name);
      form.value.last_name = this.titleCasePipe.transform(form.value.last_name);

      this.apirequest = [];
      this.apirequest["first_name"] = form.value.first_name;
      this.apirequest["last_name"] = form.value.last_name;
      this.apirequest["email"] = form.value.email;
      this.apirequest["country_id"] = form.value.userSelectedCountry;
      this.apirequest["state_id"] = form.value.userSelectedState;
      this.apirequest["city"] = form.value.city;
      this.apirequest["profile_image"] = this.profile_img_complete_path;
      this.apirequest["dial_code"] = this.appservices.getSession("dial_code", false);
      this.apirequest["contact_number"] = form.value.phoneNumber;
      this.apirequest["dial_country_code"] = this.country_name;
      this.input = this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices.callJSONAPI(this.appservices.UPDATE_WIDGET_PROFILE, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          $('.continue_btn').val('Save').attr('disabled', false);
          if (data.status && data.status_code === 1) {
            this.appservices.setSession("first_name", form.value.first_name, false);
            this.appservices.setSession("last_name", form.value.last_name, false);
            this.appservices.setSession("email", form.value.email, false);
            this.appservices.setSession("country_id", form.value.userSelectedCountry, false);
            this.appservices.setSession("state_id", form.value.userSelectedState, false);
            this.appservices.setSession("city", form.value.city, false);
            this.sendSubjectMessage = { first_name: form.value.first_name, last_name: form.value.last_name, text: this.profile_img };
            if (this.profile_img_complete_path != '') {
              this.sendSubjectMessage['text'] = environment.S3_BASE_URL + this.profile_img_complete_path;
              this.appservices.setSession("profile_picture", environment.S3_BASE_URL + this.profile_img_complete_path, false);
            }
            this.messageService.sendMessage(this.sendSubjectMessage);
            this.appservices.setSession("phoneNumber", form.value.phoneNumber, false);
            this.profileUpdatedSuccessfully = 'display_block';
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.profileUpdatedSuccessfully = 'display_none';
            }, 5000);
          }
          else {
            this.profileUpdatedError = 'display_block';
            this.profileUpdatedErrorMsg = data.message;
          }
        });
    }
  }

  get current_password() { return this.changePasswordForm.get('current_password'); }

  get new_password() { return this.changePasswordForm.get('new_password'); }

  get confirm_password() { return this.changePasswordForm.get('confirm_password'); }

  openCropperDialog(event: Event) {
    const target = event.target as HTMLInputElement;
    var isValid = true;
    if (target.files && target.files.length > 0) {
      if (target.files[0].name != '') {
        var filename = target.files[0].name;
        var fileExt = filename.split('.').pop();
        var imgArray: Array<string> = ['jpg', 'jpeg', 'png'];
        var isValidImage: any = imgArray.filter(name => name.includes(fileExt));
        if (isValidImage === '') {
          isValid = false;
        }
      }
    }

    if (!isValid) return false;
    $("body").addClass("modal-open");
    this.appservices.serviceMessages('vs-messages', '', '');
    let user_id = this.appservices.getSession("user_id", false);
    this._dialog.open<CropperDialogComponent, Event>(CropperDialogComponent, {
      data: event,
      width: 320,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {

      $("body").removeClass("modal-open");
      if (result) {
        $('.continue_btn').html('Please wait...').attr('disabled', true);
        $('#overlay1').addClass('overlay');
        $('.upload_preview_span').addClass("spinner-border");
        $('.please_wait_msg').html('Please wait...');
        this.profile_img = result.dataURL;
        this.cropped_url_blob = this.appservices.convertDataUrlToBlob(result.dataURL);
        let fileExt = result.dataURL.split(';')[0].split('/')[1];
        this.folder = 'user_profile_images/';
        this.file_name = user_id + '/image_' + this.todaysDataTime + "." + fileExt;
        this.profile_img_complete_path = this.folder + this.file_name;
        let thisobject = this;
        let greetingPromise = this.appservices.uploadToS3(this.cropped_url_blob, this.file_name, this.folder, fileExt);
        greetingPromise.then(function (resolveOutput) {
          $('#overlay1').removeClass('overlay');
          $('.upload_preview_span').removeClass("spinner-border");
          $('.please_wait_msg').html('');
          $('.continue_btn').html('Continue').attr('disabled', false);
        }, function (rejectOutput) {
        });
        this.updateProfileForm.patchValue({
          profile_image: result.dataURL
        });
        this._cd.markForCheck();
      }
    });
  }

  pushImageDataInToArray(location) {
    if (this.updateProfileForm.valid) {
      this.isUpdateProfileButtonDisabled = false;
    }
    else {
      this.isUpdateProfileButtonDisabled = true;
    }
  }

  deleteUserAlert() {
    Swal.fire({
      title: "Delete My Account",
      text: "Please enter your Password to delete your account",
      input: 'password',
      allowOutsideClick: true,
      showCloseButton: true,
      inputAutoTrim: true,
      inputValidator: (value) => {
        if ((value).length > 20) {
          return 'You cannot enter more than 20 characters';
        }
        else if (!value) {
          return 'Please enter the Password';
        }
      }
    }).then((result) => {
      if (result.value) {
        this.userPassword = result.value;
        this.deleteMyAccount();
      }
      else if (result.isConfirmed) {
        Swal.fire('Password required', 'please enter the password', 'error');
      }
    });

  }

  tabChange(currentTab) {
    if (currentTab == 'password') {
      this.profileUpdatedSuccessfully = 'display_none';
      this.profileUpdatedError = 'display_none';
      this.isPasswordFormSubmitted = false;
    }
    else {
      this.passwordUpdatedSuccessfully = 'display_none';
      this.passwordUpdatedError = 'display_none';
      this.isSubmitted = false;
    }
  }

  /* checkPasswordValidation()
  {
    let password = this.changePasswordForm.get('new_password').value;
    var check = true;
    var regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
    if (regExp.test(password)) {
      check = false;
    }
  } */

  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }

  signInHasError(status: any) {
    if (status == null) {
      this.signInValidNumber = false;
      this.phoneSignIn.setValue(null);
    } else {
      this.signInValidNumber = status;
    }
  };

  signInGetNumber(n: string) {
    this.signInPhoneNumber = n;
  };

  signInOnCountryChange(c: any) {
    this.signInSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.signInSelectedCountry = up.toUpperCase();
    this.country_name = c.iso2;
    this.countryCodeNumber = c.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.isSubmitted = false;
    // this.setValidators();
  };

  onBackToHome() {
    this._location.back();
  }
}

function MatchPassword(control: AbstractControl) {
  let parent = control.parent;
  if (parent) {
    let password = parent.get('new_password').value;
    let confirmPassword = control.value;
    if (password != confirmPassword) {
      return { ConfirmPassword: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function checkPasswordValidation(control: AbstractControl) {
  let parent = control.parent;
  if (parent) {
    let password = control.value;
    let currentPassword = parent.get('current_password').value;
    let confirmPassword = parent.get('confirm_password').value;
    if (currentPassword === password) {
      return { currentPasswordValidation: true };
    }

    var regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
    if (regExp.test(password)) {
      return null;
    }
    else {
      if (password !== confirmPassword && confirmPassword !== '') {
        return { passwordValidation: true, ConfirmPassword: true };
      } else {
        return { passwordValidation: true };
      }
    }
  } else {
    return null;
  }
}

$('body').on('cut copy paste', '.swal2-input', function (e) {
  // do something   
  e.preventDefault();
});