<div class="modal-header border-bottom-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <i class="sh-cancel"></i>
  </button>
</div>
<div class="modal-body">
  <div class="report-modal-wrapper">
    <div class="report-modal-header text-center">
      <h2 class="mb-1 mt-1 pt-lg-2">OTP Verification</h2>
      <p class="mb-3 pb-lg-1">
        Successfully sent the OTP to your<br />
        Registered Mobile Number
      </p>
      <form class="otp-form" autocomplete="off">
        <div
          class="otp-form-group d-flex justify-content-center mt-3 mb-4 py-1"
        >
          <div class="form-group mb-0 otp-input">
            <input
              type="text"
              [(ngModel)]="otp[0]"
              (input)="onInput($event, 0)"
              maxlength="1"
              class="form-control"
              (paste)="onPaste($event)"
              name="one"
              appOnlyNumbers
            />
          </div>
          <div class="form-group mb-0 otp-input">
            <input
              type="text"
              [(ngModel)]="otp[1]"
              (input)="onInput($event, 1)"
              maxlength="1"
              class="form-control"
              (paste)="onPaste($event)"
              name="two"
              appOnlyNumbers
            />
          </div>
          <div class="form-group mb-0 otp-input">
            <input
              type="text"
              [(ngModel)]="otp[2]"
              (input)="onInput($event, 2)"
              maxlength="1"
              class="form-control"
              (paste)="onPaste($event)"
              name="three"
              appOnlyNumbers
            />
          </div>
          <div class="form-group mb-0 otp-input">
            <input
              type="text"
              [(ngModel)]="otp[3]"
              (input)="onInput($event, 3)"
              maxlength="1"
              class="form-control"
              (paste)="onPaste($event)"
              name="four"
              appOnlyNumbers
            />
          </div>
        </div>
        <p>
          <a href="javascript:void(0)" (click)="onResendCode()"
            ><i class="sh-resend"></i> Resend OTP</a
          >
          in
          {{ timer | formatTime }}
        </p>
        <div class="error-text" *ngIf="isCorrectOtp">{{ errorMessage }}</div>
      </form>
      <div class="button-group text-center mt-4 pt-1 pt-sm-3 mb-4 mb-sm-0">
        <button
          type="button"
          class="btn btn-primary btn-rounded-corner btn-lg"
          (click)="onOtpSubmit()"
          [disabled]="isInProcess"
        >
          {{ isInProcess ? "Please wait" : "Submit" }}
        </button>
      </div>
    </div>
  </div>
</div>
