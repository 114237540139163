<!-- Australia section starts -->
<div class="dynamic_wrapper" *ngIf="isDomainAus">
  <div class="vs-messages"></div>
  <div class="dms_wrapper">
    <app-back-to-home></app-back-to-home>
    <div class="dms_content">
      <div class="loader_sec">
        <div class="loading_div analysing_sec">
          <div class="img_section text-center position-relative">
            <div class="analyzing_text">
              <div class="title_sec mt-4">
              </div>
            </div>
            <div class="loader_img_sec">
              <img src="assets/images/loader.gif" />
            </div>
            <div class="analyzing_text mb-4">
              <p class="thank_you_head"><b>Please wait while the payment is in progress</b></p>
              <p>Please do not press back button or refresh button</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Australia section ends -->

<!-- India section starts -->
<div class="loader-section" *ngIf="!isDomainAus">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle fill="none" stroke="#f5821e" stroke-width="18" cx="100" cy="100" r="90" class="circle"
              stroke-linecap="round" transform="rotate(-90 100 100) " />
          </svg>
        </div>
        <div class="loading-arrow">
          <i class="sh-arrow-up"></i>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center">
          <b>Please wait while the payment is in progress.</b>
        </p>
      </div>
    </div>
  </div>
</div>
<!-- India section Ends -->