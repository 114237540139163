import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  NgbCalendar,
  NgbDate,
  NgbDatepicker,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;
@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"],
})
export class DatePickerComponent implements OnInit {
  @Input() selectDate: any = new Date();
  @Input() minDate = "";
  @Input() isBtnsRequired: boolean = false;
  model: NgbDateStruct;
  weekDates: NgbDateStruct[] = [];
  today: NgbDate = this.calendar.getToday();
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  onTouched: any;
  hoveredDate: NgbDateStruct;
  @ViewChild("btn", { static: true }) btn: ElementRef;
  // @Output() onChangeValue = new EventEmitter();
  @ViewChild("dp") datePicker: NgbDatepicker | undefined; // Access NgbDatepicker
  lastweek;

  constructor(
    private calendar: NgbCalendar,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.model = this.today;
    this.onDateSelection(this.today);
  }

  selectToday(event: any) {
    this.onButtonActive(event);
    this.model = this.calendar.getToday();
    let fromDate = new Date(
      this.today.year + "-" + this.today.month + "-" + this.today.day
    );

    this.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    this.toDate = null;
    this.hoveredDate = null;
    let obj = {
      startDate: this.fromDate,
      endDate: this.fromDate,
    };
    this.selectDate = obj;
  }

  selectTomorrow(event: any) {
    this.onButtonActive(event);

    let fromDate = new Date(
      this.today.year + "-" + this.today.month + "-" + (this.today.day + 1)
    );

    this.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    this.toDate = null;
    this.hoveredDate = null;
    let obj = {
      startDate: this.fromDate,
      endDate: this.fromDate,
    };
    this.selectDate = obj;
  }

  selectThisWeek(event: any) {
    this.onButtonActive(event);

    let fromDate = new Date(
      this.today.year + "-" + this.today.month + "-" + this.today.day
    );
    let time = fromDate.getDay() ? fromDate.getDay() - 1 : 6;
    fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);

    this.fromDate = this.today;
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.toDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    let obj = {
      startDate: this.fromDate,
      endDate: this.toDate,
    };
    this.selectDate = obj;
    this.hoveredDate = null;
    if (this.onTouched) this.onTouched();
  }

  selectNextWeek(event: any) {
    this.onButtonActive(event);
    let lastweek = new Date(
      this.today.year + "-" + this.today.month + "-" + this.today.day
    );
    let time = lastweek.getDay() ? lastweek.getDay() - 1 : 6;
    lastweek = new Date(lastweek.getTime() - time * 24 * 60 * 60 * 1000);
    this.lastweek = new NgbDate(
      lastweek.getFullYear(),
      lastweek.getMonth() + 1,
      lastweek.getDate()
    );
    // this.model=this.calendar.getNext(this.calendar.getToday(), 'd', 7)
    let nextWeek = this.calendar.getNext(this.lastweek, "d", 7);
    let fromDate = new Date(
      nextWeek.year + "-" + nextWeek.month + "-" + nextWeek.day
    );
    this.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.toDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    let obj = {
      startDate: this.fromDate,
      endDate: this.toDate,
    };
    this.selectDate = obj;
    this.hoveredDate = null;
  }

  isHovered = (date) => equals(date, this.hoveredDate);
  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = (date) => equals(date, this.fromDate);
  isTo = (date) => equals(date, this.toDate);

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date)
    );
  }

  onDateSelection(date: any) {
    this.hoveredDate = date;
    this.fromDate = null;
    this.toDate = null;
    let obj = {
      startDate: date,
      endDate: date,
    };
    this.selectDate = obj;
    const slotElements = this.el.nativeElement.querySelectorAll(".btn");
    slotElements.forEach((element: HTMLElement) => {
      this.renderer.removeClass(element, "active");
    });
    let today = this.today.year + "-" + this.today.month + "-" + this.today.day;
    let tomorrow =
      this.today.year + "-" + this.today.month + "-" + (this.today.day + 1);
    let selectedDate = date.year + "-" + date.month + "-" + date.day;
    if (selectedDate == today) {
      const divElement = this.el.nativeElement.querySelector("#btn1");
      if (divElement) {
        this.renderer.addClass(divElement, "active");
      }
    }
    if (selectedDate == tomorrow) {
      const divElement = this.el.nativeElement.querySelector("#btn2");
      if (divElement) {
        this.renderer.addClass(divElement, "active");
      }
    }
  }

  onButtonActive(event: any) {
    let selectedBtn = event.target || event.srcElement;
    let parentNode = selectedBtn.parentNode;

    let btnsList = parentNode.querySelectorAll("button");
    btnsList.forEach((button) => {
      if (button.classList.contains("active")) {
        button.classList.remove("active");
      }
    });

    if (selectedBtn.nodeName === "BUTTON") {
      if (selectedBtn.classList.contains("active")) {
        selectedBtn.classList.remove("active");
      } else selectedBtn.classList.add("active");
    }
  }
}
