<div class="container mt-5">
    <div class="d-flex justify-content-center">
        <div class="col-md-8">
            <webcam *ngIf="showWebcam" [trigger]="invokeObservable" (imageCapture)="captureImg($event)"
                (initError)="handleInitError($event)"></webcam>
            <div class="text-center web-cam-error" *ngIf="!showWebcam">
                <div style="width: 25px;" class="mb-1 mx-auto">
                    <img src="./assets/images/error_logo.png" width="100%" />
                </div>
                Something went wrong..!
            </div>
        </div>
    </div>

    <div class="col-md-12 text-center mt-4">
        <div class="btn btn-outline as ap az" [class.disabled]="!isCaptureBtnEnabled" (click)="getSnapshot()">
            <i class="fa fa-camera" style="font-size: 14px;margin-right: 5px;"></i>
            Capture Image
        </div>
    </div>

    <!-- <div class="col-12">
        <img [src]="webcamImage?.imageAsDataUrl" height="400px" />
    </div> -->
</div>