<div class="loader-section" *ngIf="showLoadingForm">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle" *ngIf="!isDataLoaded">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle fill="none" stroke="#f5821e" stroke-width="18" cx="100" cy="100" r="90" class="circle"
              stroke-linecap="round" transform="rotate(-90 100 100) " />
          </svg>
        </div>
        <div class="loading-arrow" *ngIf="!isDataLoaded">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline fill="none" stroke="#ffffff" stroke-width="50" points="88,214 173,284 304,138"
              stroke-linecap="round" stroke-linejoin="round" class="tick" />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center" [ngClass]="{ 'text-green': isDataLoaded === true }">
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="section_wrapper pt-0 position-relative" *ngIf="!showLoadingForm">
  <div class="section-banner_wrapper dentist-appointment position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
            <i class="sh-chevron-left"></i> <span>back</span>
          </a>
          <div
            class="banner_wrap d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between justify-content-lg-center">
            <div class="banner-content">
              <h1>
                Payment<br />
                Successful
              </h1>
              <h3>Your payment has been successfully processed!</h3>
              <p>
                You can now access your tailored
                {{ imageSelectionCount == 1 ? "smile check" : "oral health" }}
                report and<br />
                discover valuable insights about your dental well-being.
              </p>
              <div
                class="button-group d-flex align-items-center justify-content-center justify-content-lg-end mt-4 pt-lg-2">
                <button type="button" class="btn btn-outline-light btn-rounded-corner"
                  (click)="openModalDownloadReport()">
                  Download Report
                </button>
                <button type="button" class="btn btn-primary btn-rounded-corner" (click)="openModalViewReport()">
                  View Report
                </button>
              </div>
            </div>
            <div class="banner-image">
              <img src="assets/images/appointment-sent.png" class="img-fluid d-none d-lg-block" alt="" />
              <img src="assets/images/appointment-sent-mobile.png" class="img-fluid d-block d-lg-none" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-container">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">What's Next?</h3>
              <p>Discover our services to improve your Oral Health</p>
              <!-- <p>Discover our other services to enhance your experience</p> -->
            </div>
            <div class="next_wrapper">
              <app-appointment-card *ngIf="isRequestForAppointmentCard" class="next-card"
                (bookNowClick)="onBookNow()"></app-appointment-card>
              <app-product-discount-card *ngIf="isProductDiscountCard" class="next-card"
                (productDiscountClick)="onProductDiscountClick()"></app-product-discount-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>