<div class="dynamic_wrapper">
  <div class="loading_div analysing_sec sub_wrapper p-3" *ngIf="!flag">
    <div class="img_section text-center position-relative">
      <div class="loader_img_sec mt-4">
        <img src="assets/images/loader.gif" />
      </div>
      <div class="analyzing_text mb-4">
        <p class="thank_you_head">Please Wait</p>
      </div>
    </div>
  </div>
  <div class="vs-messages"></div>
  <div class="dms_wrapper" *ngIf="flag">
    <div class="dms_header responsive_dms_header position-relative d-block">
      <div class="h-100 ml-3 heading" *ngIf="(LocationDropdownValues$ | async) || {}; let address">
        <div class="d-flex flex-column mt-3 mt-md-0 mt-lg-0 mt-xl-0 justify-content-center align-items-start"
          *ngIf="address.values?.length > 0">
          <div class="mb-2">
            <h4 class="text-white MuseoSans-500 text-center">
              {{address.values[0]?.organizationName}}
            </h4>
          </div>
          <div class="">
            <p class="text-white MuseoSans-500 paragraph">
              Address : {{address.values[0]?.practiceAddress?.streetAddress}}
              {{address.values[0]?.practiceAddress?.suburb}},{{address.values[0]?.practiceAddress?.state}}
              {{address.values[0]?.practiceAddress?.postcode}}
            </p>
            <p class="text-white MuseoSans-500 paragraph">Phone : {{address.values[0]?.practicePhone}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dms_content bg-light-blue">
      <div class="select-containers d-flex px-4 align-items-center justify-content-between gap-2">
        <div class="flex1 dropdown flex-half">
          <app-dob-picker ngDefaultControl [(ngModel)]="dateOfBirth"
            (onChangeValue)="onChangeDob($event)"></app-dob-picker>
          <div class="err-msg"
            *ngIf="(dateOfBirth == undefined || dateOfBirth == null || dateOfBirth == '') && submitted">
            Please select date of birth
          </div>
        </div>
        <div class="flex1 dropdown" *ngIf="isLocations">
          <app-filter-dropdown labelName="Select Location" placeholder="Select the location" ngDefaultControl
            [DropdownValues$]="LocationDropdownValues$" [(ngModel)]="locationId" [selecteById]="locationId"
            [selectedValue]="selectedLocation" (onChangeValue)="onChangeLocation($event)"></app-filter-dropdown>
          <div class="err-msg" *ngIf="(locationId == undefined || locationId == null || locationId == '') && submitted">
            Please select the location
          </div>
        </div>
        <div class="flex1 dropdown">
          <app-filter-dropdown labelName="Select Dentist" placeholder="Select the Dentist" [(ngModel)]="doctorId"
            ngDefaultControl [DropdownValues$]="DentistDropdownValues$" [selecteById]="doctorId"
            [selectedValue]="selectedDentist" (onChangeValue)="onChangeDentist($event)"></app-filter-dropdown>
          <div class="err-msg" *ngIf="(doctorId == undefined || doctorId == null || doctorId == '') && submitted">
            Please select the dentist
          </div>
        </div>
      </div>

      <div class="mt-4 px-4">
        <p class="sub_head">Choose Preferred Slot</p>
        <div class="choose-slot-container  justify-content-between d-flex gap-2">
          <div class="date-picker w-100 flex-half  bg-white" style="height: fit-content;">
            <app-date-picker #slotDatePicker [selectDate]="selectDate" isBtnsRequired="true" [minDate]="minDate"
              ngDefaultControl [(ngModel)]="selectedSlotDate"
              (click)="selectionDateSlot(slotDatePicker)"></app-date-picker>
          </div>
          <div class="slot-container w-100 bg-white flex-1" *ngIf="isAvailableSlot && !slotFlag">
            <h4 class="text-black MuseoSans-500 text-start mb-2 available-text">
              Available Time Slots
            </h4>
            <div class="day my-3" *ngFor="let date of objectKeys(startTimesByDate);let i = index">
              <p class="MuseoSans-500 mb-2">{{date | date:'longDate'}}</p>
              <div class="slots d-flex flex-wrap">
                <a class="slot d-inline p-1 px-2 bg-gray text-white rounded" id="slot-section{{i}}{{j}}" [ngClass]="{
                  'bg-period1': startTime.meridiem == 'AM',
                  'bg-period2': startTime.meridiem == 'PM'
                }" *ngFor="let startTime of startTimesByDate[date];let j = index"
                  (click)="selectedTimeSlot(startTime,date,i,j)">{{startTime.time}} {{startTime.meridiem}}
                </a>
              </div>
            </div>
          </div>

          <div class="slot-container empty-container w-100 bg-white  p-2 flex-1"
            *ngIf="isAvailableSlot == false && !slotFlag">
            <div class="d-flex align-items-center flex-column gap-1">
              <div class="icon">
                <img src="../../assets/images/calendar.svg" alt="" />
              </div>
              <div class="p-2 px-4">
                <p class="text-center px-4 paragraph">
                  There are no available appointments for selected date
                </p>
              </div>
            </div>
          </div>

          <div class="slot-container empty-container w-100 bg-white  p-2 flex-1" *ngIf="slotFlag">
            <div class="loading_div analysing_sec sub_wrapper p-3">
              <div class="img_section text-center position-relative">
                <div class="loader_img_sec mt-4">
                  <img src="assets/images/loader.gif" />
                </div>
                <div class="analyzing_text mb-4">
                  <p class="thank_you_head">Please Wait</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gap-2 mt-1" *ngIf="submitted && (slotId == undefined || slotId == null || slotId == '')">
          <p class="err-msg">
            Please select the slot
          </p>
        </div>
      </div>
      <div class="fixed_div d-flex flex-column  align-items-center mt-4  w-100">
        <div class="col-lg-12 col-md-12 col-sm-12 mt-3 termsAndConditionsDiv">
          <div class="center-block" style="margin: 0px auto">
            <div class="checkbox_rounded paragraph d-flex align-items-center justify-content-center">
              <span _ngcontent-out-c114="" class="position-relative mt-1"><input id="agree" type="checkbox" name="agree"
                  ngDefaultControl [(ngModel)]="tnc" /><label for="agree" class="checkbox_label"></label></span>
              <span class="check_text agree_text font_16 ml-3"><span _ngcontent-out-c114="" class="grey_color">I have
                  read and agree to Smilo.ai's </span>
                <a target="_blank" href="{{consent_form}}">Consent Form</a>,
                <a target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and
                <a target="_blank" href="{{privacy_site}}">Privacy Policy</a></span>
            </div>
            <div class="err-msg text-center" *ngIf="!tnc && submitted">
              Please select terms and condition
            </div>
          </div>
        </div>
        <div class="btn-container d-flex">
          <button (click)="backClicked()"
            class="btn  d-flex align-items-center justify-content-center  btn-secondary back_btn  btn_width_2  mt-2 mb-lg-0 mb-2">
            <!-- <span> &lt;</span> -->
            <span class="icon_arrow icon-left-arrow mr-3 font_12"></span>
            <span>Go Back</span>
          </button>
          <button (click)="bookAppointment()"
            class="btn button_links btn_width_2 submit_btn d-flex align-items-center justify-content-center  continue_btn mt-2 mb-lg-0 mb-2">
            <span>Book Appointment</span>
            <!-- <span> &gt;</span>     -->
            <span class="icon_arrow icon-right-arrow ml-3 font_12"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>