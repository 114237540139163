import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dob-picker',
  templateUrl: './dob-picker.component.html',
  styleUrls: ['./dob-picker.component.css'],
})
export class DobPickerComponent implements OnInit {
  myDateValue: Date;
  maxDate = new Date();
  @Output() onChangeValue = new EventEmitter();

  ngOnInit() {

  }

  onDateSelection(newDate: Date) {
    this.onChangeValue.emit(newDate);
  }
}
