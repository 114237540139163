import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { DomainService } from "src/core/service/domain/domain.service";

declare var gtag;
declare const fbq = "";

@Component({
  selector: "app-purpose-of-visit",
  templateUrl: "./purpose-of-visit.component.html",
  styleUrls: ["./purpose-of-visit.component.scss"],
})
export class PurposeOfVisitComponent implements OnInit, AfterViewInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  responsedata: any;
  flag: boolean = false;
  InputForm: FormGroup;
  today = new Date();
  questions_array: any[] = [];
  questions_data: any = [];
  form: FormGroup;
  first_question_type: any;
  isRadioButtonChecked: boolean = false;
  defaultImage: string = "assets/images/loader_large.gif";

  staticImage1: string = "assets/images/toothache-new.png";
  staticImage2: string = "assets/images/sensitivity-gum.png";
  staticImage3: string = "assets/images/general-checkup.png";
  staticImage: string = "assets/images/home-bg.png";

  medicalQuestionSelectedOption: any = "";
  fb_pixel_id: any = "";
  gtm_id: any = "";
  button_text_details: any = "";

  continueBtnDisabled: boolean = true;

  widget_button_type: any = "";

  widget_benefit_one: any = "";
  widget_benefit_two: any = "";
  widget_benefit_three: any = "";

  get_contact_information_later: any = "";

  privacy_site: string = environment.PRIVACY;
  terms_site: string = environment.TERMS;
  consent_form: string = environment.CONSENT_FORM;
  checkboxChecked: boolean = false;
  termsAndConditions: string = "1";
  ohr_flow: any;
  checkBoxValue: boolean = false;
  isUserFromIndia: boolean = false;
  public isDomainAus;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private domainService: DomainService
  ) {
    this.isDomainAus = this.domainService.isAusRegionDomain("");
    if (this.isDomainAus) {
      this.staticImage1 = "assets/images/Aus_images/toothache.png";
      this.staticImage2 = "assets/images/Aus_images/cosmetic.png";
      this.staticImage3 = "assets/images/ohr-new.png";
    } else {
      this.staticImage1 = "assets/images/toothache-new.png";
      this.staticImage2 = "assets/images/sensitivity-gum.png";
      this.staticImage3 = "assets/images/general-checkup.png";
    }
    this.appservices = new Services();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $("#general_checkup").trigger("click");
    }, 100);
  }

  ngOnInit(): void {
    if (!this.isDomainAus) {
      this.getCountry();
    }
    localStorage.setItem("Input_form_data", "");
    this.appservices.setSession("user_selected_practice_branch", "", false);
    this.fb_pixel_id = this.appservices.getSession("fb_pixel_id", false);
    this.gtm_id = this.appservices.getSession("gtm_id", false);
    this.button_text_details = this.appservices.getSession(
      "button_text_details",
      false
    );
    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.ohr_flow = this.appservices.getSession("ohr_flow", false);
    if (this.appservices.isEmpty(this.button_text_details)) {
      this.router.navigate(["oral-health-score-preference"]);
    } else {
      this.button_text_details = JSON.parse(this.button_text_details);
      this.widget_button_type = this.appservices.getSession(
        "widget_button_type",
        false
      );

      var sessionData;
      var question_type;
      this.form = this.formBuilder.group({
        dateFull: new FormControl(this.today),
        time: null,
      });
      if (this.appservices.hasSession("questions_array")) {
        this.first_question_type = this.appservices.getSession(
          "first_question_type",
          false
        );
        this.questions_data = this.appservices.getSession(
          "questions_array",
          false
        );
        this.medicalQuestionSelectedOption = "";

        sessionData = JSON.parse(this.questions_data);
        question_type = this.first_question_type;
      }
      $(document).on("keydown", "form", function (event) {
        return event.key != "Enter";
      });
      $(".sec_inner_content").hide();
      this.InputForm = this.formBuilder.group({
        questions: ["", [Validators.required]],
      });
      this.getQuestionsList();
      let that = this;

      this.widget_benefit_one = this.appservices.getSession(
        "widget_benefit_one",
        false
      );
      this.widget_benefit_two = this.appservices.getSession(
        "widget_benefit_two",
        false
      );
      this.widget_benefit_three = this.appservices.getSession(
        "widget_benefit_three",
        false
      );
    }
  }
  onBack() {
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_select_reason_back_clicked",
      true
    );
    this.router.navigate(["./getting-started"]);
  }
  onCheckboxChange(e, type) {
    $(".medical-questions-options-div").removeClass(
      "medical-questions-selected-option"
    );
    $(e.target)
      .closest(".medical-questions-options-div")
      .addClass("medical-questions-selected-option");
    this.appservices.setSession("medicalQuestionSelectedOption", type, false);
    this.medicalQuestionSelectedOption = type;
    this.first_question_type = type;
    this.isRadioButtonChecked = true;

    $(".text_box").addClass("visibility_hidden");
    $(".other_reasons").removeClass("custom");

    this.checkBoxValue = true;

    if (!$("#agree").is(":checked")) $("#agree").prop("checked", true);

    this.checkboxChecked = true;
    this.termsAndConditions = "1";
    this.continueBtnDisabled = false;
  }
  
  getFirstQuestionAnswer(type) {
    // localStorage.removeItem("teethWhitening");
    localStorage.removeItem("teethWhiteningType2");
    localStorage.removeItem("teethWhitheningApiResult");
    localStorage.removeItem("teethWhiteningUploadedImage");
    localStorage.removeItem("teethWhiteningResultImage");
    if (type == "radio1") {
      return "Tooth ache / Broken teeth";
    } else if (type == "radio2") {
      return "Cosmetic Treatment";
    } else if (type == "radio3" && $(".input_text_field").val() != "") {
      return "General Checkup";
    } 
    // else if (type == "radio4" && $(".input_text_field").val() != "") {
    //   this.appservices.setSession("teethWhitening", true, false);
    //   return "Teeth Whitening1";
    // }
    else if (type == "radio4") {
      this.appservices.setSession("teethWhiteningType2", true, false);
      return "Teeth Whitening2";
    }
  }
  getQuestionsList() {
    this.appservices
      .callJSONAPI(
        this.appservices.MEDICAL_QUESTIONNAIRE + "?offset=0&limit=100",
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          this.flag = true;
          if (data.status) {
            $(".sec_inner_content").show();
            this.responsedata = data.result.questions;
          } else {
          }
        },
        (error) => {}
      );
  }
  length(classname) {
    this.continueBtnDisabled = true;
    if ($("." + classname).val().length >= 5) {
      this.continueBtnDisabled = false;
    }
    if ($("." + classname).val().length > 100) {
      $("." + classname).val(
        $("." + classname)
          .val()
          .substr(0, 100)
      );
    }
  }
  saveQuestions() {
    this.getQuestionsArray();
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession(
      "token",
      false
    );
    this.apirequest["questions"] = this.questions_array;
    this.appservices.setSession("questions_array", this.questions_array, true);
    this.appservices.setSession(
      "first_question_type",
      this.first_question_type,
      false
    );
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_select_visit",
      true
    );
    if (this.get_contact_information_later == "yes") {
      this.appservices.sendAnalyticsData(
        "",
        "",
        "smilo_event",
        "sa_wg_select_reason_continue_clicked",
        true
      );
      if (this.isDomainAus) {
        this.router.navigate(["upload-teeth-images"]);
      } else {
        this.router.navigate(["consent-agreement"]);
      }
      // this.router.navigate(["upload-teeth-photos"]);
      // this.router.navigate(['oral-health-score-preference']);
    } else {
      this.router.navigate(["contact-information"]);
    }
  }
  getQuestionsArray() {
    this.questions_array = [];
    this.responsedata
      .map((item, index) => {
        return {
          question_id: item.question_id,
          answer:
            index == 0
              ? this.getFirstQuestionAnswer(this.first_question_type)
              : "",
        };
      })
      .forEach((item) => this.questions_array.push(item));
  }

  isCheckboxChecked(event) {
    this.checkboxChecked = false;
    if (event === "on") {
      this.termsAndConditions = "1";
      this.checkboxChecked = true;

      if (this.isRadioButtonChecked) this.continueBtnDisabled = false;
    }
  }

  onContinue() {
    if (!this.checkboxChecked) {
      this.termsAndConditions = "0";

      // $('html, body').animate({ scrollBottom: $('.termsandconditionstext').offset().bottom }, 'slow');

      //  $("html, body").animate({ scrollTop: $(document).height() }, 1000);

      return false;
    }

    // console.log(this.first_question_type, !this.getFirstQuestionAnswer(this.first_question_type))
    if (!this.getFirstQuestionAnswer(this.first_question_type)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.appservices.serviceMessages(
        "vs-messages",
        "Please answer the question",
        "danger"
      );
    } else {
      $(".coninue_btn").html("Submitting...").attr("disabled", true);
      this.appservices.serviceMessages("vs-messages", "", "");
      this.saveQuestions();
    }
  }

  onAnswer(event, rowid, id, answer) {
    let rootElements = document.getElementsByClassName(
      "oral_hygiene_btn_" + id
    )[0];
    let buttonElements = rootElements.getElementsByClassName("custom_btn");
    for (var i = 0; i < buttonElements.length; i++) {
      buttonElements[i].classList.remove("active");
    }
    let element = <HTMLInputElement>event.target;
    if (element.classList.contains("active"))
      element.classList.remove("active");
    else element.classList.add("active");
  }
  get oh() {
    return this.InputForm.controls;
  }
  onStart(uploadImages: boolean) {
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_start_now",
      false
    );
    this.appservices.sendPageViewAnalyticsData(
      gtag,
      "",
      this.router.url,
      "Purpose of visit"
    );

    if (uploadImages == false) {
      this.appservices.setSession("uploadImageBtnSelected", false, false);

      this.router.navigate(["purpose-of-visit"]);
    }
    if (uploadImages == true) {
      this.appservices.setSession("uploadImageBtnSelected", true, false);

      if (this.get_contact_information_later == "yes") {
        if (this.isDomainAus) {
          this.router.navigate(["upload-teeth-images"]);
        } else {
          this.router.navigate(["upload-teeth-photos"]);
        }
      } else {
        this.router.navigate(["contact-information"]);
      }
    }
  }

  getCountry() {
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
        this.isUserFromIndia = true;
        if (this.isUserFromIndia) {
          this.privacy_site = environment.IN_PRIVACY;
          this.terms_site = environment.IN_TERMS;
          this.consent_form = environment.IN_CONSENT_FORM;
        } else {
          this.privacy_site = environment.PRIVACY;
          this.terms_site = environment.TERMS;
          this.consent_form = environment.CONSENT_FORM;
        }
      });
  }
}
