<div class="modal-header border-bottom-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <i class="sh-cancel"></i>
  </button>
</div>
<div class="modal-body">
  <div class="report-modal-wrapper">
    <div class="report-modal-header text-center">
      <h2 class="mb-1">
        Your
        {{ imageSelectionCount == 1 ? "Smile Check" : "Oral Health" }} Report
      </h2>
      <p>
        Here's your comprehensive report. A copy of the<br />
        report has also been sent to your registered email.
      </p>
    </div>
    <div class="report-modal-body">
      <ngx-slick-carousel
        class="report_carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (init)="slickInit($event)"
        *ngIf="sliderData.length > 0"
      >
        <div
          ngxSlickItem
          class="slide"
          style="width: 100%"
          *ngFor="let item of sliderData; let i = index"
        >
          <div
            class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-start"
          >
            <div class="report-slider-image slider-for">
              <div class="report-slider-item">
                <img [src]="item.url" class="img-fluid" alt="" />
              </div>
            </div>
            <div class="report-slider-content slider-nav">
              <div class="report-slider-item text-center">
                <ul
                  class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                >
                  <li class="d-flex align-items-center">
                    <img
                      src="assets/images/dental-decay.svg"
                      class="img-fluid"
                      alt=""
                    />
                    Dental Decay
                  </li>
                  <li class="d-flex align-items-center">
                    <img
                      src="assets/images/gum-disease.svg"
                      class="img-fluid"
                      alt=""
                    />
                    Gum Disease
                  </li>
                </ul>
                <div class="no-of-slide mb-1" *ngIf="imageSelectionCount != 1">
                  {{ item.pageNumber }} {{ sliderData?.length }}
                </div>
                <h3 class="mb-1 mb-lg-3">{{ item.title }}</h3>
              </div>
              <!-- <div class="text-center">
                <p>Your uploaded image indicates</p>
                <p><b>potential gum disease concerns.</b></p>
              </div> -->
            </div>
          </div>
        </div>
      </ngx-slick-carousel>

      <div
        class="button-group d-flex align-items-center justify-content-center"
      >
        <button
          type="button"
          class="btn btn-outline btn-rounded-corner"
          (click)="onContactUs()"
        >
          <i class="sh-phone pr-2"></i> Contact Us
        </button>
        <button
          type="button"
          class="btn btn-primary btn-rounded-corner"
          (click)="onStartAgain()"
        >
          <i class="sh-rotate-left pr-2"></i> Start Again
        </button>
      </div>
    </div>
  </div>
</div>
