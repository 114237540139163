<div ngbDropdown class="filter-dropdown-container">
    <div tabindex="1" class="filter-dropdown">
        <div id="filterdropdown">
            <span class="dropdown-name form-text sub_head">{{labelName}}</span>
        </div>
        <div ngbDropdownToggle class="bg-white px-3 py-3 rounded position-relative">
            <span class="dropdownvalue" *ngIf="(DropdownValues$ | async) as dropdowns">
                {{(selectedValue || selectedValue != null ) ? selectedValue?.name :
                placeholder }}</span>
        </div>
        <div ngbDropdownMenu class="px-0 filterdropdownoptions" aria-labelledby="filterdropdown">
            <ul class="list-unstyled p-1"
                *ngIf="!(DropdownValues$ | async)?.isLoading && (DropdownValues$ | async) as dropdowns">
                <div class="p-1">
                    <input type="text" #searchKey class="form-control" placeholder="search"
                        (input)="searchStream$.next(searchKey.value)" (keydown.enter)="filterDropdown()" />
                </div>
             
                <li class="dropdown-item py-2" *ngFor="let value of dropdowns?.values"
                    (click)="value?.status != 0 && setSelected(value)">
                    <span [ngClass]="value?.status == 0 ? 'text-muted' : ''">{{value?.name}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>