import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";

import { environment } from "../../environments/environment";

import { Md5 } from "ts-md5/dist/md5";
import { Location } from "@angular/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  appservices: any = "";
  user_details: any = "";
  user_email: any = "";
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  apirequest: any = "";
  input: any = "";
  showErrMsg: string = "display_none";
  errMsg: string = "";
  submitBtnDisabled: boolean = false;
  btnText = "Sign in";
  pastedPassword: any = "";
  displayForgotPasswordForm: string = "display_none";
  signInForm: string = "display_block";
  forgotPasswordForm: FormGroup;
  reset_password_errMsg: string = "";
  showsuccessMsg: string = "display_none";
  reset_password_successMsg: string = "";
  forgotPasswordBtntext = "Forgot Password";
  forgotPasswordBtnDisabled: boolean = false;
  forgotPasswordShowErrMsg: string = "display_none";
  backButtonClicked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private _location: Location
  ) {
    this.appservices = new Services();
  }

  ngOnInit(): void {
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate(["get-started"]);
    }

    if (
      localStorage.getItem("Input_form_data") == null ||
      localStorage.getItem("Input_form_data") == ""
    ) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    } else {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.user_email = this.user_details.email;
    }

    this.appservices.setSession("bannerTitleText", "User Profile", false);

    this.loginForm = this.formBuilder.group({
      user_email: [this.user_email, [Validators.required]],
      user_password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      user_email: [this.user_email, [Validators.required]],
    });
  }

  onSubmit(form: FormGroup, formType: any) {
    if (formType === "login" && !this.backButtonClicked) {
      this.isSubmitted = true;
      
      if (this.loginForm.valid) {
        this.btnText = "Please Wait";
        this.submitBtnDisabled = true;
        this.apirequest = [];

        this.apirequest["email"] = this.user_email;
        this.apirequest["password"] = Md5.hashStr(
          form.value.user_password.trim()
        ); 
        this.apirequest["user_type"] = 1;
        this.apirequest["source"] = "web";
        this.apirequest["device"] = "web";

        this.input = this.appservices.generteJSONFromArray(this.apirequest);

        this.appservices
          .callJSONAPI(
            this.appservices.USER_LOGIN,
            this.input,
            "post",
            this,
            "127.0.0.1"
          )
          .subscribe((data: any) => {
            this.submitBtnDisabled = false;
            this.btnText = "Sign in";
            this.isSubmitted = false;
            if (data.status && data.status_code === 1) {
              this.showErrMsg = "display_none";
              this.errMsg = "";
              this.appservices.setSession("isUserLoggedIn", "true", false);
              this.router.navigate(["user-profile"]);
            } else {
              this.loginForm.controls["user_password"].setValue("");
              this.showErrMsg = "display_block err-msg";
              this.errMsg = data.message;
              setTimeout(() => {
                // <<<---using ()=> syntax
                this.showErrMsg = "display_none";
                this.errMsg = "";
              }, 5000);
            }
          });
      }
    } else {
      this.apirequest = [];
      this.apirequest["email"] = this.user_email;
      this.apirequest["user_type"] = 1;
      this.apirequest["source"] = "web";
      this.apirequest["device"] = "web";
      this.input = this.appservices.generteJSONFromArray(this.apirequest);
      this.forgotPasswordBtntext = "Please wait";
      this.forgotPasswordBtnDisabled = true;
      this.appservices
        .callJSONAPI(
          this.appservices.FORGOT_PASSORD,
          this.input,
          "post",
          this,
          "127.0.0.1"
        )
        .subscribe((data: any) => {
          // reset_password_errMsg

          this.forgotPasswordBtnDisabled = false;
          this.forgotPasswordBtntext = "Forgot Password";
          if (data.status && data.status_code === 1) {
            this.showsuccessMsg = "display_block";
            this.reset_password_errMsg = "";
            this.reset_password_successMsg = data.message;
            setTimeout(() => {
              // <<<---using ()=> syntax
              this.showsuccessMsg = "display_none";
              this.reset_password_successMsg = "";
            }, 5000);
          } else {
            this.forgotPasswordShowErrMsg = "display_block err-msg";
            this.reset_password_errMsg = data.message;
            setTimeout(() => {
              // <<<---using ()=> syntax
              this.forgotPasswordShowErrMsg = "display_none";
              this.reset_password_errMsg = "";
            }, 5000);
          }
        });
    }
  }

  backClicked() {
    this.backButtonClicked = true;
    // this.isSubmitted = false;
    this._location.back();
  }

  show_forgot_password_form() {
    this.loginForm.controls["user_password"].setValue("");
    this.displayForgotPasswordForm = "display_block";
    this.signInForm = "display_none";
  }

  show_login_form() {
    this.displayForgotPasswordForm = "display_none";
    this.signInForm = "display_block";
  }
}
