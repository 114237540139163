<div class="modal-header border-bottom-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <i class="sh-cancel"></i>
  </button>
</div>

<div class="modal-body">
  <div class="report-modal-wrapper">
    <div class="report-modal-header text-center">
      <div class="circle-chekmark">
        <div class="circle-chekmark-light"></div>
        <div class="circle-chekmark-light1"></div>
        <div class="zoomout-circle_tick">
          <div class="circle-chekmark-light"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="36"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <h2 class="mb-1 mt-1 pt-lg-2">Report Shared</h2>
      <p class="mb-3 pb-lg-1">
        Your report has been successfully shared via your chosen<br />
        method. For assistance, please contact our support team.
      </p>
      <p>
        <a href="javascript:void(0)" (click)="onContactUs()"
          ><i class="sh-phone"></i> Contact Us</a
        >
      </p>
    </div>
  </div>
</div>
