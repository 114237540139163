import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Services } from "../../Services";

declare var gtag;
declare const fbq ='';

@Component({
  selector: "app-appointment-card",
  templateUrl: "./appointment-card.component.html",
  styleUrls: ["./appointment-card.component.css"],
})
export class AppointmentCardComponent implements OnInit {
  isExpand: boolean = false;
  @Input() isTeethWhiteningResult:boolean = false;
  @Output() bookNowClick: EventEmitter<void> = new EventEmitter();
  isBupaFlow = false;
  appServices: Services;

  constructor(private router: Router) {
    this.appServices = new Services();
    this.isBupaFlow = this.appServices.getSession("isBupaFlow", false) === "true";
  }

  ngOnInit(): void {}

  onExpand() {}

  onBookNow() {
    this.bookNowClick.emit();
    this.router.navigate(["dental-appointment"]);
  }

  checkNow(){
    this.router.navigate(["get-started"]);
  }
}
