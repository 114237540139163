import { Component, OnInit } from "@angular/core";
import { DownloadReportOptionsComponent } from "../download-report-options/download-report-options.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { Services } from "../../../../app/helpers/Services";
import { Subscription } from "rxjs";
import { HelperServiceService } from "../../../../app/helpers/services/helper-service.service";
import { ReportSharedPopupComponent } from "../report-shared-popup/report-shared-popup.component";
import { OtpVerificationPopupComponent } from "../otp-verification-popup/otp-verification-popup.component";

declare var gtag;
declare const fbq ='';

@Component({
  selector: "app-download-report-card",
  templateUrl: "./download-report-card.component.html",
})
export class DownloadReportCardComponent implements OnInit {
  appServices: Services;
  isExpand: boolean = false;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    keyboard: false,
    class: "modal-lg",
  };
  config2 = {
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    keyboard: false,
    class: "modal-md",
  };
  private _subscriptions = new Subscription();
  constructor(
    private modalService: BsModalService,
    private helperServiceService: HelperServiceService
  ) {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.helperServiceService.downloadOptionRequest.subscribe(
        (downloadOptionResponse) => {
          if (
            downloadOptionResponse &&
            (downloadOptionResponse === 1 || downloadOptionResponse === 4)
          ) {
            const bsModalRef = this.modalService.show(
              ReportSharedPopupComponent,
              Object.assign(this.config2, {
                class:
                  "modal-dialog-centered report-modal report-shared modal-md",
              })
            );
          }
          if (
            downloadOptionResponse &&
            (downloadOptionResponse === 2 || downloadOptionResponse === 3)
          ) {
            this.appServices.sendAnalyticsData(
              "",
              "",
              "smilo_event",
              "sa_wg_payment_success_page_otp_requested",
              true
            );
            const bsModalRef = this.modalService.show(
              OtpVerificationPopupComponent,
              Object.assign(this.config2, {
                class:
                  "modal-dialog-centered report-modal report-shared otp-modal modal-md",
              })
            );
          }
        }
      )
    );
  }

  openModalDownloadReport() {
    this.appServices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_payment_success_download_report_popup_opened",
      true
    );
    const bsModalRef = this.modalService.show(
      DownloadReportOptionsComponent,
      Object.assign(this.config, {
        class: "modal-dialog-centered report-modal modal-lg",
      })
    );
  }
}
