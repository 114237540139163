import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Services } from '../../app/helpers/Services';
@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {

  appservices: Services;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.appservices = new Services;
  }
  ngOnInit(): void {
    let token = this.appservices.getSession("token", false);
    this.appservices.clearSession();
    this.appservices.setSession("token", token, false);
    this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]); 
    // this.router.navigate(['contact-information']);
  }

}
