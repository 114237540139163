<div class="dynamic_wrapper">
  <div class="dms_wrapper p-4">
    <div class="dms_content d-flex align-items-center flex-column ">
      <div class="check-img-container d-flex align-items-center justify-content-center">

        <lottie-player autoplay src="../../assets/json/success.json" style="width:150px;"></lottie-player>

      </div>
      <div class="user-details px-4">
        <div class="text-center text-div px-4">
          <p class=" sub_head text-dark appointment-details"> Dear {{booking_details?.data?.firstName?
            booking_details?.data?.firstName : 'first_name'}}, your appointment with
            {{booking_details?.doctorName? booking_details?.doctorName : 'Doctor Name'}}
            on {{booking_details?.data?.start | date:'longDate'}} & {{booking_details?.data?.start | date:'shortTime'}}
            has been successfully booked</p>
          <p class="sub_head mb-0">Booking Ref ID : {{booking_details?.bookingId ? booking_details?.bookingId : '-'}}
          </p>
        </div>
      </div>
      <div class="btn-container my-4 d-flex">
        <button routerLink="/get-started"
          class="btn d-flex align-items-center justify-content-center  text-white button_links btn_width_2 p-2">
          <span>Home</span>

          <span class="icon_arrow icon-right-arrow ml-3 font_12"></span>
        </button>
      </div>
    </div>
  </div>
</div>