//#region Imports Sections
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, share, takeWhile } from "rxjs/operators";

import Swal from "sweetalert2";

import { environment } from "../../environments/environment";
import { Utilities } from "./utilities";
//#endregion Imports Sections

declare var gtag;

export class Services extends Utilities {
  //#region Variables
  private encryptstr: any;
  private decryptstr: any;
  private request: any;
  public api_end_points: any;
  //#endregion Variables

  //#region constructor
  constructor() {
    super();
  }
  //#endregion constructor

  //#region generateServiceHeaders
  public generateServiceHeaders(endpoint, is_refresh?: boolean) {
    if (this.isAuthenticated() === false)
      return new HttpHeaders()
        .set("Content-Type", "application/json")
        .append("App-Access-Token", environment.AUTHORIZATION_KEY);
    else {
      return new HttpHeaders()
        .set("Content-Type", "application/json")
        .append("App-Access-Token", environment.AUTHORIZATION_KEY)
        .append(
          "User-Access-Token",
          this.getSession("user_access_token", false).toString()
        );
    }
  }
  //#endregion generateServiceHeaders

  //#region generateAccessToken
  public generateAccessToken(thisobject, endpoint, ip_address) {
    let headers = this.generateServiceHeaders("", true);

    let body = JSON.parse(
      "{" + this.getDeviceInfo(ip_address, "post", endpoint) + "}"
    );
    this.encryptstr = this.encryptJSONHandler(body);
    body = JSON.stringify({ edata: this.encryptstr });
    thisobject.httpClient
      .post(environment.API_BASE_URL + endpoint, body, { headers })
      .subscribe((response) => {
        this.decryptstr = this.decryptJSONHandler(response.edata);
        if (this.decryptstr.valid) {
          this.setSession("user_access_token", this.decryptstr.token, false);
          window.location.reload();
        }
      });
  }
  //#endregion generateAccessToken

  //#region callJSONAPI
  public callJSONAPI(
    endpoint,
    data,
    method_type,
    thisobject,
    ip_address,
    is_refresh?: boolean
  ) {
    if (method_type == "get") {
      let device_details = this.getDeviceInfo(
        ip_address,
        method_type,
        endpoint
      );
      let headers = this.generateServiceHeaders(endpoint, is_refresh);
      return thisobject.httpClient
        .get(environment.API_BASE_URL + endpoint, { headers })
        .pipe(
          catchError(
            (
              error: HttpErrorResponse,
              caught: Observable<HttpEvent<unknown>>
            ) => {
              // console.log(" Inside service ");
              thisobject.router.navigate(["no-internet"]);
              return of();
            }
          ),
          map((response: any) => {
            // console.log(response.edata);
            this.decryptstr = this.decryptJSONHandler(response.edata);
            // console.log(this.decryptstr);
            if (this.decryptstr.force_logout == "1")
              this.force_logout(thisobject, this.decryptstr.message);
            else if (this.decryptstr.is_expired == "1") {
              this.generateAccessToken(
                thisobject,
                "generate_access_token",
                ip_address
              );
            }
            return this.decryptstr;
          }),
          share()
        );
    } else if (method_type == "post") {
      if (data != "") {
        if (data.startsWith("{")) this.request = JSON.parse(data);
        else this.request = JSON.parse("{" + data + "}");
      } else {
        this.request = JSON.parse(
          "{" + this.getDeviceInfo(ip_address, method_type, endpoint) + "}"
        );
      }
      // console.log(this.request);
      this.encryptstr = this.encryptJSONHandler(this.request);
      let body = JSON.stringify({ edata: this.encryptstr });
      let headers = this.generateServiceHeaders(endpoint, is_refresh);
      return thisobject.httpClient
        .post(environment.API_BASE_URL + endpoint, body, { headers })
        .pipe(
          catchError(
            (
              error: HttpErrorResponse,
              caught: Observable<HttpEvent<unknown>>
            ) => {
              //  console.log(" Inside service ");
              thisobject.router.navigate(["no-internet"]);
              return of();
            }
          ),
          map((response: any) => {
            // console.log(" response : " + JSON.stringify(response));
            this.decryptstr = this.decryptJSONHandler(response.edata);
            if (this.decryptstr.force_logout == "1")
              this.force_logout(thisobject, this.decryptstr.message);
            else if (this.decryptstr.is_expired == "1") {
              this.generateAccessToken(
                thisobject,
                "generate_access_token",
                ip_address
              );
            }
            return this.decryptstr;
          }),
          share()
        );
    }
  }
  //#endregion callJSONAPI

  //#region force_logout
  public force_logout(thisobject, message) {
    Swal.fire({
      text: message,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        thisobject.router.navigate(["/signout"]);
      }
    });
  }
  //#endregion force_logout

  public gtmEvents(
    key: string,
    value: string,
    params: {} = {},
    send_to: any = ""
  ) {
    // console.log("called");
    if (value === "page_view") {
      params = {
        page_title: document.title,
        page_location: location.href,
        page_path: location.pathname,
      };
    }
    if (send_to !== "") {
      params["send_to"] = send_to;
    }

    // params['cookieFlags'] = "SameSite=None; Secure" ;

    // console.log(
    //   "key :" + key + " value : " + value + " params " + JSON.stringify(params)
    // );
    // gtag(key, value, params);
  }
}
