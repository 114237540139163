<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div class="sec_inner_content1 p-0">
                <div class="title_sec text-center bg-white max_width_800">
                    <h4 class="font_20 font-weight-700 mb-3"><b>Your Oral Health Rating is</b></h4>



                    <div class="canvas-container d-flex align-items-center justify-content-center">
                        <canvas id="canvas" #graphCanvas></canvas>
                        <div id="container" [ngClass]="needleImage">
                            <img [ngClass]="meterClassName" id="needleImage" src="assets/images/Needle.png"
                                alt="{{OHRText}}">
                        </div>
                        <div class="smiley_image">
                            <div class="dot">
                                <img class="smiley_img" src="{{OHRImage}}" alt="{{OHRText}}">
                            </div>
                        </div>
                    </div>
                    <!-- <p class="rating_txt font_22 mt-2" [ngClass]="appservices.getColor(overall_score)"><b>{{OHRText}}</b></p> -->

                    <p class="rating_txt font_22" [ngClass]="appservices.getColor(overall_score)"><b>{{OHRText}}</b></p>

                    <p class="mt-2"> Calculated on {{created_date}}</p>

                    <p class="my-3 max_width_550">{{rating_explanation}}</p>
                    <p class="font_12 my-3 max_width_550">The Smilo.ai rating is for screening, awareness and education
                        only. Any information or report provided by Smilo.ai is indicative only and should be confirmed
                        by a visit to the dentist.</p>
                    <p class="font_12">Please check with your dentist for further recommendations.</p>

                    <button (click)="getReportInsight()"
                        class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-4 justify-content-center align-items-center">More
                        Personalized Recommendations</button>
                </div>
            </div>
        </div>
    </div>
</div>