import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-image-webcam',
  templateUrl: './image-webcam.component.html',
  styleUrls: ['./image-webcam.component.css']
})
export class ImageWebcamComponent implements OnInit {
  private trigger: Subject<any> = new Subject();
  webcamImage: any;
  private nextWebcam: Subject<any> = new Subject();
  @Output() webCameraImage = new EventEmitter();
  sysImage = '';
  public errors: WebcamInitError[] = [];
  isCaptureBtnEnabled:boolean = true;
  showWebcam = true;

  ngOnInit() { }

  public getSnapshot(): void {
    this.trigger.next(void 0);
  }

  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    this.webCameraImage.emit(this.sysImage)
    // console.info('got webcam image', this.sysImage,
    //   webcamImage);
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    console.log(this.errors)
    if (error.message == 'Requested device not found'){
      this.isCaptureBtnEnabled = false;
      this.showWebcam = false;
    } else {
      this.showWebcam = true;
      this.isCaptureBtnEnabled = true;
    }
  }
}
