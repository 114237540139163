<div class="dynamic_wrapper ">
    <div class="content_main_wrapper">
        <div class="row mx-2 ohr_bg flex_directions">
            <div class="col-lg-6 col-md-12 text-center ohs_content">
                <h3 class="sub_head"> Thank you <br></h3>

                <div class="grey_color font_18 max_width_500">
                    <p>Thank you for submitting your photos, one of your dental </p>
                    <p>practice team will verify your photos.</p>
                </div>

                <div class="text-center mt-3">
                    <button class="btn button_links btn_width_2 mr-3 mb-3" (click)="redirectPatientUpload()">Start
                        again</button>
                    <div class="" *ngIf="displaySettings">

                        <a (click)="redirectToButton(button_url)" class="btn start_btn btn_width_2 ctabtn"
                            target="_blank">{{button_text}}</a>
                    </div>
                </div>


            </div>
            <div class="col-lg-6 col-md-12 px-0 ohs_image">
                <div class="oral_score">
                    <img [defaultImage]="defaultImage1" [lazyLoad]="staticImage" [errorImage]="defaultImage"
                        alt="Oral Score">
                </div>
            </div>
        </div>
    </div>
</div>