<div class="dynamic_wrapper">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="d-flex justify-content-center">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1" />
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1" />
                <span class="icon-tick-fill1 icon_blue"></span>
            </div>
            <div class="container_content_sec">
                <div class="inner_container_content">
                    <div class="sec_inner_content">
                        <div class="title_sec">
                            <h4 class="thank_you_head">Congratulations</h4>
                        </div>
                        <div class="loader_thankyou_sec pr-4 pl-4">


                            <div class="img_section text-center position-relative">
                                <div class="loader_img_sec">
                                    <img src="assets/images/loader.gif" />
                                </div>
                                <div class="analyzing_text">
                                    <p>Analyzing...</p>
                                </div>
                            </div>
                            <div class="art_intelli_para_sec">

                                <p class="font_w_500">
                                    Please wait while our AI engine is analysing your photos
                                </p>
                            </div>
                            <div class="text-center mt-3">
                                <button class="btn button_links btn-block mr-1 skip_btn btn_color"
                                    [routerLink]="['/oral-health-score']">
                                    Skip
                                </button>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>