import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Services } from "../helpers/Services";
import { Router } from "@angular/router";
import { ConnectionService } from "ng-connection-service";

import { Observable, Observer, fromEvent, merge } from "rxjs";
import { map } from "rxjs/operators";
import { AnimationOptions } from "ngx-lottie";
import { DomainService } from "src/core/service/domain/domain.service";

@Component({
  selector: "app-no-internet",
  templateUrl: "./no-internet.component.html",
  styleUrls: ["./no-internet.component.css"],
})
export class NoInternetComponent implements OnInit {
  appservices: Services;
  current_url: any;
  isConnected = false;
  noInternetConnection: boolean = false;
  btnsDisabled: boolean = false;
  // defaultImage:string = 'assets/images/loader_large.gif';
  staticImage: string = "assets/images/no-internet-lg.png";
  btnClicked: boolean = false;
  public isDomainAus;
  options: AnimationOptions = {
    path: "./../../assets/json/animation.json",
  };

  constructor(
    private _cd: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private router: Router,
    private domainService: DomainService
  ) {
    this.appservices = new Services();
    this.isDomainAus = this.domainService.isAusRegionDomain("");

    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.btnsDisabled = false;
      } else {
        this.btnsDisabled = true;
      }
    });
  }

  ngOnInit(): void {
    this.appservices.setSession("bannerTitleText", "No Internet", false);
  }

  async refreshHere() {
    this.btnClicked = true;
    await this.createOnline$().subscribe((isOnline) => {
      this.current_url = this.appservices.getSession("current_url", false);

      this.isConnected = isOnline;
      this._cd.detectChanges();

      if (isOnline) {
        setTimeout(() => {
          this.router.navigate([this.current_url]);
        }, 1500);
      }
    });
  }

  backToHome() {
    this.router.navigate([
      "get-started/" + this.appservices.getSession("token", false),
    ]);
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
