import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Services } from "../../app/helpers/Services";
import * as $ from "jquery";
import * as Bowser from "bowser";

import { environment } from "../../../src/environments/environment";

declare var gtag;
declare const fbq;

@Component({
  selector: "app-oral-hygiene",
  templateUrl: "./oral-hygiene.component.html",
  styleUrls: ["./oral-hygiene.component.css"],
})
export class OralHygieneComponent implements OnInit {
  @Input() practiceSettingsData: any[];

  answers: any[] = [];
  obj: any;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  responsedata: any;
  practiceSettings: any;
  offset: string = "0";
  flag: boolean = false;
  InputForm: FormGroup;
  isSubmitted = false;
  latest_ans: any;
  isSubmitButtonDisabled: boolean = true;
  settingsData: any[] = [];
  get_contact_information_later: any = "";
  guest_user_id: any = "";
  hasRedirectedFromContactForm: any = "";
  report_id: any = "";
  showCalculationScreen: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.appservices = new Services();
  }
  public getDeviceInfo() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details =
      userAgent.browser.name +
      " " +
      userAgent.os.name +
      " " +
      userAgent.browser.version;
    return browser_details;
  }
  ngOnInit(): void {
    this.appservices.setSession("bannerTitleText", "Oral Health Rating", false);
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_ohr_questions",
      true
    );
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    $(document).on("keydown", "form", function (event) {
      return event.key != "Enter";
    });
    $(".sec_inner_content").hide();
    this.InputForm = this.formBuilder.group({
      questions: ["", [Validators.required]],
    });

    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);
    this.hasRedirectedFromContactForm = this.appservices.getSession(
      "hasRedirectedFromContactForm",
      false
    );

    this.appservices
      .callJSONAPI(
        this.appservices.QUESTIONNAIRE + "?offset=0&limit=100",
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          if (data.status) {
            $(".sec_inner_content").show();
            this.responsedata = data.result.questions;
          } else {
          }
        },
        (error) => {}
      );
    let user_id = this.appservices.getSession("user_id", false);
    this.appservices
      .callJSONAPI(
        this.appservices.CALCULATINGSCORES +
          "?device_details=" +
          this.getDeviceInfo() +
          "&oral_unique_id=" +
          this.appservices.getSession("ohr_unique_key", false) +
          "&widget_token=" +
          this.appservices.getSession("token", false) +
          "&timezone=Asia/Calcutta&selected_branch_id=" +
          this.appservices.getSession("user_selected_practice_branch", false) +
          "&get_contact_information_later=" +
          this.get_contact_information_later +
          "&guest_user_id=" +
          this.guest_user_id +
          "&user_id=" +
          user_id,
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          if (data.status) {
            this.appservices.setSession(
              "overall_score",
              data.result.scores.overall_score,
              false
            );
            this.appservices.setSession(
              "health_score_id",
              data.result.scores.score_id,
              false
            );
            if (!this.appservices.isEmpty(this.hasRedirectedFromContactForm)) {
              if (
                !this.appservices.isEmpty(
                  this.appservices.getSession("user_id", false)
                )
              ) {
                this.report_id = this.appservices.getSession(
                  "ohr_unique_key",
                  false
                );
                this.guest_user_id = this.appservices.getSession(
                  "guest_user_id",
                  false
                );
                this.apirequest = [];
                this.apirequest["report_id"] = this.report_id;
                this.apirequest["user_id"] = this.appservices.getSession(
                  "user_id",
                  false
                );
                this.apirequest["guest_user_id"] = this.guest_user_id;
                this.apirequest["widget_token"] = this.appservices.getSession(
                  "token",
                  false
                );
                this.apirequest["overall_score"] = this.appservices.getSession(
                  "overall_score",
                  false
                );
                this.apirequest[
                  "selected_branch_id"
                ] = this.appservices.getSession("selected_branch_id", false);
                this.apirequest[
                  "health_score_id"
                ] = this.appservices.getSession("health_score_id", false);
                this.apirequest["timezone"] = "Asia/Calcutta";

                let report_input = this.appservices.generteJSONFromArray(
                  this.apirequest
                );

                this.appservices
                  .callJSONAPI(
                    this.appservices.UPDATE_OHR_USER_ID,
                    report_input,
                    "post",
                    this,
                    ""
                  )
                  .subscribe(async (data: any) => {
                    this.flag = true;
                  });
              }
            } else {
              this.flag = true;
            }
          } else {
          }
        },
        (error) => {}
      );
  }
  onContinue(type) {
    $(".coninue_btn").html("Submitting...").attr("disabled", true);
    this.appservices.serviceMessages("vs-messages", "", "");
    this.saveQuestions(type);
  }
  onAnswer(event, rowid, type, ques_id, btn_id?: any) {
    this.isSubmitButtonDisabled = true;
    this.appservices.serviceMessages("vs-messages", "", "danger");
    let index = this.answers.findIndex((item) => item.question_id === ques_id);
    if (index >= 0) {
      this.answers.splice(index, 1);
    }

    if (type == "1" || type == "2") {
      let ans = $(".custom_button" + rowid + btn_id).html();
      this.latest_ans = "[" + ans + "]";
    } else {
      if (event.target.value != "") this.latest_ans = event.target.value;
    }
    if (this.latest_ans != undefined) {
      this.obj =
        '{"question_id":"' +
        ques_id +
        '","type":"' +
        type +
        '","answers":"' +
        this.latest_ans +
        '"}';
      this.answers.push(JSON.parse(this.obj));
    }

    let rootElements = document.getElementsByClassName(
      "oral_hygiene_btn" + rowid
    )[0];
    let buttonElements = rootElements.getElementsByClassName("custom_btn");
    if (type == "1" || type == "2") {
      for (var i = 0; i < buttonElements.length; i++) {
        buttonElements[i].classList.remove("active");
      }
    }
    let element = <HTMLInputElement>event.target;
    if (element.classList.contains("active"))
      element.classList.remove("active");
    else element.classList.add("active");

    var domRepresentation = document.getElementsByClassName("options_sec");

    if (this.answers.length === domRepresentation.length) {
      this.isSubmitButtonDisabled = false;
      $("#num3").removeClass("disabled");
    }
  }
  get oh() {
    return this.InputForm.controls;
  }

  saveQuestions(type) {
    if (type == "skip") {
      this.answers = [];
    }
    this.apirequest["oral_unique_id"] = this.appservices.getSession(
      "ohr_unique_key",
      false
    );
    this.apirequest["content_type"] = 1;
    this.apirequest["questions"] = this.answers;
    let input = this.appservices.generteJSONFromArray(this.apirequest);

    this.appservices
      .callJSONAPI(this.appservices.QUESTIONNAIRE_SAVE, input, "post", this, "")
      .subscribe(
        (data: any) => {
          $(".coninue_btn").html("Continue").attr("disabled", false);
          if (data.status) {
            this.appservices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_ohr_questions_submit",
              true
            );

            this.router.navigate(["oral-health-score"]);
          } else {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "danger"
            );
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
  }
}
