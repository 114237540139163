import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Services } from "../../app/helpers/Services";

@Component({
  selector: "app-payment-successful",
  templateUrl: "./payment-successful.component.html",
  styleUrls: ["./payment-successful.component.css"],
})
export class PaymentSuccessfulComponent implements OnInit {
  first_name: any = "";
  last_name: any = "";
  appservices: Services;
  constructor(private router: Router) {
    this.appservices = new Services();
  }

  ngOnInit(): void {
    this.first_name = this.appservices.getSession("first_name", false);
    this.last_name = this.appservices.getSession("last_name", false);
  }

  redirectOhrImages() {
    this.router.navigate(["process-ohr-images"]);
  }

  // process-ohr-images
}
